import log from 'loglevel'
import router from '@/router'
import axios from 'axios'
import { NotificationProgrammatic as Notification } from 'buefy'

const getBackendBaseUrl = () => process.env.VUE_APP_BASE_URL

const clientOptions = {
    baseURL: getBackendBaseUrl(),
    timeout: 10000, // indicates, 1000ms ie. 1 second
    headers: {
        'Content-Type': 'application/json',
        // anything you want to add to the headers
    },
}

// log.debug("clientOptions:", clientOptions);

const client = axios.create(clientOptions)

//
// Auth
//

const authInterceptor = (config) => {
    const bearerToken = localStorage.getItem('bearerToken')
    if (bearerToken) {
        config.headers['Authorization'] = 'Bearer ' + bearerToken
    }

    return config
}

client.interceptors.request.use(authInterceptor)

//
// Errors
//

// interceptor to catch errors
const errorInterceptor = (error) => {
    // log.debug('Response Error: ', error.response)
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        log.debug('error.response ===>')
        log.debug(error.response.data)
        log.debug(error.response.status)
        log.debug(error.response.headers)

        let responseStatus = error.response.data.ResponseStatus

        // Redirect user to login page
        //
        if (error.response.status == 401) {
            router.push({ name: 'login' })
        } else {
            // Display error
            //
            Notification.open({
                duration: 5000,
                position: 'is-top-right',
                type: 'is-danger',
                hasIcon: true,
                message: `${responseStatus.ErrorCode}: ${responseStatus.Message}`,
            })
            // Vue.bus.emit("on-response-error", responseStatus);
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        log.debug('error.request ===>')
        log.debug(error.request)
        // console.info(error)
        Notification.open({
            duration: 5000,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
            message: `Server Timeout!`,
        })
    } else {
        // Something happened in setting up the request that triggered an Error
        log.debug('setting up request error ===>')
        log.debug('Error', error.message)
        Notification.open({
            duration: 5000,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
            message: `Unexpected Error: ${error.message}`,
        })
    }

    return Promise.reject(error)
}

// Interceptor for responses
const responseInterceptor = (response) => {
    switch (response.status) {
        case 200:
            // yay!
            return Promise.resolve(response)
        // any other cases
        default:
        // default case
    }

    return Promise.resolve(response)
}

client.interceptors.response.use(responseInterceptor, errorInterceptor)

export default client
export { getBackendBaseUrl }
