<template>
    <BDetailView
        :loading="isLoading"
        @backButtonClick="$router.push({ name: 'roleList' })"
        prevPathName="Role"
        @prevPathClick="$router.push({ name: 'roleList' })"
        :currentPathName="roleQuery.firstRow.Name"
        typeName="Role"
        :viewName="roleQuery.firstRow.Name"
        :tabItems="tabItems"
        :actionPermission="actionPermission"
        :actionItems="actionItems"
    />
</template>

<script>

import Command from '@/libs/Command'
import Query from '@/libs/Query'
import RoleDetail from '@/views/Account/Role/DetailTabs/DetailTab.vue'
import RoleUser from '@/views/Account/Role/DetailTabs/UserTab.vue'
import RolePermission from '@/views/Account/Role/DetailTabs/PermissionTab.vue'

export default {
    name: 'RoleDetail',
    props: {
        roleId: {
            type: [String]
        },
        tabId: {
            type: [String, Number],
            default: 0
        }
    },
    components: {
        RoleDetail
    },
    data() {
        return {
            tabItems: [
                { component: RoleDetail, label: 'Details' },
                { component: RoleUser, label: 'User' },
                { component: RolePermission, label: 'Permission' }        
            ],
            actionPermission: {
                resources: 'Role',
                any: true,
                actions: ['Edit', 'Delete']
            },
            actionItems: [
                {
                    label: 'Edit',
                    do: () =>
                        this.$router.push({
                            name: 'RoleEdit',
                            params: { roleId: this.$route.params.roleId }
                        }),
                    permission: { resources: 'Role', actions: ['Edit'] }
                },
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(),
                    permission: { resources: 'Role', actions: ['Delete'] }
                }
            ],
            roleQuery: new Query(this.$http, 'RolesGet', {
                Ids: this.$route.params.roleId
            }),
            deleteRoleCommand: new Command(this.$http, 'DELETE', 'RoleRemove')
        }
    },
    created() {
        this.roleQuery.execute()
    },
    computed: {
        isLoading() {
            return this.roleQuery.isLoading || this.deleteRoleCommand.isLoading
        }
    },
    methods: {
        confirmDelete() {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: "Are you sure you want to delete this Role: <b>'" + this.roleQuery.firstRow.Name + "'</b>?",
                confirmText: 'Delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteRoleCommand.execute({ Ids: this.$route.params.roleId }).then(resp => {
                        if (resp.status === 204) {
                            this.$router.push({ name: 'roleList' })
                        }
                    })
                }
            })
        }
    }
}
</script>