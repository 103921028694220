<template>
    <div v-if="remaining">
        <b-tooltip :label="fulltext">
            <span v-if="remaining.Year > 0">
                <span v-if="remaining.Months > 0 || remaining.Days > 0">~</span>
                {{ remaining.Years }} {{ 'Year' | pluralize(remaining.Years) }}                
            </span>
            
            <span v-else-if="remaining.Months > 0">
                <span v-if="remaining.Days > 0">~</span>
                {{ remaining.Months }} {{ 'Month' | pluralize(remaining.Months) }}
            </span>
            
            <span v-else-if="remaining.Days > 0">
                {{ remaining.Days }} {{ 'Day' | pluralize(remaining.Days) }}
            </span>
        </b-tooltip>
    </div>
</template>

<script>

export default {
    name: 'BRemainingPeriod',
    props: {
        remaining: {
            type: [Object]
        }
    },
    data() {
        return {

        }
    },
    computed: {
        fulltext() {
            let text = ''
            const { Years, Months, Days } = this.remaining

            if(Years > 0) {
                text += Years.toString() + ' ' + this.$pluralize('Year', Years) + ' '
            }
            
            if(Months > 0) {
                text += Months.toString() + ' ' + this.$pluralize('Month', Months) + ' '
            }

            if(Days > 0) {
                text += Days.toString() + ' ' + this.$pluralize('Days', Days)
            }
            
            return text
        }
    }
}
</script>

<style scoped>
</style>