<template>
    <div id="cus-create" class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="createCustomerCommand.isLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Create Customer</div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Name*"
                            :type="{ 'is-danger': $v.input.name.$error }"
                            :message="{ 'Customer Name': $v.input.name.$error }"
                        >
                            <b-input
                                name="name"
                                v-model="input.name"
                                ref="cusName"
                                placeholder="Customer Name"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Address*"
                            :type="{ 'is-danger': $v.input.address.$error }"
                            :message="{ 'Customer Address': $v.input.address.$error }"
                        >
                            <b-input
                                name="address"
                                v-model="input.address"
                                ref="cusAddress"
                                placeholder="Customer Address"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field horizontal label="Location*">
                            <b-field
                                :type="{ 'is-danger': $v.input.lat.$error }"
                                :message="{ 'POS Latitude': $v.input.lat.$error }"
                            >
                                <b-input
                                    name="lat"
                                    v-model="input.lat"
                                    ref="cusLat"
                                    placeholder="Latitude"
                                    expanded
                                ></b-input>
                            </b-field>
                            <b-field
                                :type="{ 'is-danger': $v.input.lng.$error }"
                                :message="{ 'POS Longitude': $v.input.lat.$error }"
                            >
                                <b-input
                                    name="lng"
                                    v-model="input.lng"
                                    ref="cusLng"
                                    placeholder="Longitude"
                                    expanded
                                ></b-input>
                            </b-field>
                        </b-field>

                        <b-field
                            horizontal
                            label="Country*"
                            :type="{ 'is-danger': $v.input.CountryCode.$error }"
                            :message="{ 'Please select country': $v.input.CountryCode.$error }"
                        >
                            <b-select name="country" v-model="input.CountryCode" placeholder="Select a country">
                                <option
                                    v-for="(option, index) in countryListGet.Data"
                                    :value="option.Code"
                                    :key="index"
                                >
                                    {{ option.Name }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field
                            horizontal
                            label="Phone Number*"
                            :type="{ 'is-danger': $v.input.phoneNumber.$error }"
                            :message="{ 'Customer Phone Number': $v.input.phoneNumber.$error }"
                        >
                            <b-input
                                name="phone"
                                v-model="input.phoneNumber"
                                ref="cusPhoneNumber"
                                type="tel"
                                placeholder="Customer Phone Number"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Email*"
                            :type="{ 'is-danger': $v.input.email.$error }"
                            :message="{ 'Customer Email': $v.input.email.$error }"
                        >
                            <b-input
                                name="email"
                                v-model="input.email"
                                ref="cusEmail"
                                type="email"
                                placeholder="Customer Email"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Server URL"
                            :type="{ 'is-danger': $v.input.ServerUrl.$error }"
                            :message="{ 'URL format': $v.input.ServerUrl.$error }"
                        >
                            <b-input
                                name="serverURL"
                                v-model="input.ServerUrl"
                                ref="cusServerURL"
                                placeholder="Server URL"
                                expanded
                            ></b-input>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Create</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Get from '@/libs/Get'
import Command from '@/libs/Command'
import { required, numeric, email, url } from 'vuelidate/lib/validators'

export default {
    name: 'ManageCustomerCreate',
    props: {
        msg: String,
    },
    data() {
        return {
            input: {
                name: '',
                address: '',
                lat: '',
                lng: '',
                phoneNumber: '',
                email: '',
                ServerUrl: '',
                CountryCode: '',
            },
            createCustomerCommand: new Command(this.$http, 'POST', 'CustomersAdd'),
            countryListGet: new Get(this.$http, 'SupportedCountriesGet'),
        }
    },
    created() {
        this.countryListGet.execute({})
    },
    validations: {
        input: {
            name: {
                required,
            },
            address: {
                required,
            },
            phoneNumber: {
                required,
                numeric,
            },
            email: {
                required,
                email,
            },
            ServerUrl: {
                url,
            },
            lat: {
                required,
            },
            lng: {
                required,
            },
            CountryCode: {
                required,
            },
        },
    },
    methods: {
        createCustomer() {
            let locationText = ''
            if (this.input.lat.length > 0 && this.input.lng.length > 0)
                locationText = this.input.lat + ' , ' + this.input.lng

            this.createCustomerCommand
                .execute({
                    Customers: [
                        {
                            Name: this.input.name,
                            Address: this.input.address,
                            Location: {
                                Latitude: this.input.lat,
                                Longitude: this.input.lng,
                            },
                            PhoneNumber: this.input.phoneNumber,
                            Email: this.input.email,
                            ServerUrl: this.input.ServerUrl,
                            CountryCode: this.input.CountryCode,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 201) {
                        this.$router.push({ name: 'customerList' })
                    }
                })
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.createCustomer()
        },
    },
}
</script>
