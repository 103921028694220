<template>
    <b-autocomplete
        v-model="myKeyword"
        :data="itemRows"
        :keep-first="true"
        :open-on-focus="true"
        :clearable="true"
        :placeholder="placeholderText"
        :icon="iconState"
        :field="displayField"
        :clear-on-select="clearOnSelect"
        @focus="getItems(myKeyword)"
        @typing="(value) => getItems(value)"
        @select="(value) => selectItems(value)"
        :disabled="isDisabled"
    >
    </b-autocomplete>
</template>

<script>
import Query from '@/libs/Query'

export default {

    props: {
        QueryAPIName: {
            type: [String],
            required: true
        },
        QueryParams: {
            type: [Object],
            default: () => ({ Keyword: '' })        
        },
        placeholderText: {
            type: [String],
            required: true,
        },
        displayField: {
            type: [String],
            default: 'Name',
        },
        isDisabled: {
            type: [Boolean],
            default: false,
        },
        clearOnSelect: {
            type: [Boolean],
            default: false,
        }
    },
    data() {
        return {
            myKeyword: '',
            itemRows: [],
            //itemQuery: new Query(this.$http, this.QueryAPIName, { Keyword: '' }, 1, 0, this.displayField),
            itemQuery: new Query(this.$http, this.QueryAPIName, this.QueryParams, 1, 0, this.displayField),
        }
    },
    methods: {
        async getItems(value) {
            this.QueryParams.Keyword = value
            await this.itemQuery.execute(this.QueryParams)
            this.itemRows = this.itemQuery.Rows
            this.itemRows.unshift({ Id: '', Name: '' })
        },
        selectItems(value) {
            this.$emit('onItemSelect', value);
        },
        clear() {
            this.myKeyword = ''
            this.$emit('onItemSelect', this.itemRows[0]);
        }
    },
    computed: {
        iconState() {
            return this.itemQuery.isLoading ? 'loading' : 'magnify'
        }
    },
    // watch: {},
    // mounted() {},
}
</script>

<style scoped>
</style>
