<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active="isLoading"></b-loading>

                <!-- Edit -->
                <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/users" exact><span class="item-link">User</span></router-link>
                        </li>
                        <li>
                            <router-link
                                :to="{ name: 'UserDetail', params: { userId: this.$route.params.userId, tabId: 0 } }"
                                exact
                                ><span class="item-link">{{ itemDetail.UserName }}</span></router-link
                            >
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page">Edit {{ itemDetail.UserName }}</a>
                        </li>
                    </ul>
                </nav>
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-edit">
                        <div class="edit-title">Edit {{ itemDetail.UserName }}</div>
                    </header>
                    <div class="card-content">
                        <b-field horizontal label="Enabled" message="Enabled/Disabled user status">
                            <b-switch v-model="isSwitchedCustom" type="is-info" true-value="Yes" false-value="No">
                                {{ isSwitchedCustom }}
                            </b-switch>
                        </b-field>

                        <b-field
                            horizontal
                            label="Username*"
                            :type="{ 'is-danger': $v.input.username.$error }"
                            :message="{ Username: $v.input.username.$error }"
                        >
                            <b-input name="username" v-model="input.username" autocomplete="off" expanded></b-input>
                        </b-field>

                        <hr />

                        <b-field horizontal label="Full Name">
                            <b-input name="fullame" v-model="input.fullname" expanded></b-input>
                        </b-field>

                        <b-field horizontal label="Gender">
                            <b-select name="gender" v-model="input.gender" placeholder="Select a gender">
                                <option
                                    v-for="(option, index) in genderSelect"
                                    :value="option"
                                    :key="index"
                                    :selected="option === input.gender ? true : false"
                                >
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>

                        <!-- date-parser="dateParser" -->
                        <b-field horizontal label="Date of Birth">
                            <b-datepicker
                                v-model="input.birthDate"
                                placeholder="Type or select a date..."
                                icon="calendar-today"
                                style="display: block;"
                                :date-formatter="dateFormatter"
                                :date-parser="dateParser"
                                editable
                            >
                            </b-datepicker>
                        </b-field>

                        <b-field horizontal label="Address">
                            <b-input name="address" v-model="input.address" expanded></b-input>
                        </b-field>

                        <b-field horizontal label="Country">
                            <b-select name="country" v-model="input.country" placeholder="Select a country">
                                <option v-for="(option, index) in countrySelect" :value="option" :key="index">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field horizontal label="Postal Code">
                            <b-input name="postalCode" v-model="input.postalCode" expanded></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Phone Number"
                            :type="{ 'is-danger': $v.input.phoneNumber.$error }"
                            :message="{ 'only number': $v.input.phoneNumber.$error }"
                        >
                            <b-input name="phoneNumber" v-model="input.phoneNumber" type="tel" expanded></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Email"
                            :type="{ 'is-danger': $v.input.email.$error }"
                            :message="{ 'Email Format': $v.input.email.$error }"
                        >
                            <b-input name="email" v-model="input.email" type="email" expanded></b-input>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-edit">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm">Update</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CountryList from 'country-list'
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required, numeric, email, url } from 'vuelidate/lib/validators'

const countryList = CountryList.getNames()

export default {
    name: 'AccountUsersEdit',
    props: {
        msg: String,
        userId: Number,
    },
    data() {
        return {
            /* Parameter */
            userEditId: this.$route.params.userId,

            input: {
                id: '',
                username: '',
                fullname: '',
                gender: 'Undefined',
                birthDate: null,
                address: '',
                country: null,
                postalCode: '',
                phoneNumber: '',
                email: '',
            },

            /* Detail Item */
            itemDetail: {},

            // Command
            userQuery: new Query(this.$http, 'UserGet', {
                Ids: this.$route.params.userId,
            }),
            editUserCommand: new Command(this.$http, 'PUT', 'UserUpdate'),

            /* Switch*/
            isSwitchedCustom: 'Yes',

            /* Select Option */
            genderSelect: ['Undefined', 'Male', 'Female'],
            countrySelect: countryList,
        }
    },
    validations: {
        input: {
            username: {
                required,
            },
            phoneNumber: {
                numeric,
            },
            email: {
                email,
            },
        },
    },
    created() {
        this.getItem()
    },
    computed: {
        isLoading() {
            return this.userQuery.isLoading || this.editUserCommand.isLoading
        },
    },
    methods: {
        dateFormatter(d) {
            return d.toLocaleDateString('en-GB')
        },
        dateParser(d) {
            let DD = d.substring(0, 1)
            let MM = d.substring(3, 4)
            let YYYY = d.substring(6, 9)
            let parseObj = new Date(Date.parse(YYYY + '-' + MM + '-' + DD))
            return parseObj
        },
        getItem() {
            this.userQuery.execute().then(() => {
                this.itemDetail = this.userQuery.firstRow
                //this.input = Object.assign(this.input, this.itemDetail)
                this.input.username = this.itemDetail.UserName
                this.input.fullname = this.itemDetail.FullName
                this.input.gender = this.itemDetail.Gender
                if (this.itemDetail.DateOfBirth) this.input.birthDate = new Date(this.itemDetail.DateOfBirth)
                this.input.address = this.itemDetail.Address
                this.input.country = this.itemDetail.Country
                this.input.postalCode = this.itemDetail.PostalCode
                this.input.phoneNumber = this.itemDetail.PhoneNumber
                this.input.email = this.itemDetail.Email
                this.isSwitchedCustom = this.itemDetail.Enabled ? 'Yes' : 'No'
            })
        },
        updateUser() {
            this.editUserCommand
                .execute({
                    Users: [
                        {
                            Id: this.userEditId,
                            UserName: this.input.username,
                            Enabled: this.isSwitchedCustom === 'Yes',
                            FullName: this.input.fullname,
                            Email: this.input.email,
                            Gender: this.input.gender,
                            DateOfBirth: new Date(this.input.birthDate),
                            Address: this.input.address,
                            Company: this.input.company,
                            Country: this.input.country,
                            PostalCode: this.input.postalCode,
                            PhoneNumber: this.input.phoneNumber,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$router.go(-1)
                    }
                })
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.updateUser()
        },
    },
}
</script>
