import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import { MainLayout } from '@/layouts'

import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard/Dashboard.vue'

// Manage - Customer
import ManageCustomersList from '@/views/Manage/Customer/ManageCustomersList.vue'
import ManageCustomerCreate from '@/views/Manage/Customer/ManageCustomerCreate.vue'
import ManageCustomerDetail from '@/views/Manage/Customer/ManageCustomerDetail.vue'
import ManageCustomerEdit from '@/views/Manage/Customer/ManageCustomerEdit.vue'
import ManageCustomerAssignGroup from '@/views/Manage/Customer/ManageCustomerAssignGroup.vue'

// Manage - Customer Group
import ManageCustomerGroup from '@/views/Manage/CustomerGroup/ManageCustomerGroupList.vue'
import ManageCustomerGroupCreate from '@/views/Manage/CustomerGroup/ManageCustomerGroupCreate.vue'
import ManageCustomerGroupDetail from '@/views/Manage/CustomerGroup/ManageCustomerGroupDetail.vue'
import ManageCustomerGroupEdit from '@/views/Manage/CustomerGroup/ManageCustomerGroupEdit.vue'
import ManageCustomerGroupCreateSubGroup from '@/views/Manage/CustomerGroup/ManageCustomerGroupCreateSubGroup.vue'
import ManageCustomerGroupEditSubGroup from '@/views/Manage/CustomerGroup/ManageCustomerGroupEditSubGroup.vue'

// Manage - Product
import ManageProduct from '@/views/Manage/Product/ManageProductList.vue'
import ManageProductCreate from '@/views/Manage/Product/ManageProductCreate.vue'
import ManageProductDetail from '@/views/Manage/Product/ManageProductDetail.vue'
import ManageProductEdit from '@/views/Manage/Product/ManageProductEdit.vue'
import ManageProductVersionCreate from '@/views/Manage/Product/ProductVersionCreate.vue'
import ManageProductVersionEdit from '@/views/Manage/Product/ProductVersionEdit.vue'

// Manage - Machine
import ManageMachine from '@/views/Manage/Machine/ManageMachineList.vue'
import ManageMachineRegister from '@/views/Manage/Machine/ManageMachineRegister.vue'
import ManageMachineDetail from '@/views/Manage/Machine/ManageMachineDetail.vue'
import ManageMachineEdit from '@/views/Manage/Machine/ManageMachineEdit.vue'
import ManageMachineWarrantyCreate from '@/views/Manage/Machine/MachineWarrantyCreate.vue'
import ManageMachineWarrantyEdit from '@/views/Manage/Machine/MachineWarrantyEdit.vue'
import ManageMachineLicenseCreate from '@/views/Manage/Machine/MachineLicenseCreate.vue'
import ManageMachineLicenseEdit from '@/views/Manage/Machine/MachineLicenseEdit.vue'

// Manage - License
import ManageLicense from '@/views/Manage/License/ManageLicenseListMachine.vue'
import ManageLicenseServer from '@/views/Manage/License/ManageLicenseListServer.vue'
import ManageLicenseCreate from '@/views/Manage/License/ManageLicenseCreate.vue'
import ManageLicenseDetail from '@/views/Manage/License/ManageLicenseDetail.vue'
import ManageLicenseEdit from '@/views/Manage/License/ManageLicenseEdit.vue'
import LicenseActivationAdd from '@/views/Manage/License/LicenseAddActivation.vue'
import LicenseActivationUpload from '@/views/Manage/License/LicenseUploadActivation.vue'

// Audit Log
import AuditLog from '@/views/AuditLog/AuditLog.vue'
import AuditLogDetail from '@/views/AuditLog/AuditLogDetail.vue'

/* Account */
import MyAccount from '@/views/Account/MyAccount/MyAccount.vue'
import MyAccountEdit from '@/views/Account/MyAccount/MyAccountEdit.vue'

// Account - User
import AccountUsers from '@/views/Account/User/UserList.vue'
import AccountUsersCreate from '@/views/Account/User/UserCreate.vue'
import AccountUsersDetail from '@/views/Account/User/UsersDetail.vue'
import AccountUsersEdit from '@/views/Account/User/UsersEdit.vue'

// Account - Role
import AccountRoles from '@/views/Account/Role/RoleList.vue'
import AccountRolesCreate from '@/views/Account/Role/RoleCreate.vue'
import AccountRolesDetail from '@/views/Account/Role/RoleDetail.vue'
import AccountRolesEdit from '@/views/Account/Role/RoleEdit.vue'

// Settings
import Settings from '@/views/Settings.vue'

let router = new VueRouter({
    // mode: 'history',
    routes: [
        { path: '/', redirect: '/login' },
        { path: '/login', name: 'login', component: Login },

        // Dashboard
        {
            path: '/dashboard',
            component: Dashboard,
            name: 'dashboard',
        },

        { path: '/customers', component: ManageCustomersList, name: 'customerList' },
        { path: '/create/customer', name: 'customerCreate', component: ManageCustomerCreate },
        { path: '/customer/:customerId/tab/:tabId', name: 'customerDetail', component: ManageCustomerDetail },
        { path: '/customerEdit/:customerId', name: 'customerEdit', component: ManageCustomerEdit },
        {
            path: '/customerAssign/:customerId/group',
            name: 'customerAssignGroup',
            component: ManageCustomerAssignGroup,
        },
        {
            path: '/customerGroup',
            name: 'customerGroupList',
            component: ManageCustomerGroup,
            meta: { parent: 'Manage' },
        },
        { path: '/create/customerGroup', name: 'customerGroupCreate', component: ManageCustomerGroupCreate },
        {
            path: '/customerGroup/:customerGroupId/tab/:tabId',
            name: 'customerGroupDetail',
            component: ManageCustomerGroupDetail,
            props: true,
        },
        { path: '/customerGroupEdit/:customerGroupId', name: 'customerGroupEdit', component: ManageCustomerGroupEdit },
        {
            path: '/create/customerSubGroup/:parentGroupId',
            name: 'customerSubGroupCreate',
            component: ManageCustomerGroupCreateSubGroup,
            props: true,
        },
        {
            path: '/customerGroup/:customerGroupId/subGroup/:subGroupId',
            name: 'customerSubGroupEdit',
            component: ManageCustomerGroupEditSubGroup,
        },

        { path: '/product', name: 'productList', component: ManageProduct, meta: { parent: 'Manage' } },
        { path: '/create/product', name: 'productCreate', component: ManageProductCreate },
        { path: '/product/:productId/tab/:tabId', name: 'productDetail', component: ManageProductDetail },
        { path: '/productEdit/:productId', name: 'productEdit', component: ManageProductEdit },
        { path: '/productVersionAdd/:productId', name: 'versionCreate', component: ManageProductVersionCreate },
        { path: '/productVersionEdit/:productId/:versionId', name: 'versionEdit', component: ManageProductVersionEdit },

        { path: '/machine', name: 'machineList', component: ManageMachine, meta: { parent: 'Manage' } },
        { path: '/register/machine', name: 'machineRegister', component: ManageMachineRegister },
        { path: '/machine/:machineId/tab/:tabId', name: 'machineDetail', component: ManageMachineDetail },
        { path: '/machineEdit/:machineId', name: 'machineEdit', component: ManageMachineEdit },
        { path: '/machineWarrantyAdd/:machineId', name: 'warrantyCreate', component: ManageMachineWarrantyCreate },
        {
            path: '/machineWarrantyEdit/:machineId/:warrantyId',
            name: 'warrantyEdit',
            component: ManageMachineWarrantyEdit,
        },
        { path: '/machineLicenseAdd/:machineId', name: 'machineLicenseAdd', component: ManageMachineLicenseCreate },
        {
            path: '/machineLicenseEdit/:machineId/:licenseId',
            name: 'machineLicenseEdit',
            component: ManageMachineLicenseEdit,
        },

        {
            name: 'licenseListMachine',
            path: '/license-list-machine',
            component: () => import('@/views/Manage/License/ManageLicenseListMachine'),
        },
        {
            name: 'licenseListServer',
            path: '/license-list-server',
            component: () => import('@/views/Manage/License/ManageLicenseListServer'),
        },
        { path: '/create/license/:type', name: 'licenseCreate', component: ManageLicenseCreate },
        { path: '/license/:licenseId/tab/:tabId', name: 'licenseDetail', component: ManageLicenseDetail },
        { path: '/licenseEdit/:licenseId', name: 'licenseEdit', component: ManageLicenseEdit },
        { path: '/licenseActivationAdd/:licenseId', name: 'licenseActivationAdd', component: LicenseActivationAdd },
        { path: '/licenseActivationUpload', name: 'licenseActivationUpload', component: LicenseActivationUpload },

        { path: '/auditLog', name: 'auditlog', component: AuditLog },
        { path: '/auditLog/:auditLogId/tab/:tabId', component: AuditLogDetail },

        { path: '/myAccount/tab/:tabId', name: 'MyAccount', component: MyAccount, props: true },
        { path: '/myAccountEdit', name: 'MyAccountEdit', component: MyAccountEdit },

        { path: '/users', name: 'UserList', component: AccountUsers, meta: { parent: 'Account' } },
        { path: '/create/user', name: 'UserCreate', component: AccountUsersCreate },
        { path: '/users/:userId/tab/:tabId', name: 'UserDetail', component: AccountUsersDetail },
        { path: '/userEdit/:userId', name: 'UserEdit', component: AccountUsersEdit },

        { path: '/roles', name: 'RoleList', component: AccountRoles, meta: { parent: 'Account' } },
        { path: '/create/role', name: 'RoleCreate', component: AccountRolesCreate },
        { path: '/role/:roleId/tab/:tabId', name: 'RoleDetail', component: AccountRolesDetail },
        { path: '/roleEdit/:roleId', name: 'RoleEdit', component: AccountRolesEdit },

        { path: '/users', name: 'userList', component: AccountUsers, meta: { parent: 'Account' } },
        { path: '/create/user', name: 'userCreate', component: AccountUsersCreate },
        { path: '/users/:userId/tab/:tabId', name: 'userDetail', component: AccountUsersDetail },
        { path: '/userEdit/:userId', name: 'userEdit', component: AccountUsersEdit },

        {
            path: '/roles-new',
            name: 'roleList-new',
            component: () => import('@/views/Account/Role/RoleList-VueSync'),
        },
        { path: '/roles', name: 'roleList', component: AccountRoles, meta: { parent: 'Account' } },
        { path: '/create/role', name: 'roleCreate', component: AccountRolesCreate },
        { path: '/role/:roleId/tab/:tabId', name: 'roleDetail', component: AccountRolesDetail },
        { path: '/roleEdit/:roleId', name: 'roleEdit', component: AccountRolesEdit },

        { path: '/settings', name: 'settings', component: Settings },

        /* otherwise redirect to home */
        { path: '*', redirect: '/login' },
    ],
})

router.beforeEach((to, from, next) => {
    const publicPages = ['/login']
    const authRequired = !publicPages.includes(to.path)
    const loggedIn = localStorage.getItem('bearerToken')

    if (authRequired && !loggedIn) {
        return next('/login')
    }

    next()
})

export default router
