import Vue from 'vue'
import Vuex from 'vuex'
import client from '@/libs/api-client'

Vue.use(Vuex)

function userPermissionToTokens(permissions) {
    // Convert permission object to string token "{Resource}-{Permission}"
    //
    if (!permissions || permissions.length == 0) return []

    let allowResources = permissions.filter((p) => p.HasPermission)
    let tokens = allowResources.flatMap((r) =>
        r.Actions.filter((a) => a.HasPermission).map((a) => `${r.Resource}-${a.Action}`)
    )
    return tokens
}

function requiredPermissionToTokens(required) {
    // Convert required permission object to string token "{Resource}-{Permission}"
    //
    if (!required || !required.resources || !required.actions) return []

    let tokens = required.resources.flatMap((r) => required.actions.map((a) => `${r}-${a}`))
    return tokens
}

export default new Vuex.Store({
    state: {
        status: '',
        bearerToken: localStorage.getItem('bearerToken') || '',
        account: {},
        error: '',
        params: {},
        myPermissionTokens: null,
    },
    getters: {
        isLoggedIn: (state) => typeof state.bearerToken == 'string' && state.bearerToken.length > 0,
        authStatus: (state) => state.status,
        bearerToken: (state) => state.bearerToken,
        currentAccount: (state) => state.account,
        errorInfo: (state) => state.error,
        isMyPermissionsLoaded: (state) => state.myPermissionTokens != null,
        myPermissionTokens: (state) => state.myPermissionTokens,
    },
    mutations: {
        auth_request(state) {
            state.status = 'loading'
        },
        auth_success(state, account) {
            state.status = 'success'
            state.bearerToken = account.BearerToken
            state.account = account
        },
        auth_error(state, err) {
            state.status = 'error'
            state.error = err
        },
        logout(state) {
            state.status = ''
            state.bearerToken = ''
        },
        myPermissionTokens(state, data) {
            state.myPermissionTokens = data
        },
    },
    actions: {
        async loginAsync({ dispatch, commit }, user) {
            try {
                commit('auth_request')
                let resp = await client({
                    url: '/json/reply/Authenticate',
                    method: 'POST',
                    data: user,
                })

                // console.log(resp)
                const token = resp.data.BearerToken
                const account = resp.data
                const userId = resp.data.UserId
                const userName = resp.data.UserName
                const lang = resp.data.Meta.Language
                const roles = resp.data.Meta.Roles
                localStorage.setItem('userId', userId)
                localStorage.setItem('userName', userName)
                localStorage.setItem('bearerToken', token)
                localStorage.setItem('currLang', lang)
                localStorage.setItem('currRoles', roles)

                commit('auth_success', account)
                //console.log('login load permissions')
                // console.log(
                //   "====== login success with username: " +
                //     localStorage.getItem("userName")
                // )

                await dispatch('loadMyPermissionsAsync')
            } catch (err) {
                commit('auth_error', err)
                localStorage.removeItem('bearerToken')
                localStorage.removeItem('userId')
                localStorage.removeItem('userName')
                localStorage.removeItem('currRoles')

                // console.log(err)
                throw err
                // console.log('====== login error with username: ' + localStorage.getItem('userName'));
            }
        },
        async loadMyPermissionsAsync({ commit, getters, state }) {
            if (!getters.isLoggedIn) return

            //   console.log("Loading permissions...")
            let resp = await client({
                url: '/json/reply/PermissionsOfMyAccountGet',
            })

            let tokens = userPermissionToTokens(resp.data.Permissions)
            //   console.log(tokens)
            commit('myPermissionTokens', tokens)
            //   console.log("Permissions loaded.")
        },

        logout({ commit }) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('bearerToken')
                localStorage.removeItem('userId')
                localStorage.removeItem('userName')
                localStorage.removeItem('currLang')
                localStorage.removeItem('currRoles')
                // delete this.$http.defaults.headers.common['Authorization']
                resolve()
            })
        },

        hasPermission({ state, getters }, payload) {
            if (!getters.isMyPermissionsLoaded) return false
            if (!payload.resources || payload.resources.length == 0) return false

            let required_tokens = requiredPermissionToTokens(payload)
            if (required_tokens.length == 0) return false

            // Check required permissions against user's permissions
            //
            if (payload.permitAny) {
                for (let required of required_tokens) {
                    let allow = getters.myPermissionTokens.some((u) => u === required)
                    if (allow) return true
                }
                return false
            } else {
                for (let required of required_tokens) {
                    let allow = getters.myPermissionTokens.some((u) => u === required)
                    if (!allow) return false
                }
                return true
            }
        },
    },
})
