<template>
    <BTabContent :loading="isLoading">
        <div class="detail-item-content">
            <template v-if="hasCustomer">
                <BListview 
                pageTitle="License"
                :disableSearchByKeyword=true
                QueryAPIName="LicensesOfMachineQuery"
                :QueryParams="params"
                DeleteAPIName="LicensesRemove"

                @onPageSizeChange="(value) => { params.pageSize = value; }"
                @onPageChange="(value) => { params.currentPage = value; }"
                @onDeleted="(value) => { if(value) { selectedRows = []; } }"
                ref="listview">

                    <template v-slot:actionButton>
                        <BActionButton
                            v-if="selectedRows.length > 0"
                            :actionPermission="actionPermission"
                            :actionItems="actionItems"
                            :selectedRows="selectedRows"
                        />
                        <button
                            v-if="selectedRows.length == 0 && rowCount == 0"
                            class="button is-info"
                            @click="
                                $router.push({
                                    name: 'machineLicenseAdd',
                                    params: { machineId: $route.params.machineId },
                                })
                            "
                        >
                            Create
                        </button>
                    </template>

                    <template v-slot:filterInLine>
                        <BFilter
                            tooltip="Status"
                            class="level-item"
                            :loading="false"
                            v-model="filterStatus"
                            :options="statusOptions"
                            @change="
                                (Item) => {
                                    params.Statuses = Item.Id
                                }
                            "
                        ></BFilter>
                    </template>

                    <template v-slot:default="{ result }" >
                        <b-table
                            :data="result"
                            backend-pagination
                            backend-sorting
                            checkable
                            :checked-rows.sync="selectedRows"
                            :is-row-checkable="(row) => row.Status !== 'Expired' && row.Status !== 'Cancelled'"
                            :row-class="
                                (row, index) => (row.Status === 'Expired' || row.Status === 'Cancelled') && 'is-gray'
                            "
                            :current-page="params.currentPage"
                            :per-page="params.pageSize"
                            :default-sort-direction="params.sortDirection"
                            :default-sort="params.sortColumn"
                            @sort="
                                (field, order) => {
                                    params.sortColumn = field
                                    params.sortDirection = order
                                }
                            "
                            truncate-column
                        >
                            <b-table-column field="Type" label="Type" v-slot="props">
                                {{ props.row.Type }}
                            </b-table-column>

                            <b-table-column field="Periods" label="Periods" sortable v-slot="props">
                                <div v-if="props.row.Periods">
                                    <BMonthsToYearMonth :months="props.row.Periods" />
                                </div>
                            </b-table-column>

                            <b-table-column field="RemainingPeriod" label="Remaining" sortable v-slot="props">
                                <BRemainingPeriod :remaining="props.row.RemainingPeriod" />
                            </b-table-column>

                            <b-table-column field="Status" label="Status" sortable v-slot="props">
                                <span class="tag" v-if="props.row.Status === 'Ready'">
                                    Ready
                                </span>
                                <span class="tag is-success" v-if="props.row.Status === 'Activated'">
                                    Activated
                                </span>
                                <span class="tag is-danger" v-else-if="props.row.Status === 'Expired'">
                                    {{ props.row.Status }}
                                </span>
                                <span class="tag" v-else-if="props.row.Status === 'Cancelled'">
                                    {{ props.row.Status }}
                                </span>
                            </b-table-column>

                            <b-table-column field="control" centered v-slot="props">
                                <b-dropdown
                                    position="is-bottom-left"
                                    v-permit="{ resources: 'License', any: true, actions: ['Edit', 'Delete'] }"
                                    v-if="props.row.Status !== 'Expired' && props.row.Status !== 'Cancelled'"
                                >
                                    <button class="button border-0 btn-control" slot="trigger">
                                        <b-icon icon="dots-horizontal" size="is-small"></b-icon>
                                    </button>
                                    <b-dropdown-item has-link v-permit="{ resources: 'License', actions: ['Edit'] }">
                                        <a style="cursor:pointer;" @click="Download([props.row])">Download</a>
                                    </b-dropdown-item>
                                    <b-dropdown-item has-link v-permit="{ resources: 'License', actions: ['Edit'] }">
                                        <router-link
                                            :to="{
                                                name: 'machineLicenseEdit',
                                                params: { machineId: $route.params.machineId, licenseId: props.row.Id },
                                            }"
                                            exact
                                            >Edit</router-link
                                        >
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        @click="confirmDelete([props.row])"
                                        v-permit="{ resources: 'License', actions: ['Delete'] }"
                                        >Delete</b-dropdown-item
                                    >
                                </b-dropdown>
                            </b-table-column>

                            <template slot="empty">
                                <BEmptyState title="No License found." subtitle=" " />
                            </template>
                        </b-table>
                    </template>

                    <template v-slot:loadingState="{ loadingRows }">
                        <b-table :data="loadingRows">
                            <b-table-column label="Type">
                                <b-skeleton height="35px" :animated="true"></b-skeleton>
                            </b-table-column>

                            <b-table-column label="Periods">
                                <b-skeleton height="35px" :animated="true"></b-skeleton>
                            </b-table-column>

                            <b-table-column label="Remaining">
                                <b-skeleton height="35px" :animated="true"></b-skeleton>
                            </b-table-column>

                            <b-table-column label="Status">
                                <b-skeleton height="35px" :animated="true"></b-skeleton>
                            </b-table-column>

                            <b-table-column field="control">
                                <b-skeleton height="35px" :animated="true"></b-skeleton>
                            </b-table-column>                   
                        </b-table>
                    </template>
                </BListview>
            </template>

            <template v-else>
                <p>Please assign customer for this machine first.</p>
            </template>
        </div>
    </BTabContent>
</template>

<script>
import Get from '@/libs/Get'
import BListview from '@/components/common/BListview'
import BMonthsToYearMonth from '@/components/display/BMonthsToYearMonth'
import BRemainingPeriod from '@/components/display/BRemainingPeriod'

export default {
    props: {
        msg: String,
    },
    components: {
        BListview,
        BMonthsToYearMonth,
        BRemainingPeriod
    },
    url: {
        __root: 'params',
            Statuses: { key: 'status' },
            currentPage: { key: 'page' },
            pageSize: { key: 'size' },
            sortColumn: { key: 'col' },
            sortDirection: { key: 'dir' },
            MachineId: { key:'mid' }
    },
    data() {
        const params = {
            Statuses: '',
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'RemainingPeriod',
            sortDirection: 'desc',
            MachineId: this.$route.params.machineId
        }

        return {
            params,

            rowCount: 1,

            selectedRows: [],
            
            actionPermission: {
                resources: 'License',
                any: true,
                actions: ['Delete'],
            },
            actionItems: [
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(this.selectedRows),
                    permission: { resources: 'License', actions: ['Delete'] },
                },
            ],

            machineInfoGet: new Get(this.$http, 'MachinesGet', { Ids: this.$route.params.machineId }),

            isLoad: false,
            statusOptions: [],
            filterStatus: { Name: 'All Status', Value: '' },
        }
    },
    computed: {
        isLoading() {
            return (
                this.machineInfoGet.isLoading ||
                this.isLoad
            )
        },
        hasCustomer() {
            return (
                this.machineInfoGet.Data &&
                this.machineInfoGet.Data.Rows.length > 0 &&
                this.machineInfoGet.Data.Rows[0].Customer != ''
            )
        },
    },
    async created() {
        this.isLoad = true

        await this.machineInfoGet.execute()

        await this.$http({
            url: '/json/reply/SupportedLicenseStatusesGet ',
        })
        .then((resp) => {
            let defaultItem = { Id: '', Name: 'All Status' }
            let dataArray = []
            resp.data.forEach((element) => {
                dataArray.push({ Id: element, Name: element })
            })

            this.statusOptions = dataArray
            this.statusOptions.unshift(defaultItem)
            this.filterStatus = defaultItem
        })
        .finally(() => {
            this.isLoad = false
        })

        this.rowCount = this.$refs.listview.GetRowCount()

        this.$refs.listview.reload()
        this.$refs.listview.startWatch()
    },
    methods: {
        confirmDelete(selectedRow) {
            let formattedItemsName = selectedRow
                .map((p) => `<b>${p.Type} ${p.Periods + this.$pluralize(' month', p.Periods)}</b>`)
                .join('<br/>')
            let nPos = selectedRow.length > 1 ? selectedRow.length : ''
            let confirmMsg = `Are you sure you want to delete the following ${nPos} License?<br/><br/>`
            let ItemsId = selectedRow.map((p) => p.Id)

            this.$refs.listview.confirmDelete(ItemsId, confirmMsg + formattedItemsName)
            this.rowCount = this.$refs.listview.GetRowCount()
        },
        Download(selectedRow) {
            let ids = selectedRow.map((a) => a.Id)
            let param = '[' + ids.join(',') + ']'
            let pm = this.$http({
                url: '/json/reply/LicensesUnrestrictHardwareIdDownload?LicenseIds=' + param,
                //params: { LicenseIds: ItemIds },
                responseType: 'blob',
            })
                .then((resp) => {
                    const url = window.URL.createObjectURL(new Blob([resp.data]))
                    const link = document.createElement('a')
                    link.href = url

                    let filename = resp.headers['content-disposition'].substring(
                        resp.headers['content-disposition'].indexOf('"') + 1,
                        resp.headers['content-disposition'].lastIndexOf('"')
                    )

                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()
                })
                .finally(() => {
                    //this.isLoad = false
                })
        },
    },
    mounted() {
        
    }
}
</script>

<style>
.is-gray {
    color: #aaa;
}
</style>
