<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Create Product</div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Product Name*"
                            :type="{ 'is-danger': $v.input.Name.$error }"
                            :message="{ 'Product Name': $v.input.Name.$error }"
                        >
                            <b-input
                                name="serialNumber"
                                v-model="input.Name"
                                ref="productSerialNumber"
                                placeholder="Product Name"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Activation Method*"
                            :type="{ 'is-danger': $v.input.ActivationMethod.$error }"
                            :message="{ 'Please Select Activation Method': $v.input.ActivationMethod.$error }"
                        >
                            <b-select
                                name="model"
                                v-model="input.ActivationMethod"
                                ref="machineModel"
                                placeholder="Select an Activation Method"
                            >
                                <option v-for="(option, index) in ActivationMethodOption" :value="option" :key="index">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field horizontal>
                            <b-checkbox v-model="input.IsMultipleActivations">
                                Multiple Activations
                            </b-checkbox>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Create</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ManageProductCreate',
    props: {
        msg: String,
    },
    data() {
        return {
            input: {
                Name: '',
                ActivationMethod: '',
                IsMultipleActivations: false,
            },
            createProductCommand: new Command(this.$http, 'POST', 'ProductsAdd'),
            ActivationMethodOption: ['Machine', 'Server'],
        }
    },
    validations: {
        input: {
            Name: {
                required,
            },
            ActivationMethod: {
                required,
            },
        },
    },
    created() {},
    computed: {
        isLoading() {
            return this.createProductCommand.isLoading
        },
    },
    methods: {
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.createProduct()
        },
        createProduct() {
            this.createProductCommand
                .execute({
                    Products: [
                        {
                            Name: this.input.Name,
                            ActivationMethod: this.input.ActivationMethod,
                            IsMultipleActivations: this.input.IsMultipleActivations,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 201) {
                        this.$router.push({ name: 'productList' })
                    }
                })
        },
    },
}
</script>
