<template>
    <div>
        <span v-if="myYear > 0">{{ myYear }} {{ 'Year' | pluralize(myYear) }}&nbsp;</span>
        <span v-if="myMonth > 0">{{ myMonth }} {{ 'Month' | pluralize(myMonth) }}</span>
    </div>
</template>

<script>

export default {
    name: 'BMonthsToYearMonth',
    props: {
        months: {
            type: [Number],
            required: true,
        }
    },
    data() {
        return {
            myYear: 0,
            myMonth: 0
        }
    },
    mounted() {
        this.myYear = Math.floor(this.months / 12)
        this.myMonth = this.months % 12
    }
}
</script>

<style scoped>
</style>