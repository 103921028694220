<template>
    <MainListLayout>
        <div class="content-container">
            <div class="title">Settings</div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <h2>No Settings</h2>
        </div>
    </MainListLayout>
</template>

<script>
import { MainListLayout } from '@/layouts'

export default {
    name: 'Settings',
    props: {
        msg: String,
    },
    components: {
        MainListLayout,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
