<template>
    <div style="height: 32px; display: flex; align-items: flex-end;">
        <SkeletonLoading v-if="loading">
            <SquareSkeleton
                :count="1"
                :boxProperties="{
                    width: '140px',
                    height: '32px',
                }"
            />
        </SkeletonLoading>
        <div v-else-if="beginRow > 0 && endRow > 0" class="mb-1" style="text-align: right;">
            <span class="has-text-grey">{{ beginRow | numFormat }} - {{ endRow | numFormat }}</span>
            <span class="has-text-grey-light is-italic">&nbsp; of {{ totalRows | numFormat }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BPagingCounter',
    props: {
        loading: {
            type: [Boolean],
            default: false,
        },
        beginRow: {
            type: [Number],
            default: 0,
        },
        endRow: {
            type: [Number],
            default: 0,
        },
        totalRows: {
            type: [Number],
            default: 0,
        },
    },

    data() {
        return {}
    },
    mounted() {},
}
</script>
