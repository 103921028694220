<template>
    <div class="h-100">
        
        <div class="content-section-view">
            <div class="content-container-view">
                <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

                <!-- Edit -->
                <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
                    <ul>
                        <li><router-link to="/license" exact><span class="item-link">License</span></router-link></li>
                        <li class="is-active"><a href="#" aria-current="page">Activation Upload</a></li>
                    </ul>
                </nav>
                <div class="card mb-48">
                    <header class="card-header card-header-edit">
                        <div class="edit-title">Activate {{ itemDetail.Product.Name }} v{{ itemDetail.ProductVersion.Name }}</div>
                    </header>

                    <div class="card-content">
                        <b-field :type="checkHWIdFile.type">
                            <b-upload 
                                name="File"
                                v-model="dropFiles"
                                ref="hwidFile"
                                multiple
                                native
                                drag-drop>
                                <section class="section">
                                    <div class="content has-text-centered">
                                        <p>
                                            <b-icon
                                                icon="upload"
                                                size="is-large">
                                            </b-icon>
                                        </p>
                                        <p>Drop your file here or click to upload.</p>
                                    </div>
                                </section>
                            </b-upload>
                        </b-field>

                        <b-field>
                            <table class="w-100">
                                <tr>
                                    <td>
                                        <div class="tags">
                                            <span v-for="(file, index) in dropFiles"
                                                :key="index"
                                                class="tag is-info" >
                                                {{file.name}}
                                                <button class="delete is-small"
                                                    type="button"
                                                    @click="deleteDropFile(index)">
                                                </button>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div v-if="isLoading && !fullProgress" class="myProgress">
                                            <div class="myBar" :style="{ width: progressBar.percent + '%' }"></div>
                                        </div>
                                        <div v-if="isLoading && fullProgress" class="i-relative mySync">
                                            <div class="i-sync-database">
                                                <b-icon icon="sync" class="i-spin" size="is-medium"></b-icon>
                                            </div>
                                        </div>
                                        <div  v-if="isLoading" style="color:red; text-align:center;">This progress may be taken long time, Please wait!</div>
                                    </td>
                                </tr>
                            </table>
                        </b-field>
                                            
                    </div>
                    
                    <footer class="card-footer card-footer-edit">
                        <div class="level w-100">
                            <div class="level-left">
                                <button class="button has-border-transparent ml-12" @click="$router.go(-1)" data-cy="cancel">Cancel</button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submit">Upload</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Get from '@/libs/Get'
import Query from '@/libs/Query'
import { required, numeric } from 'vuelidate/lib/validators'

export default {
    name: 'ManageLicenseEdit',
    props: {
        'msg': String
    },
    data() {
        return {
            licenseID: this.$route.params.licenseId,
            itemDetail: { Customer: {}, Product: {}, ProductVersion: {} },


            /* Validation */
            checkHWIdFile: {
                type: '',
                message: 'Please select a hardware ids file'
            },

            /* File Upload */
            dropFiles: [],
            fileNotExist : false, 
            /* Progress Bar */
            progressBar: {
                loaded: 0,
                total: 0,
                percent: 0
            },

            isLoading: false,
            fullProgress: false,


            licenseGet: new Query(this.$http, 'LicensesGet', { Ids: [] }, 1, 10, 'RemainingPeriod' ),
        }
    },
    
    created() {
        //this.getItem()
    },     
    // computed: {
    //     isLoading() {
            
    //     }
    // },  
    methods: {        
        async getItem() {
            await this.licenseGet.execute({ Ids: this.licenseID })
            this.itemDetail = this.licenseGet.firstRow
        },
        checkInputHWIdFile() {
            if (this.dropFiles[0]) {
                this.fileNotExist = false
                this.checkHWIdFile.type = ''
                this.checkHWIdFile.message = ''
                return true
            }
            
            this.fileNotExist = true
            this.checkHWIdFile.type = 'is-danger'
            this.checkHWIdFile.message = 'Please select a hardware ids file'
            this.$nextTick(() => this.$refs.hwidFile.focus())
            
            return false
        },        
        checkForm() {
            if (this.checkInputHWIdFile()) {                
                return true
            }

            return false
        },
        deleteDropFile(index) {
            this.dropFiles.splice(index, 1)
        },
        submit() {
            if(this.checkForm()) {
                this.uploadActivations()
            }
        },
        uploadActivations() {
        
            this.isLoading = true

            let data = new FormData()
            // data.append('Id', this.$route.params.databaseId)
            data.append('File', this.dropFiles[0])

            const options = {
                method: "POST",
                headers: {
                    accept: "application/json",          
                },
                data: data,
                // data: this.dropFiles[0],
                url: '/json/oneway/LicenseActivationAddByFiles',
                onUploadProgress: progress => {
                    this.progressBar.loaded = progress.loaded
                    this.progressBar.total = progress.total
                    this.percentShow()
                }
            }

            this.$http(options)
            .then(resp => {
                this.isLoading = false
                if(resp.status === 201) {
                    this.deleteDropFile(0)
                    this.$router.go(-1)
                }
            })
            .catch(err => {
                this.errorMsg = err.response.data.ResponseStatus.Message
                // this.errorMsg = err
                this.isLoading = false
                this.fullProgress = false
                // this.openNotify()
            })
            .finally(() => {
                this.deleteDropFile(0)
            })
        },
        percentShow() {
            setInterval(this.percentValue(), 100)
        },
        percentValue() {
            this.progressBar.percent = (this.progressBar.loaded/this.progressBar.total) * 100
            this.progressBar.percent  = Math.floor(this.progressBar.percent) 
            if(this.progressBar.percent === 100) {
                this.fullProgress = true
            }
        }
    }
}
</script>
