<template>
    <div class="h-100">
       
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Create License for machine: {{ serialNo }}</div>
                    </header>

                    <div class="card-content">
                        <b-field horizontal label="Customer">
                            {{ customerName }}
                        </b-field>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;">
                        <b-field horizontal label='Product*' :type="{'is-danger':$v.input.product.$error}" :message="{'Please select Product': $v.input.product.$error}">
                            <b-autocomplete
                                v-model="productKeyword"
                                :data="productQuery.Rows"
                                :loading="productQuery.isLoading"
                                :keep-first="true"
                                :open-on-focus="true"
                                placeholder="Search Product name..."
                                icon="magnify"
                                field="Name"
                                @focus="getProduct(productKeyword)"
                                @typing="value => getProduct(value)"
                                @select="value => selectProduct(value)"
                                style="width:50%;"
                            >
                            </b-autocomplete>
                        </b-field>

                        <b-field horizontal label='Product Version*' :type="{'is-danger':$v.input.productVersion.$error}" :message="{'Please select Product Version': $v.input.productVersion.$error}">
                            <b-autocomplete
                                v-model="productVersionKeyword"
                                :data="productVersionQuery.Rows"
                                :loading="productVersionQuery.isLoading"
                                :keep-first="true"
                                :open-on-focus="true"
                                placeholder='Search Version Name'
                                icon="magnify"
                                field="Name"
                                @focus="getVersion(productVersionKeyword)"
                                @typing="value => getVersion(value)"
                                @select="value => selectVersion(value)"
                                :disabled="input.product == ''"
                                style="width:50%;"
                            >
                            </b-autocomplete>
                        </b-field>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;">
                        <b-field horizontal label="License Type*"  :type="{'is-danger': $v.input.Type.$error}" :message="{ 'Please select a License Type': $v.input.Type.$error}" >
                            <b-select name="licenseType" v-model="input.Type" ref="licenseType" placeholder="Select a License Type">
                                <option
                                    v-for="(option, index) in licenseTypes"
                                    :value="option"
                                    :key="index">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>
   
                        <b-field 
                            horizontal 
                            label="Period*"
                            :type="{ 'is-danger': $v.input.maxActivation.$error }"
                            :message="{ 'Value is out of range': $v.input.maxActivation.$error }"
                            >
                            <b-period v-model="input.period" @change="(value) => { this.input.period = value }" :type="{'is-danger': $v.input.period.$error}" :message="{ 'Please select a Period': $v.input.period.$error}" />
                        </b-field>

                        <b-field 
                            horizontal 
                            label="Max Activations*"
                            :type="{ 'is-danger': $v.input.maxActivation.$error }"
                            :message="{ 'Value is out of range': $v.input.maxActivation.$error }"
                            >
                            <BMaxActivation :value="input.maxActivation" :maxActivate="maxActivationFromProduct" @onChange="(value) => { input.maxActivation = value }" />
                        </b-field>

                        <b-field horizontal label="Note">
                             <b-input type="textarea" name="note" v-model="input.note" ref="note"></b-input>
                        </b-field>
                    </div>

                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button class="button has-border-transparent ml-12" @click="$router.go(-1)"  data-cy="cancel">Cancel</button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Create</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Get from '@/libs/Get'
import Query from '@/libs/Query'
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators'
import BPeriod from '@/components/control/BPeriod'
import BMaxActivation from '@/components/control/BMaxActivation'

export default {
    name: 'MachineLicenseCreate',
    props: {
        'msg': String
    },
    components: { 
        BPeriod,
        BMaxActivation
    },
    data() {
        return {
            /* Select Option */
            licenseTypes: ['Trial','Subscription','Perpetual'],

            maxActivationFromProduct: 1,

            input: {
                product: '',
                productVersion: '',
                Type: null,
                period: 1,
                maxActivation: 1,
                note: '',
            },

            machineInfoGet: new Query(this.$http, 'MachinesGet', { Ids: this.$route.params.machineId }),
            assignedCustomerGet: new Get(this.$http, 'CustomerOfMachineGet', { Id: this.$route.params.machineId }),
            createLicenseCommand: new Command(this.$http, 'POST', 'LicensesAdd'),
            
            productKeyword: '',
            productQuery: new Query(this.$http, 'ProductsQuery', { Keyword: '', ActivationMethods: 'Machine' }, 1, 0, 'Name' ),

            productVersionKeyword: '',
            productVersionQuery: new Query(this.$http, 'ProductVersionsOfProductQuery', { ProductId: '', Keyword: '' }, 1, 0, 'Name'),

        }
    },
    validations() {
        return {
            input: {
                product:{
                    required
                },
                productVersion:{
                    required
                },
                period: {
                    required,
                    numeric,
                    minValue: minValue(1)
                },
                Type: {
                    required
                },
                maxActivation: {
                    numeric,
                    required,
                    minValue: minValue(1),
                    maxValue: maxValue(this.maxActivationFromProduct)
                }
            } 
        }
    },
    async created() {
        await this.machineInfoGet.execute()
        await this.assignedCustomerGet.execute()
    },
    computed: {
        isLoading() {
            return this.machineInfoGet.isLoading || this.createLicenseCommand.isLoading || this.assignedCustomerGet.isLoading
        },
        serialNo() {
            return this.machineInfoGet.firstRow != {} ? this.machineInfoGet.firstRow.SerialNumber : '-'
        },
        customerName() {
            return this.machineInfoGet.firstRow != {} ? this.machineInfoGet.firstRow.Customer : '-'
        },
        assignedCustomers() {
            return this.assignedCustomerGet.Data
        }
    },
    methods: {
        getProduct(value) {
            this.productQuery.execute({ Keyword: value })
        },
        selectProduct(value){
            this.input.product = value.Id
            this.ActivationKeyVisible = value.ActivationMethod == "Machine"
            this.productVersionQuery.execute({ ProductId: value.Id, Keyword: '' })
            this.maxActivationFromProduct = value.MaxActivations
        },
        getVersion(value) {
            this.productVersionQuery.execute({ ProductId: this.input.product, Keyword: value })
        },
        selectVersion(value){
            this.input.productVersion = value.Id
            if(!this.ActivationKeyVisible){
                this.schemaGet.execute({ ProductVersionId: value.Id }).then(() => {
                    this.input.features = this.schemaGet.Data.Features
                    this.input.features.forEach(element => {
                        element.Limits = 0
                    });
                })
            }
        },
        submitForm() {
            this.$v.$reset();
            this.$v.$touch();

            if (this.$v.$error) 
                return;            
            this.createLicense()
        },
        createLicense() {
            let items = []

            if(items.length == 0){
                items.push({
                    CustomerId: this.assignedCustomers.Id,
                    ProductVersionId: this.input.productVersion,
                    ActivationKey: this.serialNo,
                    Type: this.input.Type,
                    Periods: this.input.period,
                    MaxActivations: this.input.maxActivation,
                    Features: [],
                    Note: this.input.note,
                })
            }

            this.createLicenseCommand
            .execute({ 
                Licenses: items
            })
            .then(resp => {
                if(resp.status === 201){
                    this.$router.go(-1)
                }
            })
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
