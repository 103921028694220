import EmptyImg from '@/assets/empty.png'
// console.log(typeof `${EmptyImg}`)

export default {
    props: {
        border: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'No data',
        },
        subtitle: {
            type: String,
            default: 'Create new one, try different keywords or remove search filters',
        },
    },
    render() {
        return (
            <LayoutFlexColumn
                padding='10'
                gap='5'
                horizontalAlign='center'
                style={this.border && { border: '1px solid #ddd' }}>
                <img slot='remain' width='250px' src={EmptyImg} />
                <div slot='bottom' class='has-text-grey has-text-centered'>
                    <h1 class='title is-4'>{this.title}</h1>
                    <h2 class='subtitle is-6'>{this.subtitle}</h2>
                </div>
            </LayoutFlexColumn>
        )
    },
}
