<template>
  <BTabContent :loading="isLoading">
    <div class="detail-item-title">Reset Password</div>
    <div class="detail-item-content">
        <button class="button is-info" @click="resetConfirm">RESET</button>
    </div>
  </BTabContent>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'

export default {
  props: {
    msg: String
  },
  data() {    
    return {  
        userQuery: new Query(this.$http, 'UserGet', { Ids: this.$route.params.userId }),
        resetPasswordCommand: new Command(this.$http, 'PUT', 'UserResetPassword')
    }
  },
  created() {        
      this.userQuery.execute()
  },
  computed: {
      isLoading() {
          return this.userQuery.isLoading || this.resetPasswordCommand.isLoading
      }
  },
  methods: {
        resetConfirm() {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message:
                    "Are you sure you want to unassign from group: <b>'" + this.userQuery.firstRow.UserName + "'</b>?",
                confirmText: 'RESET',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.resetPasswordCommand.execute({ Ids: this.userQuery.firstRow.Id }).then(resp => {
                        if (resp.status === 200) {
                            this.$router.push({ name: 'userDetail', params: { userId: this.$route.params.userId, tabId: 0 } }) 
                        }
                    })
                }
            })
        }
    }
};
</script>