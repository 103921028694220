<template>
    <BDetailView
        :loading="isLoading"
        @backButtonClick="$router.push({ name: 'productList' })"
        prevPathName="Product"
        @prevPathClick="$router.push({ name: 'productList' })"
        :currentPathName="productQuery.firstRow.Name"
        typeName="Product"
        :viewName="productQuery.firstRow.Name"
        :tabItems="tabItems"
        :actionPermission="actionPermission"
        :actionItems="actionItems"
    />
</template>

<script>

import Command from '@/libs/Command'
import Query from '@/libs/Query'
import DetailTab from '@/views/Manage/Product/DetailTabs/DetailTab.vue'
import VersionTab from '@/views/Manage/Product/DetailTabs/VersionTab.vue'

export default {
    name: 'ManageProductDetail',
    props: {
        customerId: {
            type: [String]
        },
        tabId: {
            type: [String, Number],
            default: 0
        }
    },
    components: {
        DetailTab
    },
    data() {
        return {
            tabItems: [
                { component: DetailTab, label: 'Details' },
                { component: VersionTab, label: 'Version' }
            ],
            actionPermission: {
                resources: 'Product',
                any: true,
                actions: ['Edit', 'Delete']
            },
            actionItems: [
                {
                    label: 'Edit',
                    do: () =>
                        this.$router.push({
                            name: 'productEdit',
                            params: { productId: this.$route.params.productId }
                        }),
                    permission: { resources: 'Product', actions: ['Edit'] }
                },
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(),
                    permission: { resources: 'Product', actions: ['Delete'] }
                }
            ],
            productQuery: new Query(this.$http, 'ProductsGet', {
                Ids: this.$route.params.productId
            }),
            deleteProductCommand: new Command(this.$http, 'DELETE', 'ProductsRemove')
        }
    },
    created() {
        this.productQuery.execute()
    },
    computed: {
        isLoading() {
            return this.productQuery.isLoading || this.deleteProductCommand.isLoading
        }
    },
    methods: {
        confirmDelete() {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: "Are you sure you want to delete this Product: <b>'" + this.productQuery.firstRow.Name + "'</b>?",
                confirmText: 'Delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteCustomerCommand.execute({ Ids: this.$route.params.productId }).then(resp => {
                        if (resp.status === 204) {
                            this.$router.push({ name: 'productList' })
                        }
                    })
                }
            })
        }
    }
}
</script>