<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">Customers</p>
        </header>

        <div class="card-content bg">
            <div class="content">
                <b-table
                    :data="customersQuery.Rows"
                    backend-pagination
                    backend-sorting
                    :selected.sync="selected"
                    focusable
                    :current-page="$path.params.currentPage"
                    :per-page="$path.params.pageSize"
                    :default-sort-direction="$path.params.sortDirection"
                    :default-sort="$path.params.sortColumn"
                    @sort="
                        (field, order) => {
                            $path.params.sortColumn = field
                            $path.params.sortDirection = order
                            $path.push('customersList')
                        }
                    "
                    @click="rowSelected"
                >
                    <b-table-column field="Name" label="Name" style="width: 400px;" sortable v-slot="props">
                        <router-link :to="{ name: 'customerDetail', params: { customerId: props.row.Id, tabId: 0 } }">
                            <span class="item-link">{{ props.row.Name }}</span>
                        </router-link>
                    </b-table-column>

                    <b-table-column field="Country" label="Country" sortable v-slot="props">
                        {{ props.row.Country.Name }}
                    </b-table-column>

                    <b-table-column field="MachineCount" label="No. of Machine" sortable v-slot="props">
                        <b-tooltip :label="props.row.MachineStringify" position="is-right" type="is-light">
                            {{ props.row.MachineCount }}
                        </b-tooltip>
                    </b-table-column>
                    <template slot="empty">
                        <BEmptyState title="No any item" subtitle="" />
                    </template>
                </b-table>
                <div class="pagination-container">
                    <BPagination
                        :total="customersQuery.TotalRows"
                        :per-page="$path.params.pageSize"
                        :current="$path.params.currentPage"
                        @change="
                            (page) => {
                                $path.params.currentPage = page
                                $path.push('paging')
                            }
                        "
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { pathParamsMixin } from '@/mixins'
import Query from '@/libs/Query'

export default {
    name: 'CustomerList',
    props: {
        msg: String,
    },
    mixins: [pathParamsMixin],
    pathParams: {
        params: {
            cusID: '',
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'Name',
            sortDirection: 'asc',
            cache: true,
        },
    },
    data() {
        return {
            selected: {},
            customersQuery: new Query(this.$http, 'CustomersQuery', { keyword: '', cache: true }),
        }
    },
    created() {
        this.customersQuery.execute(this.$path.params).then(() => {
            this.$path.monitor(() => {
                this.customersQuery.execute(this.$path.params)
            })
        })
    },
    methods: {
        rowSelected(row) {
            //console.log(row.Id)
            this.$path.params.cusID = row.Id
            this.$path.push('customersList')
        },
    },
}
</script>

<style scoped>
    tr{
        line-height: 35px;
    }

    .card{
        min-height: 603px;
    }
</style>