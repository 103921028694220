<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="updateWarrantyCommand.isLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Edit Warranty</div>
                    </header>
                    <div class="card-content">
                        <div class="field is-horizontal">
                            <p class="field-label is-normal"></p>
                            <p class="field-body is-remark">*Update will be cancel this warranty and create new one</p>
                        </div>

                        <b-field
                            horizontal
                            label="Period"
                            :type="{ 'is-danger': $v.input.period.$error }"
                            :message="{ 'Please select Period': !$v.input.period.required }"
                        >
                            <b-period
                                v-model="input.period"
                                @change="
                                    (value) => {
                                        this.input.period = value
                                    }
                                "
                            />
                        </b-field>

                        <b-field
                            horizontal
                            label="Begin date"
                            :type="{ 'is-danger': $v.input.beginDate.$error }"
                            :message="{ 'begin date': !$v.input.beginDate.required }"
                        >
                            <b-datepicker
                                v-model="input.beginDate"
                                placeholder=""
                                icon="calendar-today"
                                style="display: block;width:130px;"
                                :date-formatter="dateFormatter"
                                :date-parser="dateParser"
                                :min-date="minDate"
                                :max-date="maxDate"
                                editable
                            >
                            </b-datepicker>
                        </b-field>

                        <b-field horizontal label="Note">
                            <b-input type="textarea" name="note" v-model="input.note" ref="note"></b-input>
                        </b-field>
                    </div>

                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Update</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import BPeriod from '@/components/control/BPeriod'

export default {
    name: 'ManageWarrantyEdit',
    props: {
        msg: String,
    },
    components: {
        BPeriod,
    },
    data() {
        return {
            periodOption: [
                { Id: 6, Name: '6 Months' },
                { Id: 12, Name: '12 Months' },
            ],
            input: {
                period: 1,
                beginDate: null,
                note: '',
            },
            minAvailableDate: null,
            minDate: null,
            maxDate: new Date('2025-01-01'),
            getWarrantyCommand: new Query(this.$http, 'WarrantiesGet', { Ids: this.$route.params.warrantyId }),
            updateWarrantyCommand: new Command(this.$http, 'PUT', 'WarrantiesUpdate'),
            getWarrantiesQuery: new Query(this.$http, 'WarrantiesOfMachineQuery', {
                MachineId: this.$route.params.machineId,
                Statuses: ['InWarranty'],
                sortColumn: 'EndDate',
                sortDirection: 'Descending',
            }),
        }
    },
    validations: {
        input: {
            period: {
                required,
            },
            beginDate: {
                required,
            },
        },
    },
    async created() {
        await this.getWarrantyCommand.execute().then(() => {
            let item = this.getWarrantyCommand.firstRow
            this.input.period = item.Period
            this.input.beginDate = new Date(item.BeginDate)
            this.input.note = item.Note
        })

        await this.getWarrantiesQuery.execute()
        if (this.getWarrantiesQuery.hasRow) {
            this.input.beginDate = this.minDate = moment(new Date(this.getWarrantiesQuery.firstRow.EndDate)).add(
                1,
                'days'
            )
            this.minAvailableDate = new Date(this.getWarrantiesQuery.firstRow.EndDate)
            this.minAvailableDate.setDate(this.minAvailableDate.getDate() + 1)
            this.input.beginDate = this.minDate = this.minAvailableDate
        } else {
            this.calculateMinDate(1)
        }
    },
    computed: {
        isLoading() {
            return (
                this.getWarrantyCommand.isLoading ||
                this.updateWarrantyCommand.isLoading ||
                this.getWarrantiesQuery.isLoading
            )
        },
    },
    methods: {
        dateFormatter(d) {
            return d.toLocaleDateString('en-GB')
        },
        dateParser(d) {
            let DD = d.substring(0, 1)
            let MM = d.substring(3, 4)
            let YYYY = d.substring(6, 9)
            let parseObj = new Date(Date.parse(YYYY + '-' + MM + '-' + DD))
            return parseObj
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.updateItem()
        },
        updateItem() {
            this.updateWarrantyCommand
                .execute({
                    Warranties: [
                        {
                            Id: this.$route.params.warrantyId,
                            Period: this.input.period,
                            BeginDate: this.input.beginDate,
                            Note: this.input.note,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$router.go(-1)
                    }
                })
        },
        calculateMinDate(monthAmt) {
            let monthDatefromToday = moment()
                .subtract(monthAmt, 'months')
                .toDate()
            if (this.minAvailableDate)
                this.minDate = monthDatefromToday < this.minAvailableDate ? monthDatefromToday : this.minAvailableDate
            else this.minDate = monthDatefromToday
        },
    },
    watch: {
        'input.period': {
            handler: function(val, oldVal) {
                this.calculateMinDate(val)
            },
            deep: true,
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.is-remark {
    color: red;
    font-size: 12px;
    font-style: italic;
}
</style>
