<template>
    <MainListLayout>

        <BListview 
            pageTitle="User"
            searchPlaceholder="Search Username, Full Name, ..."
            QueryAPIName="UserQuery"
            :QueryParams="params"
            DeleteAPIName="UserRemove"

            @onKeywordSearch="(value) => { params.keyword = value; }"
            @onPageSizeChange="(value) => { params.pageSize = value; }"
            @onPageChange="(value) => { params.currentPage = value; }"
            @onDeleted="(value) => { if(value) { selectedRows = []; } }"
            ref="listview">

            <template v-slot:actionButton>
                <BActionButton
                    v-if="selectedRows.length > 0"
                    :actionPermission="actionPermission"
                    :actionItems="actionItems"
                    :selectedRows="selectedRows"
                />
                <button v-else class="button is-primary ml-16" @click="$router.push({ name: 'userCreate' })">
                    Create
                </button>
            </template>

            <template v-slot:filter>
                <LayoutListInline
                    padding-x="0"
                    padding-y="0"
                    gap-x="4"
                    horizontal-align="left"
                    vertical-align="center"
                    :wrap="true"
                >
                    <BFilter
                        tooltip="Role"
                        class="level-item"
                        :loading="false"
                        v-model="filterUserRole"
                        :options="rolesQuery.Rows"
                        @change="
                            (Role) => {
                                params.RoleIds = Role.Id
                            }
                        "
                    ></BFilter>

                    <BFilter
                        tooltip="Status"
                        class="level-item"
                        :loading="false"
                        v-model="filterUserStatus"
                        :options="userStatuses"
                        @change="
                            (Status) => {
                                params.Enabled = Status.Value
                            }
                        "
                    ></BFilter>
                </LayoutListInline>
            </template>

            <template v-slot:default="{ result }">
                <b-table
                    :data="result"
                    backend-pagination
                    backend-sorting
                    checkable
                    :current-page="params.currentPage"
                    :per-page="params.pageSize"
                    :checked-rows.sync="selectedRows"
                    :default-sort-direction="params.sortDirection"
                    :default-sort="params.sortColumn"
                    @sort="
                        (field, order) => {
                            params.sortColumn = field
                            params.sortDirection = order
                        }
                    "
                >
                    <b-table-column field="username" label="Username" sortable v-slot="props">
                        <router-link :to="'/users/' + props.row.Id + '/tab/0'" exact>
                            <span class="item-link">{{ props.row.UserName }}</span>
                        </router-link>
                    </b-table-column>

                    <b-table-column field="fullname" label="Full Name" sortable v-slot="props">{{
                        props.row.FullName
                    }}</b-table-column>

                    <b-table-column field="role" label="Role" sortable v-slot="props">
                        <div v-for="(roleObj, index) in props.row.Roles" :key="index" style="display:inline-block;">
                            <span v-if="index > 0">,&nbsp;</span><span>{{ roleObj }}</span>
                        </div>
                    </b-table-column>

                    <b-table-column field="status" label="Status" sortable v-slot="props">
                        <div v-if="props.row.Enabled"><b-tag type="is-success">Enabled</b-tag></div>
                        <div v-else><b-tag type="is-danger">Disabled</b-tag></div>
                    </b-table-column>

                    <b-table-column field="control" centered v-slot="props">
                        <b-dropdown position="is-bottom-left">
                            <button class="button border-0 btn-control" slot="trigger">
                                <b-icon icon="dots-horizontal" size="is-small"></b-icon>
                            </button>
                            <b-dropdown-item has-link v-permit="{ resources: 'User', actions: ['Edit'] }">
                                <router-link :to="'/userEdit/' + props.row.Id" exact>Edit</router-link>
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="confirmDelete([props.row])"
                                v-permit="{ resources: 'User', actions: ['Delete'] }"
                                >Delete</b-dropdown-item
                            >
                        </b-dropdown>
                    </b-table-column>
                    <template slot="empty">
                        <BEmptyState title="No mached result" subtitle="Please try another criteria" />
                    </template>
                </b-table>
            </template>

            <template v-slot:loadingState="{ loadingRows }">
                <b-table :data="loadingRows">
                    <b-table-column label="Username">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Full Name">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Role">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Status">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column field="control">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>                   
                </b-table>
            </template>

        </BListview>

    </MainListLayout>
</template>

<script>
import Query from '@/libs/Query'
import { MainListLayout } from '@/layouts'
import BListview from '@/components/common/BListview'

export default {
    name: 'UserList',
    props: {
        msg: String,
    },
    components: {
        MainListLayout,
        BListview
    },

    url: {
        __root: 'params',

        keyword: { key: 'kw' },
        RoleIds: { key: 'role' },
        Enabled: { key: 'enable' },
        currentPage: { key: 'page' },
        pageSize: { key: 'size' },
        sortColumn: { key: 'col' },
        sortDirection: { key: 'dir' },
    },

    data() {
        const params = {
            keyword: '',
            RoleIds: '',
            Enabled: '',
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'Name',
            sortDirection: 'asc'
        }

        return {
            params,

            selectedRows: [],

            actionPermission: {
                resources: 'User',
                any: true,
                actions: ['Delete'],
            },
            actionItems: [
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(this.selectedRows),
                    permission: { resources: 'User', actions: ['Delete'] },
                },
            ],
            rolesQuery: new Query(this.$http, 'RolesQuery', { Keyword: '' }),

            /* Select Option */
            filterUserRole: null,
            userStatuses: [
                { Name: 'All Status', Value: '' },
                { Name: 'Enabled', Value: 'true' },
                { Name: 'Disabled', Value: 'false' },
            ],
            filterUserStatus: { Name: 'All Status', Value: '' },
        }
    },
    created() {
        this.rolesQuery.execute().then(() => {
            this.rolesQuery.Rows.unshift({ Id: '', Users: [], Name: 'All Roles', Description: '' })
            this.filterUserRole = this.rolesQuery.firstRow
        })
    },
    methods: {
        confirmDelete(selectedRow) {
            let formattedItemsName = selectedRow.map((p) => `<b>${p.UserName}</b>`).join('<br/>')
            let nPos = selectedRow.length > 1 ? selectedRow.length : ''
            let confirmMsg = `Are you sure you want to delete the following ${nPos} user?<br/><br/>`
            let ItemsId = selectedRow.map((p) => p.Id)

            this.$refs.listview.confirmDelete(ItemsId, confirmMsg + formattedItemsName)
        },
    },
    mounted() {
        this.$refs.listview.reload()
    }
}
</script>
