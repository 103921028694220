<template>
    <BDetailView
        :loading="auditLogQuery.isLoading"
        @backButtonClick="$router.go(-1)"
        prevPathName="Audit Log"
        @prevPathClick="$router.push({ name: 'auditlog' })"
        :currentPathName="auditLogQuery.firstRow.User + ' - ' + auditLogQuery.firstRow.Action + ' - ' + auditLogQuery.firstRow.Result + ' - ' + this.format_date(auditLogQuery.firstRow.Timestamp)"
        typeName="Audit Log"
        :viewName="auditLogQuery.firstRow.User + ' - ' + auditLogQuery.firstRow.Action + ' - ' + auditLogQuery.firstRow.Result + ' - ' + this.format_date(auditLogQuery.firstRow.Timestamp)"
        :tabItems="tabItems"
        
    />
</template>

<script>
import Query from '@/libs/Query'
import AuditLogDetailTab from '@/views/AuditLog/DetailTabs/DetailTab.vue'
import { format_date } from '@/libs/datetime'

export default {
    name: 'AuditLogDetail',
    props: {
        auditLogId: {
            type: [String]
        },
        tabId: {
            type: [String, Number],
            default: 0
        }
    },
    components: {
        AuditLogDetailTab
    },
    data() {
        return {
            tabItems: [
                { component: AuditLogDetailTab, label: 'Details' },       
            ],
            auditLogQuery: new Query(this.$http, 'AuditLogsGet', {
                Ids: this.$route.params.auditLogId
            }),

            format_date
        }
    },
    created() {
        this.auditLogQuery.execute()
    }
}
</script>