<template>
    <div>
        <b-numberinput
            type="is-outlined"
            icon-pack="fa"
            controls-position="compact"
            name="maxActivation"
            v-model="maxActivationValue"
            :min="1"
            :max="maxActivate"
        ></b-numberinput>
        <span>(Max: {{ maxActivate }})</span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number],
            default: 1,
        },
        maxActivate: {
            type: [Number],
            default: 1,
        },
    },
    data() {
        return {
            maxActivationValue: 1,
        }
    },
    methods: {},
    computed: {},
    watch: {
        value() {
            this.maxActivationValue = this.value
        },
        maxActivationValue() {
            this.$emit('onChange', this.maxActivationValue)
        },
    },
    mounted() {},
}
</script>

<style scoped>
div {
    display: flex;
}
span {
    color: gray;
    font-style: italic;
    line-height: 34px;
    margin-left: 10px;
}

>>> div.control {
    width: 80px;
}
</style>
