<template>
    <div class="i-relative">
        <!-- Icon -->
        <div class="i-sync-search i-spin" v-if="isFetching && isFocus">
            <b-icon icon="sync"></b-icon>
        </div>
        <div class="i-sync-search" v-else>
            <b-icon icon="magnify"></b-icon>
        </div>

        <!-- Close Btn -->
        <div
            v-if="isExistKeyword"
            class="i-remove-search"
            @click="onClear">
            <b-icon icon="close"></b-icon>
        </div>
        <div class="i-sync-cover" v-if="isFetching"></div>

        <b-input
            v-model="searchValue"
            class="text-box"
            type="search"
            icon="magnify"
            @input="onSubmit"
            :placeholder="placeholder"
            @focus="onFocus"
            @blur="onBlur"
            >
        </b-input>
    </div>
</template>

<script>
export default {
  name: "BRapidSearchBox",
  props: {
    placeholder: {
      type: [String],
      default: 'Search...'
    },
    initValue: {
        type: [String],
        default: ''
    },
    isFetching: {
        type: [Boolean],
        default: false
    }
  },
  watch: {
    // searchValue () {
    //   // console.log('ddValue: ', this.ddValue)
    //   this.$emit('input', this.searchValue)
    //   this.$emit('change', this.searchValue)
    // }
  },
  data() {
    return {
        isExistKeyword: false,
        searchValue: '',
        submittedValue: '',
        isFocus: false
    }
  },
  mounted() {
    // console.log('value: ', this.value)
    this.searchValue = this.initValue
  },
  methods: {
    onFocus() {
      this.isFocus = true
      console.log('Focus')
    },
    onBlur() {
      this.isFocus = false
      console.log('Blur')
    },
    onClear() {
      this.searchValue = ''
      this.onSubmit()
    },
    onSubmit() {
      this.isExistKeyword = (this.searchValue.length > 0)
      this.submittedValue = this.searchValue
      this.$emit('textChange', this.submittedValue)
    }
  }
}
</script>
