<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view">
                <b-loading :is-full-page="false" :active.sync="createRoleCommand.isLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Create Role</div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Name*"
                            :type="{ 'is-danger': $v.input.name.$error }"
                            :message="{ 'a Role Name': $v.input.name.$error }"
                        >
                            <b-input
                                name="name"
                                v-model="input.name"
                                ref="roleName"
                                placeholder="a Role Name"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field horizontal label="Description">
                            <b-input
                                name="description"
                                v-model="input.description"
                                ref="roleDescription"
                                placeholder="a Role Description"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field horizontal label="Assign User">
                            <div class="assigned-component">
                                <div class="i-relative">
                                    <b-field>
                                        <b-autocomplete
                                            v-model="unassignUserName"
                                            :data="unassignUserQuery.Rows"
                                            :loading="unassignUserQuery.isLoading"
                                            :keep-first="true"
                                            :clear-on-select="true"
                                            :open-on-focus="true"
                                            placeholder="e.g. Username"
                                            icon="magnify"
                                            field="UserName"
                                            @focus="getUnassignedUser(unassignUserName)"
                                            @typing="(value) => getUnassignedUser(value)"
                                            @input="null"
                                            @select="(value) => assignUser(value)"
                                        >
                                        </b-autocomplete>
                                    </b-field>
                                </div>
                                <ul class="list mt-32 list-assigned">
                                    <li class="list-item" v-for="(item, index) in itemUsers" :key="index">
                                        <div class="item-assigned">
                                            <span>{{ item.UserName }}</span>
                                            <span class="item-assigned-remove" @click="unassignUser(item.Id)"
                                                ><b-icon icon="close"></b-icon
                                            ></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Create</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CountryList from 'country-list'
import Query from '@/libs/Query'
import Command from '@/libs/Command'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'RolesCreate',
    props: {
        msg: String,
    },
    data() {
        return {
            input: {
                name: '',
                description: '',
                userIds: [],
            },
            createRoleCommand: new Command(this.$http, 'POST', 'RolesAdd'),
            unassignUserName: '',
            unassignUserQuery: new Query(this.$http, 'UserQuery', { Keyword: '' }, 1, 0, 'UserName'),
            itemUsers: [],
        }
    },
    validations: {
        input: {
            name: {
                required,
            },
        },
    },
    methods: {
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.createRole()
        },
        createRole() {
            this.createRoleCommand
                .execute({
                    Roles: [
                        {
                            Name: this.input.name,
                            Description: this.input.description,
                            UserIds: this.input.userIds,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 201) {
                        this.$router.push({ name: 'roleList' })
                    }
                })
        },
        getUnassignedUser(value) {
            this.unassignUserQuery.execute({
                Keyword: value,
            })
        },
        assignUser(option) {
            let object = null

            if (option) {
                object = this.itemUsers.filter((obj) => obj.Id === option.Id)

                if (object.length === 0) {
                    this.itemUsers.push(option)
                    this.input.userIds.push(option.Id)
                }
            }
        },
        unassignUser(removeId) {
            this.itemUsers = this.itemUsers.filter((obj) => obj.Id !== removeId)
            this.input.userIds = this.input.userIds.filter((item) => item !== removeId)
        },
    },
}
</script>
