{
    /* <template>
    <div id="the-header" class="the-header level is-mobile has-text-light">
        <div class="level-left pl-32">
            <p class="level-item">{{ appVersion.name }}</p>
        </div>
        <div class="level-right pr-32">
            <p class="level-item">
                <span v-if="hasNotification" class="badge is-badge-danger is-badge-small" :data-badge="msgCount">
                    <b-icon icon="bell"></b-icon>
                </span>
                <span v-else>
                    <b-icon icon="bell-outline"></b-icon>
                </span>
            </p>
            <p class="level-item">
                <b-dropdown position="is-bottom-left">
                    <button
                        class="button is-small has-text-light has-background-transparent has-border-transparent"
                        slot="trigger"
                    >
                        <span>{{ account }}</span>
                        <b-icon icon="menu-down"></b-icon>
                    </button>
                    <b-dropdown-item has-link>
                        <router-link to="/myAccount/tab/0" exact>My Account</router-link>
                    </b-dropdown-item>
                    <hr class="dropdown-divider" />
                    <b-dropdown-item @click="logout">Log out</b-dropdown-item>
                </b-dropdown>
            </p>
        </div>
    </div>
</template> */
}

import appVersion from '@/version.json'
import Vue from 'vue'
import styled from 'vue-styled-components'

export default {
    data() {
        const currentUsername = localStorage.getItem('userName')
        return {
            appVersion: appVersion,
            msgCount: 0,
            account: currentUsername,
        }
    },
    computed: {
        hasNotification() {
            return this.msgCount && this.msgCount > 0
        },
    },
    methods: {
        logout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push({ name: 'login' })
            })
        },
    },
    render() {
        const Wrapper = styled.div`
            /* height: 41px; */
            background-color: var(--app-primary-color);
        `

        const Notification = () =>
            this.hasNotification ? (
                <div class='is-relative'>
                    <span class='badge is-danger'>{this.msgCount}</span>
                    <BIcon pack='fa' icon='bell' size='is-medium' />
                </div>
            ) : (
                <BIcon pack='far' icon='bell' size='is-medium' />
            )

        const CurrentAccount = () => (
            <b-dropdown position='is-bottom-left'>
                <a class='has-text-light' slot='trigger'>
                    <span>{this.account}</span>
                    <BIcon pack='fa' icon='caret-down'></BIcon>
                </a>
                <b-dropdown-item has-link>
                    <router-link to='/myAccount/tab/0' exact>
                        My Account
                    </router-link>
                </b-dropdown-item>
                <hr class='dropdown-divider' />
                <b-dropdown-item v-on:click={this.logout}>Log out</b-dropdown-item>
            </b-dropdown>
        )

        return (
            <Wrapper class='has-text-light'>
                <LayoutFlexRow verticalAlign='center' paddingX='8' paddingY='3'>
                    <template slot='left'>
                        <p class='subtitle has-text-white'>{this.appVersion.name}</p>
                    </template>
                    <LayoutListInline slot='right' wrap={false} verticalAlign='center' gapX='4'>
                        <Notification />
                        <CurrentAccount />
                    </LayoutListInline>
                </LayoutFlexRow>
            </Wrapper>
        )
    },
}
