import VueLayoutSystem from 'vue-layout-system'
import 'vue-layout-system/dist/vue-layout-system.css'

export default {
    install(Vue) {
        if (!Vue) return

        Object.keys(VueLayoutSystem).forEach((name) => {
            Vue.component(name, VueLayoutSystem[name])
        })
    },
}
