<template>
    <BTabContent :loading="changePasswordCommand.isLoading">
        <div class="detail-item-title">Change Password</div>
        <div class="detail-item-content">
            <b-field
                horizontal
                label="Current Password*"
                :type="{ 'is-danger': $v.input.curPassword.$error }"
                :message="{ 'your Current Password': $v.input.curPassword.$error }"
            >
                <b-input
                    name="password"
                    v-model="input.curPassword"
                    type="password"
                    autocomplete="off"
                    password-reveal
                ></b-input>
            </b-field>

            <b-field
                horizontal
                label="New Password*"
                :type="{ 'is-danger': $v.input.password.$error }"
                :message="{ 'New Password': $v.input.password.$error }"
            >
                <b-input
                    name="password"
                    v-model="input.password"
                    type="password"
                    autocomplete="off"
                    password-reveal
                ></b-input>
            </b-field>

            <b-field
                horizontal
                label="Confirm Password*"
                :type="{ 'is-danger': $v.input.confirmPassword.$error }"
                :message="{ 'Please confirm a password': $v.input.confirmPassword.$error }"
            >
                <b-input
                    name="confirmPassword"
                    v-model="input.confirmPassword"
                    type="password"
                    autocomplete="off"
                    password-reveal
                ></b-input>
            </b-field>

            <b-field horizontal>
                <div class="has-text-right">
                    <button class="button is-info" @click="submitForm">Change Password</button>
                </div>
            </b-field>
        </div>
    </BTabContent>
</template>

<script>
import Command from '@/libs/Command'
import { required } from 'vuelidate/lib/validators'
import { Script } from 'vm'

export default {
    name: 'ManageCustomerGroupEdit',
    props: {
        msg: String,
    },
    data() {
        return {
            input: {
                curPassword: '',
                password: '',
                confirmPassword: '',
            },
            changePasswordCommand: new Command(this.$http, 'PUT', 'MyAccountChangePassword'),
        }
    },
    validations: {
        input: {
            curPassword: {
                required,
            },
            password: {
                required,
            },
            confirmPassword: {
                required,
            },
        },
    },
    created() {},
    methods: {
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.changePasswordConfirm()
        },
        changePasswordConfirm() {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: 'Are you sure you want to change your password?',
                confirmText: 'OK',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.changePasswordCommand
                        .execute({ CurrentPassword: this.input.curPassword, NewPassword: this.input.password })
                        .then((resp) => {
                            if (resp.status === 200) {
                                //this.$router.go(-1)
                                //this.$router.push({name:'MyAccount', params:{ tabId: 2}})
                                this.input.curPassword = ''
                                this.input.password = ''
                                this.input.confirmPassword = ''
                                this.$v.$reset()
                            }
                        })
                },
            })
        },
    },
}
</script>
