<template>
  <BTabContent :loading="machineQuery.isLoading">
    <div v-if="details">
      <div class="detail-item-title">Model</div>
      <div class="detail-item-content">{{ modelName }}&nbsp;</div>
      <div class="detail-item-title">Serial Number</div>
      <div class="detail-item-content">{{ details.SerialNumber }}&nbsp;</div>                            
      <div class="detail-item-title">Manufactured Date</div>
      <div class="detail-item-content">{{ format_date(details.ManufacturedDate) }}&nbsp;</div>
    </div>
    <div v-else>
        <!-- <p>Details not found</p> -->
    </div>
  </BTabContent>
</template>

<script>
import Query from '@/libs/Query'
import { format_date } from '@/libs/datetime'

export default {
  props: {
    msg: String
  },
  data() {    
    return {
      modelName:'',
      machineQuery: new Query(this.$http, 'MachinesGet', { Ids: this.$route.params.machineId }),

      format_date
    }
  },
  created() {        
      this.machineQuery.execute().then(()=>{
        this.modelName = this.machineQuery.firstRow.Model.Name
      })
  },
  computed: {
      details() {
          return this.machineQuery.firstRow
      }
  }
};
</script>