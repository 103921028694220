<template>
    <div
        class="row"
        :style="{
            'alignItems': flexAlignItems[align],
            'justifyContent': flexJustifyContent,
            'paddingTop': gutter.top,
            'paddingBottom': gutter.bottom
        }"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: {
        gutter: {
            type: Object,
            default() {
                return {
                    top: '0',
                    bottom: '0'
                }
            }
        },
        align: {
            type: String,
            default: 'middle',
            validator: function(value) {
                return ['top', 'middle', 'bottom'].indexOf(value) >= 0
            }
        },
        justify: {
            type: String,
            default: 'start',
            validator: function(value) {
                return ['start', 'end', 'center', 'space-around', 'space-between'].indexOf(value) >= 0
            }
        }
    },
    data() {
        return {
            flexAlignItems: {
                top: 'flex-start',
                middle: 'center',
                bottom: 'flex-end'
            }
        }
    },
    computed: {
        flexJustifyContent() {
            switch (this.justify) {
                case 'start' : return 'flex-start'
                case 'end' : return 'flex-end'
                default: return this.justify
            }
        }
    }
}
</script>

<style lang="less">
.row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
</style>