<template>
    <BDetailView
        :loading="isLoading"
        @backButtonClick="$router.go(-1)"
        prevPathName="My Account"
        @prevPathClick="$router.push({ name: 'MyAccount', params: {tab:0} })"
        :currentPathName="myAccountGet.Data.UserName"
        typeName="My Account"
        :viewName="myAccountGet.Data.UserName"
        :tabItems="tabItems"
        :actionPermission="actionPermission"
        :actionItems="actionItems"
    />
</template>

<script>
import Command from '@/libs/Command'
import Get from '@/libs/Get'
import DetailTab from '@/views/Account/MyAccount/DetailTabs/DetailTab.vue'
import RoleTab from '@/views/Account/MyAccount/DetailTabs/RoleTab.vue'
import PasswordTab from '@/views/Account/MyAccount/DetailTabs/PasswordTab.vue'

export default {
    name: 'MyAccount',
    props: {
        tabId: {
            type: [String, Number],
            default: 0
        }
    },
    components: {
        DetailTab
    },
    data() {
        return {
            tabItems: [
                { component: DetailTab, label: 'Details' },
                { component: RoleTab, label: 'Role' },
                { component: PasswordTab, label: 'Password' }        
            ],
            actionPermission: {
                resources: 'MyAccount',
                any: true,
                actions: ['Edit']
            },
            actionItems: [
                {
                    label: 'Edit',
                    do: () =>
                        this.$router.push({
                            name: 'MyAccountEdit',
                            params: { sitePath: 1, posId: this.posId }
                        }),
                    permission: { resources: 'MyAccount', actions: ['Edit'] }
                }
            ],
            myAccountGet: new Get(this.$http, 'MyAccountGet', {})
        }
    },
    created() {
        this.myAccountGet.Data = ''
        this.myAccountGet.execute()
    },
    computed: {
        isLoading() {
            return this.myAccountGet.isLoading
        }
    }
}
</script>