<template>
  <BTabContent :loading="userQuery.isLoading">
    <div v-if="details">
        <div class="columns">
            <div class="column">
                <div class="detail-item-title">Enabled</div>
                <div class="detail-item-content">{{ details.Enabled ? 'Yes' : 'No' }}</div>
                <div class="detail-item-title">Username</div>
                <div class="detail-item-content">{{ details.UserName }}&nbsp;</div>
                <div class="detail-item-title">Full Name</div>
                <div class="detail-item-content">{{ details.FullName }}&nbsp;</div>
                <div class="detail-item-title">Gender</div>
                <div class="detail-item-content">{{ details.Gender }}&nbsp;</div>
                <div class="detail-item-title">Date of Birth</div>
                <div class="detail-item-content">{{ format_date(details.DateOfBirth) }}&nbsp;</div>
            <!-- </div>
            <div class="column"> -->
                <div class="detail-item-title">Address</div>
                <div class="detail-item-content">{{ details.Address }}&nbsp;</div>
                <div class="detail-item-title">Country</div>
                <div class="detail-item-content">{{ contryName }}&nbsp;</div>
                <div class="detail-item-title">Postal Code</div>
                <div class="detail-item-content">{{ details.PostalCode }}&nbsp;</div>
                <div class="detail-item-title">Phone Number</div>
                <div class="detail-item-content">{{ details.PhoneNumber }}&nbsp;</div>
                <div class="detail-item-title">Email</div>
                <div class="detail-item-content">{{ details.Email }}&nbsp;</div>
            </div>
        </div>
    </div>
    <div v-else>
        <!-- <p>Details not found</p> -->
    </div>
  </BTabContent>
</template>

<script>
import Query from '@/libs/Query'
import { format_date } from '@/libs/datetime'

export default {
  props: {
    msg: String
  },
  data() {    
    return {
        userQuery: new Query(this.$http, 'UserGet', {
            Ids: this.$route.params.userId
        }),

        format_date
    }
  },
  created() {        
      this.userQuery.execute()
  },
  computed: {
      details() {
          return this.userQuery.firstRow
      },
      contryName() {
          return this.userQuery.firstRow.Country ? this.userQuery.firstRow.Country.Name : ''
      }
  }
};
</script>