

export default class Command {

    // States
    //
    get isLoading() {
        return this.__isLoading
    }

    constructor(http, verb, opName) {

        this.__isLoading = false

        this.__http = http
        this.__verb = verb
        this.__opName = opName
    }

    execute(params = {}) {

        // console.log('params: ', params)

        this.__isLoading = true

        let pm = this.__http({
            method: this.__verb,
            url: '/json/reply/' + this.__opName,
            data: params
        })
        // .then(resp => {
        //     console.log('resp: ', resp)
        // })
        .finally(() => {
            this.__isLoading = false
        })

        return pm
    }

}
