<template>
    <BTabContent>
        <div class="detail-item-title">Audit Log</div>
        <div class="detail-item-content">
            <div class="level m-control-8">
                <div class="level-left">
                    <BRapidSearchBox
                        ref="searchBox"
                        :initValue="$path.params.UserKeyword"
                        :isFetching="AuditLogQuery.isLoading"
                        placeholder="Username"
                        @textChange="
                            (value) => {
                                $path.params.UserKeyword = value
                                $path.push('UserKeyword')
                            }
                        "
                    />
                </div>
                <div class="level-right">
                    &nbsp;&nbsp;&nbsp;
                    <BPageSize
                        class="level-item"
                        v-model="$path.params.pageSize"
                        :options="[10, 20, 50, 100]"
                        @change="$path.push('pageSize')"
                    />
                </div>
            </div>

            <div class="level m-control-32">
                <div class="level-left">
                    <BTooltip label="Period" position="is-top" type="is-dark" :animated="true">
                        <b-dropdown ref="dropdownCustomOption">
                            <button class="button is-info" slot="trigger">
                                <span>{{ labelCustomOption }}</span>
                                <b-icon icon="chevron-down"></b-icon>
                            </button>
                            <template v-if="flagCustomOption">
                                <b-dropdown-item @click="viewLast7Days">Last 7 days</b-dropdown-item>
                                <b-dropdown-item @click="viewLast28Days">Last 28 days</b-dropdown-item>
                                <b-dropdown-item @click="viewLast90Days">Last 90 days</b-dropdown-item>
                                <b-dropdown-item @click="viewLast365Days">Last 365 days</b-dropdown-item>
                                <hr class="dropdown-divider" />
                                <b-dropdown-item @click="viewCurrentYear">{{ labelCurrentYear }}</b-dropdown-item
                                ><!-- current year -->
                                <b-dropdown-item @click="viewLastYear">{{ labelLastYear }}</b-dropdown-item
                                ><!-- last year -->
                                <hr class="dropdown-divider" />
                                <b-dropdown-item @click="viewCurrentMonth">{{ labelCurrentMonth }}</b-dropdown-item
                                ><!-- current month -->
                                <b-dropdown-item @click="viewLastMonth">{{ labelLastMonth }}</b-dropdown-item
                                ><!-- last month -->
                                <b-dropdown-item @click="viewLast2Month">{{ labelLast2Month }}</b-dropdown-item
                                ><!-- last 2 month -->
                                <hr class="dropdown-divider" />
                                <b-dropdown-item custom>
                                    <div class="dropdown-item-custom" @click="switchCustomOption">
                                        <b-icon icon="calendar"></b-icon>&nbsp;&nbsp;Custom Range
                                    </div>
                                </b-dropdown-item>
                            </template>
                            <template v-else>
                                <b-dropdown-item custom paddingless>
                                    <v-date-picker
                                        mode="range"
                                        v-model="selectedDate"
                                        class="custom-calendar"
                                        @input="setCustomDateRange"
                                        is-inline
                                        is-expanded
                                    >
                                    </v-date-picker>
                                </b-dropdown-item>
                                <hr class="dropdown-divider" />
                                <b-dropdown-item custom>
                                    <div class="dropdown-item-custom" @click="switchCustomOption">
                                        <b-icon icon="format-list-bulleted-type"></b-icon>&nbsp;&nbsp;Option Range
                                    </div>
                                </b-dropdown-item>
                            </template>
                        </b-dropdown>
                    </BTooltip>
                    &nbsp;&nbsp;&nbsp;
                    <BFilter
                        tooltip="Action"
                        :loading="false"
                        v-model="filterAuditLogAction"
                        :options="auditLogActions"
                        @change="
                            (Item) => {
                                $path.params.Actions = Item.Id
                                $path.push('Action')
                            }
                        "
                    ></BFilter>
                    &nbsp;&nbsp;&nbsp;
                    <BFilter
                        tooltip="Resouerce"
                        :loading="false"
                        v-model="filterAuditLogResource"
                        :options="auditLogResources"
                        @change="
                            (Item) => {
                                $path.params.Resources = Item.Id
                                $path.push('Resource')
                            }
                        "
                    ></BFilter>
                    &nbsp;&nbsp;&nbsp;
                    <BFilter
                        tooltip="Result"
                        :loading="false"
                        v-model="filterAuditLogResult"
                        :options="auditLogResults"
                        @change="
                            (Item) => {
                                $path.params.Results = Item.Id
                                $path.push('Result')
                            }
                        "
                    ></BFilter>
                </div>
                <div class="level-right">
                    <BPagingCounter
                        class="level-item"
                        :loading="AuditLogQuery.isLoading"
                        :beginRow="AuditLogQuery.beginRow"
                        :endRow="AuditLogQuery.endRow"
                        :totalRows="AuditLogQuery.TotalRows"
                    />
                </div>
            </div>

            <template v-if="modeDisplay === 'List'">
                <div v-if="!AuditLogQuery.isLoading" class="card card-container">
                    <b-table
                        :data="AuditLogQuery.Rows"
                        :opened-detailed="defaultOpenedDetails"
                        detailed
                        detail-key="Id"
                        backend-pagination
                        backend-sorting
                        :current-page="$path.params.currentPage"
                        :per-page="$path.params.pageSize"
                        :default-sort-direction="$path.params.sortDirection"
                        :default-sort="$path.params.sortColumn"
                        @sort="
                            (field, order) => {
                                $path.params.sortColumn = field
                                $path.params.sortDirection = order
                                $path.push('customers')
                            }
                        "
                    >
                        <template slot-scope="props">
                            <b-table-column field="Timestamp" label="Timestamp" sortable>
                                <template v-if="showDetailIcon">
                                    <router-link :to="'/auditLog/' + props.row.Id + '/tab/0'" exact>
                                        <span class="item-link">{{ props.row.Timestamp }}</span>
                                    </router-link>
                                </template>
                                <template v-else>
                                    <a @click="toggle(props.row)">
                                        <router-link :to="'/auditLog/' + props.row.Id + '/tab/0'" exact>
                                            <span class="item-link">{{ props.row.Timestamp }}</span>
                                        </router-link>
                                    </a>
                                </template>
                            </b-table-column>

                            <b-table-column field="User" label="User" sortable>
                                {{ props.row.User }}
                            </b-table-column>

                            <b-table-column field="Action" label="Action" sortable>
                                {{ props.row.Action }}
                            </b-table-column>

                            <b-table-column field="Resource" label="Resource" sortable>
                                {{ props.row.Resource }}
                            </b-table-column>

                            <b-table-column field="RefKey" label="Ref. Key" sortable>
                                {{ props.row.RefKey }}
                            </b-table-column>

                            <b-table-column field="Result" label="Result" sortable>
                                <span class="tag is-success" v-if="props.row.Result === 'OK'">
                                    {{ props.row.Result }}
                                </span>
                                <span class="tag is-danger" v-else-if="props.row.Result === 'Error'">
                                    {{ props.row.Result }}
                                </span>
                                <span class="tag is-grey" v-else>
                                    {{ props.row.Result }}
                                </span>
                            </b-table-column>
                        </template>
                        <template slot="detail" slot-scope="props">
                            <div class="detail-item-title" style="font-size: 0.875rem;">Data</div>
                            <div v-if="isJson(props.row.Data)" class="detail-item-content i-relative data-code">
                                <div class="i-absolute">
                                    <pre>{{ dataStringify(props.row.Data) }}</pre>
                                </div>
                            </div>
                            <div v-else class="detail-item-content i-relative data-code">
                                <div class="i-absolute">
                                    <pre>{{ props.row.Data }}</pre>
                                </div>
                            </div>
                        </template>
                        <template slot="empty">
                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                                    </p>
                                    <p>Nothing here.</p>
                                </div>
                            </section>
                        </template>
                    </b-table>
                </div>
            </template>
            <template v-else>
                <div class="columns is-variable is-1 is-multiline">
                    <div v-for="item in items" :key="item.id" class="column is-3">
                        <div class="card ">
                            <div class="card-content">
                                <div class="level">
                                    <div class="level-left">
                                        {{ item.Timestamp }}<br />
                                        {{ item.User }}<br />
                                        {{ item.Action }}<br />
                                        {{ item.Resource }}<br />
                                        {{ item.RefKey }}<br />
                                        {{ item.Result }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div v-if="!AuditLogQuery.isLoading" class="pagination-container">
                <BPagination
                    :total="AuditLogQuery.TotalRows"
                    :per-page="$path.params.pageSize"
                    :current="$path.params.currentPage"
                    @change="
                        (page) => {
                            $path.params.currentPage = page
                            $path.push('paging')
                        }
                    "
                />
            </div>
        </div>
    </BTabContent>
</template>

<script>
import { pathParamsMixin } from '@/mixins'
import Query from '@/libs/Query'
//url: urlMachines + '/' + this.machineEditId + '/auditlogs'

export default {
    props: {
        msg: String,
    },
    mixins: [pathParamsMixin],
    pathParams: {
        params: {
            UserKeyword: '',
            RefKeyKeyword: '',
            DateType: '',
            Begin: '2020-01-01T00:00:00',
            End: '2020-12-31T23:59:59',
            Actions: '',
            Resources: '',
            Results: '',
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'Timestamp',
            sortDirection: 'desc',
        },
    },
    data() {
        return {
            auditLogActions: [],
            filterAuditLogAction: null,
            auditLogResources: [],
            filterAuditLogResource: null,
            auditLogResults: [],
            filterAuditLogResult: null,

            modeDisplay: 'List',
            AuditLogQuery: new Query(this.$http, 'AuditLogsQuery', {
                Begin: '',
                End: '',
                UserKeyword: '',
                RefKeyKeyword: '',
                Actions: '',
                Resources: '',
                Results: '',
            }),

            /* Detail Table */
            defaultOpenedDetails: [1],
            showDetailIcon: true,

            /* Dropdown Calendar */
            labelCustomOption: '',
            flagCustomOption: true,
            /* Calendar */
            attrs: [
                {
                    key: 'today',
                    dot: true,
                    dates: new Date(),
                },
            ],
            nowDate: new Date(),
            selectedDate: {
                start: null,
                end: null,
            },
            monthName: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            beginDate: null,
            endDate: null,
            machineEditId: this.$route.params.machineId,
        }
    },
    created() {
        this.labelCustomOption = new Date().getFullYear()
        Promise.all([this.optionAuditLogAction(), this.optionAuditLogResource(), this.optionAuditLogResult()]).then(
            () => {
                this.AuditLogQuery.execute(this.$path.params).then(() => {
                    this.$path.monitor(() => {
                        this.AuditLogQuery.execute(this.$path.params)
                    })
                })
            }
        )

        this.$refs.listview.reload()
        this.$refs.listview.startWatch()
    },
    computed: {
        labelCurrentYear() {
            return this.nowDate.getFullYear()
        },
        labelLastYear() {
            return this.nowDate.getFullYear() - 1
        },
        labelCurrentMonth() {
            return this.monthName[this.nowDate.getMonth()]
        },
        labelLastMonth() {
            let monthValue = this.nowDate.getMonth() - 1
            if (monthValue === -1) {
                monthValue = 11
            }
            return this.monthName[monthValue]
        },
        labelLast2Month() {
            let monthValue = this.nowDate.getMonth() - 2
            if (monthValue === -1) {
                monthValue = 11
            }
            if (monthValue === -2) {
                monthValue = 10
            }
            return this.monthName[monthValue]
        },
    },

    methods: {
        optionAuditLogAction() {
            this.$http({
                url: '/auditlogs/actions/metadata',
            }).then((resp) => {
                let defaultItem = { Id: '', Name: 'All Actions' }
                let dataArray = []
                resp.data.forEach((element) => {
                    dataArray.push({ Id: element, Name: element })
                })
                this.auditLogActions = dataArray
                this.auditLogActions.unshift(defaultItem)
                this.filterAuditLogAction = defaultItem
            })
        },
        optionAuditLogResource() {
            this.$http({
                url: '/auditlogs/resources/metadata',
            }).then((resp) => {
                let defaultItem = { Id: '', Name: 'All Resources' }
                let dataArray = []
                resp.data.forEach((element) => {
                    dataArray.push({ Id: element, Name: element })
                })
                this.auditLogResources = dataArray
                this.auditLogResources.unshift(defaultItem)
                this.filterAuditLogResource = defaultItem
            })
        },
        optionAuditLogResult() {
            this.$http({
                url: '/auditlogs/results/metadata',
            }).then((resp) => {
                let defaultItem = { Id: '', Name: 'All Results' }
                let dataArray = []
                resp.data.forEach((element) => {
                    dataArray.push({ Id: element, Name: element })
                })
                this.auditLogResults = dataArray
                this.auditLogResults.unshift(defaultItem)
                this.filterAuditLogResult = defaultItem
            })
        },
        get2Digit(input) {
            if (input < 10) {
                return '0' + input
            }
            return '' + input
        },
        switchCustomOption() {
            this.flagCustomOption = !this.flagCustomOption
        },
        viewLast7Days() {
            let dtInput = new Date()
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = this.get2Digit(dtInput.getDate())
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            dtInput.setDate(dtInput.getDate() - 7)
            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = this.get2Digit(dtInput.getDate())
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            this.$path.params.Begin = startDate + 'T00:00:00'
            this.$path.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = 'Last 7 Days'
            this.$path.push('changeDate')
        },
        viewLast28Days() {
            let dtInput = new Date()
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = this.get2Digit(dtInput.getDate())
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            dtInput.setDate(dtInput.getDate() - 28)
            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = this.get2Digit(dtInput.getDate())
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            this.$path.params.Begin = startDate + 'T00:00:00'
            this.$path.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = 'Last 28 Days'
            this.$path.push('changeDate')
        },
        viewLast90Days() {
            let dtInput = new Date()
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = this.get2Digit(dtInput.getDate())
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            dtInput.setDate(dtInput.getDate() - 90)
            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = this.get2Digit(dtInput.getDate())
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            this.$path.params.Begin = startDate + 'T00:00:00'
            this.$path.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = 'Last 90 Days'
            this.$path.push('changeDate')
        },
        viewLast365Days() {
            let dtInput = new Date()
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = this.get2Digit(dtInput.getDate())
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            dtInput.setDate(dtInput.getDate() - 365)
            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = this.get2Digit(dtInput.getDate())
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            this.$path.params.Begin = startDate + 'T00:00:00'
            this.$path.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = 'Last 365 Days'
            this.$path.push('changeDate')
        },
        viewCurrentYear() {
            let dtInput = new Date()

            let startYear = dtInput.getFullYear()
            let startMonth = '01'
            let startDay = '01'
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let endYear = dtInput.getFullYear()
            let endMonth = '12'
            let endDay = '31'
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            this.$path.params.Begin = startDate + 'T00:00:00'
            this.$path.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = this.labelCurrentYear
            this.$path.push('changeDate')
        },
        viewLastYear() {
            let dtInput = new Date()
            dtInput.setYear(dtInput.getFullYear() - 1)

            let startYear = dtInput.getFullYear()
            let startMonth = '01'
            let startDay = '01'
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let endYear = dtInput.getFullYear()
            let endMonth = '12'
            let endDay = '31'
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            this.$path.params.Begin = startDate + 'T00:00:00'
            this.$path.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = this.labelLastYear
            this.$path.push('changeDate')
        },
        viewCurrentMonth() {
            let dtInput = new Date()

            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = '01'
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let dtInput2 = new Date(dtInput.getFullYear(), dtInput.getMonth() + 1, 0)
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = dtInput2.getDate()
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            this.$path.params.Begin = startDate + 'T00:00:00'
            this.$path.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = this.labelCurrentMonth
            this.$path.push('changeDate')
        },
        viewLastMonth() {
            let dtInput = new Date()
            dtInput.setMonth(dtInput.getMonth() - 1)

            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = '01'
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let dtInput2 = new Date(dtInput.getFullYear(), dtInput.getMonth() + 1, 0)
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = dtInput2.getDate()
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            this.$path.params.Begin = startDate + 'T00:00:00'
            this.$path.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = this.labelLastMonth
            this.$path.push('changeDate')
        },
        viewLast2Month() {
            let dtInput = new Date()
            dtInput.setMonth(dtInput.getMonth() - 2)

            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = '01'
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let dtInput2 = new Date(dtInput.getFullYear(), dtInput.getMonth() + 1, 0)
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = dtInput2.getDate()
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            this.$path.params.Begin = startDate + 'T00:00:00'
            this.$path.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = this.labelLast2Month
            this.$path.push('changeDate')
        },
        setCustomDateRange(objValue) {
            let startYear = objValue.start.getFullYear()
            let startMonth = this.get2Digit(objValue.start.getMonth() + 1)
            let startDay = this.get2Digit(objValue.start.getDate())
            let startDate = startYear + '-' + startMonth + '-' + startDay
            let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let endYear = objValue.end.getFullYear()
            let endMonth = this.get2Digit(objValue.end.getMonth() + 1)
            let endDay = this.get2Digit(objValue.end.getDate())
            let endDate = endYear + '-' + endMonth + '-' + endDay
            let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            let newStartDate = startDate + 'T00:00:00'
            let newEndDate = endDate + 'T23:59:59'

            if (this.$path.params.Begin === newStartDate && this.$path.params.End === newEndDate) {
                // this.$refs.dropdownCustomOption.toggle()
            } else {
                this.$path.params.Begin = newStartDate
                this.$path.params.End = newEndDate
                this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
                this.$refs.dropdownCustomOption.toggle()
                this.$path.push('changeDate')
            }
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        isJson(data) {
            try {
                JSON.parse(data)
            } catch (e) {
                return false
            }
            return true
        },
        dataStringify(data) {
            return JSON.stringify(JSON.parse(data), undefined, 2)
        },
    },
}
</script>
