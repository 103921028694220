import log from 'loglevel'

const pathParamsMixin = {
    data() {
        return {
            isActivated: false,
        }
    },
    activated() {
        //console.log(this.$options.name, ': activated() ------------------------------------------')
        this.isActivated = true
        this.$path.push()
    },
    deactivated() {
        // console.log(this.$options.name, ': deactivated() ------------------------------------------')
        this.isActivated = false
    },
    beforeCreate() {
        // console.log('pathParamsMixin: beforeCreate()')
        this.$options.computed.$path = function() {
            let pathParams = this.$options.pathParams || {}
            return {
                vue: this,
                options: pathParams.options || {},
                params: pathParams.params || {},
                __onChange: null,
                monitor(onChange) {
                    this.__onChange = onChange
                    this.vue.$watch('$route', () => {
                        if (this.options.keepAlive && !this.vue.isActivated) return
                        this.extract()
                        if (this.__onChange) this.__onChange()
                    })
                },
                push(caller = '') {
                    let q = Object.assign({}, this.params)
                    // console.log("push => caller: ", caller);
                    // console.log("push => query: ", q);
                    let $r = this.vue.$route
                    this.vue.$router
                        .push({
                            name: $r.name,
                            params: $r.params,
                            query: q,
                        })
                        .catch(() => {})
                },
                extract() {
                    let $r = this.vue.$route
                    // console.log("extract => query: ", $r.query);

                    // Assign value from route's query with type conversion
                    //
                    for (let p in this.params) {
                        if (!$r.query[p]) continue
                        this.params[p] = typeof this.params[p] == 'number' ? Number($r.query[p]) : $r.query[p]
                    }
                    // Validate query
                    //
                    for (let p in this.params) {
                        if (!this.params[p] || (typeof this.params[p] == 'string' && this.params[p].length == 0)) {
                            log.debug(`Invalid query parameter '${p}' = ${this.params[p]}`)
                            //return
                        }
                    }

                    // console.log("new query: ", JSON.stringify(this.params));
                },
            }
        }
    },
    created() {
        this.$path.extract()
    },
}

export { pathParamsMixin }
