import BTabContent from '@/components/common/BTabContent'
import BBackButton from '@/components/common/BBackButton'
import BBreadcrumb from '@/components/common/BBreadcrumb'
import BDetailView from '@/components/common/BDetailView'
import BTabPanel from '@/components/common/BTabPanel'
import BFilter from '@/components/common/BFilter'
import BPageSize from '@/components/common/BPageSize'
import BPagingCounter from '@/components/common/BPagingCounter'
import BSearchBox from '@/components/common/BSearchBox'
import BActionButton from '@/components/common/BActionButton'
import BRapidSearchBox from '@/components/common/BRapidSearchBox'
import BEmptyState from './BEmptyState'
import BValidateField from './BValidateField'

export default {
    install(Vue) {
        Vue.component('BTabContent', BTabContent)
        Vue.component('BBackButton', BBackButton)
        Vue.component('BBreadcrumb', BBreadcrumb)
        Vue.component('BDetailView', BDetailView)
        Vue.component('BTabPanel', BTabPanel)
        Vue.component('BFilter', BFilter)
        Vue.component('BPageSize', BPageSize)
        Vue.component('BPagingCounter', BPagingCounter)
        Vue.component('BSearchBox', BSearchBox)
        Vue.component('BActionButton', BActionButton)
        Vue.component('BRapidSearchBox', BRapidSearchBox)
        Vue.component('BEmptyState', BEmptyState)
        Vue.component('BValidateField', BValidateField)
    },
}
