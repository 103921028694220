<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">Recent Activation</p>
        </header>

        <div class="card-content bg">
            <div class="content">
                <b-table :data="recentActivityQuery.Rows">
                    <b-table-column label="Activation Date" sortable v-slot="props">
                        {{ props.row.ActivatedDate }}
                    </b-table-column>

                    <b-table-column label="Machine" sortable v-slot="props">
                        {{ props.row.SerialNumber }}
                    </b-table-column>

                    <b-table-column label="Customer" sortable v-slot="props">
                        {{ props.row.Customer }}
                    </b-table-column>

                    <b-table-column label="Product" sortable v-slot="props">
                        {{ props.row.Product }} - {{ props.row.ProductVersion }}
                    </b-table-column>

                    <b-table-column label="Period" sortable v-slot="props">
                        {{ props.row.Periods }}
                    </b-table-column>

                    <b-table-column label="License Type" sortable v-slot="props">
                        {{ props.row.Type }}
                    </b-table-column>

                    <template slot="empty">
                        <BEmptyState title="No any item" subtitle="" />
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
import { pathParamsMixin } from '@/mixins'
import Query from '@/libs/Query'

export default {
    name: 'RecentActivity',
    props: {
        msg: String,
    },
    // mixins: [pathParamsMixin],
    // pathParams: {
    //     params: {
    //         cusID: '',
    //         Begin: '',
    //         End: '',
    //         currentPage: 1,
    //         pageSize: 15,
    //         sortColumn: 'Timestamp',
    //         sortDirection: 'desc',
    //         cache: true
    //     }
    // },
    data() {
        return {
            todayDateString: '',
            recentActivityQuery: new Query(this.$http, 'MetricRecentLicenseActivationsQuery', { ItemsRequire: 15 }),
        }
    },
    created() {
        // let todayDateString = this.formatDate(new Date())
        // let vBegin = this.todayDateString + 'T00:00:00'
        // let vEnd = this.todayDateString + 'T23:59:59'

        this.recentActivityQuery.execute({
            //Begin: vBegin, End: vEnd
            sortColumn: 'ActivatedDate',
            sortDirection: 'Descending',
            // Begin: '2019-01-01T00:00:00',
            // End: '2019-12-31T23:59:59'
        })
    },
    methods: {
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear()

            if (month.length < 2) month = '0' + month
            if (day.length < 2) day = '0' + day

            return [day, month, year].join('-')
        },
    },
}
</script>
