<template>
    <div class="content-container">
        <div class="level space-16" v-if="!hideHeaderSection">
            <div class="level-left">
                <slot name="title">
                    <span class="title">{{ pageTitle }}</span>
                </slot>
            </div>
            <div class="level-right">
                <slot name="actionButton"></slot>
            </div>
        </div>

        <div style="margin-bottom: 16px; border-bottom: 1px solid #ccc;" v-if="!hideHeaderSection"></div>

        <div class="level space-8">
            <div class="level-left">
                <BSearchBox
                    v-show="!disableSearchByKeyword"
                    ref="searchBox"
                    class="level-item"
                    :initValue="myKeyword"
                    :placeholder="searchPlaceholder"
                    @submit="
                        (value) => {
                            $emit('onKeywordSearch', value)
                        }
                    "
                />
                <slot name="filterInLine"></slot>
            </div>
            <div class="level-right">
                <BPageSize
                    class="level-item"
                    v-model="myPageSize"
                    :options="[10, 20, 50, 100, 500]"
                    @change="
                        (value) => {
                            $emit('onPageSizeChange', value)
                        }
                    "
                />
            </div>
        </div>

        <slot name="filter"></slot>

        <div class="level space-0">
            <div class="level-left" />
            <div class="level-right">
                <BPagingCounter
                    class="level-item"
                    :loading="listQuery.isLoading"
                    :beginRow="listQuery.beginRow"
                    :endRow="listQuery.endRow"
                    :totalRows="listQuery.TotalRows"
                />
            </div>
        </div>

        <div v-if="!listQuery.isLoading" class="card card-container">
            <slot v-bind:result="listQuery.Rows" v-bind:alert="alert"> </slot>
        </div>

        <div v-if="listQuery.isLoading" class="card card-container">
            <slot name="loadingState" v-bind:loadingRows="loadingRows"> </slot>
        </div>

        <div class="pagination-container">
            <BPagination
                :total="listQuery.TotalRows"
                :per-page="myPageSize"
                :current="myCurrentPage"
                @change="
                    (page) => {
                        $emit('onPageChange', page)
                        myCurrentPage = page
                    }
                "
            />
        </div>

        <div style="padding-bottom: 50px;"></div>
    </div>
</template>

<script>
import Query from '@/libs/Query'
import Command from '@/libs/Command'

export default {
    name: 'BListview',
    props: {
        hideHeaderSection: {
            type: [Boolean],
            default: false,
        },
        pageTitle: {
            type: [String],
        },
        disableSearchByKeyword: {
            type: [Boolean],
            default: false,
        },
        searchPlaceholder: {
            type: [String],
            default: 'Search Name...',
        },
        QueryAPIName: {
            type: [String],
            required: true,
        },
        QueryParams: {
            type: [Object],
            required: true,
        },
        DeleteAPIName: {
            type: [String],
        },
    },
    data() {
        return {
            watchingQueryParams: false,
            neverLoad: true,
            selected: [],
            myKeyword: '',
            myPageSize: 10,
            myCurrentPage: 1,
            loadingRows: [],

            listQuery: new Query(this.$http, this.QueryAPIName, {}),
            deleteCommand: new Command(this.$http, 'DELETE', this.DeleteAPIName),
        }
    },
    created() {
        const { keyword, currentPage, pageSize } = this.QueryParams

        this.myKeyword = keyword
        this.myPageSize = pageSize
        this.myCurrentPage = currentPage
    },
    watch: {
        QueryParams: {
            handler(newVal, oldVal) {
                console.log('Watch triged')
                if(this.watchingQueryParams) {
                    this.selected = []
                    //this.listQuery.execute(this.QueryParams)                
                    this.reload();
                    //console.log('Watch Query Param')
                    
                }
            },
            deep: true,
            // immediate: true,
        },
        // watchingQueryParams() {
        //     console.log('Watch Start watch')
        // }
    },
    methods: {
        reload() {
            this.neverLoad = false
            this.listQuery.execute(this.QueryParams)
            this.loadingRows = []
            for (let i = 0; i < this.QueryParams.pageSize; i++) this.loadingRows.push(i)
        },
        confirmDelete(Ids, confirmMessage) {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: confirmMessage,
                confirmText: 'Delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteCommand.execute({ ids: Ids }).then((resp) => {
                        if (resp.status === 204) {
                            this.listQuery.execute()
                            // this.selectedRows = []
                            this.$emit('onDeleted', true)
                        } else {
                            this.$emit('onDeleted', false)
                        }
                    })
                },
            })
        },
        alert(text) {
            this.$buefy.dialog.alert(text)
        },
        GetRowCount() {
            return this.listQuery.TotalRows
        },
        startWatch() {
            this.watchingQueryParams = true
        }
    },
    computed: {
        isLoading() {
            return this.listQuery.isLoading
        },
        RowCount() {
            return this.listQuery.TotalRows
        }
    },
    // mounted() {
    //     if(this.neverLoad){
    //         console.log('load when mounted')
    //         this.reload()
    //         this.startWatch()
    //     }
    // }
}
</script>

<style scoped></style>
