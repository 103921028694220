<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="createUserCommand.isLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Create User</div>
                    </header>
                    <div class="card-content">
                        <b-field horizontal label="Enabled" message="Enabled/Disabled user status">
                            <b-switch v-model="isSwitchedCustom" type="is-info" true-value="Yes" false-value="No">
                                {{ isSwitchedCustom }}
                            </b-switch>
                        </b-field>

                        <b-field
                            horizontal
                            label="Username*"
                            :type="{ 'is-danger': $v.input.username.$error }"
                            :message="{ Username: $v.input.username.$error }"
                        >
                            <b-input name="username" v-model="input.username" autocomplete="off" expanded></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Password*"
                            :type="{ 'is-danger': $v.input.password.$error }"
                            :message="{ Password: $v.input.password.$error }"
                        >
                            <b-input
                                name="password"
                                v-model="input.password"
                                type="password"
                                autocomplete="off"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Confirm Password*"
                            :type="{ 'is-danger': $v.input.confirmPassword.$error }"
                            :message="{ 'Please confirm a password': $v.input.confirmPassword.$error }"
                        >
                            <b-input
                                name="confirmPassword"
                                v-model="input.confirmPassword"
                                type="password"
                                autocomplete="off"
                                expanded
                            ></b-input>
                        </b-field>

                        <hr />

                        <b-field horizontal label="Full Name">
                            <b-input name="fullame" v-model="input.fullname" expanded></b-input>
                        </b-field>

                        <b-field horizontal label="Gender">
                            <b-select name="gender" v-model="input.gender" placeholder="Select a gender">
                                <option
                                    v-for="(option, index) in genderSelect"
                                    :value="option"
                                    :key="index"
                                    :selected="option === input.gender ? true : false"
                                >
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>

                        <!-- date-parser="dateParser" -->
                        <b-field horizontal label="Date of Birth">
                            <b-datepicker
                                v-model="input.birthDate"
                                placeholder="Type or select a date..."
                                icon="calendar-today"
                                style="display: block;"
                                :date-formatter="dateFormatter"
                                :date-parser="dateParser"
                                editable
                            >
                            </b-datepicker>
                        </b-field>

                        <b-field horizontal label="Address">
                            <b-input name="address" v-model="input.address" expanded></b-input>
                        </b-field>

                        <b-field horizontal label="Country">
                            <b-select name="country" v-model="input.country" placeholder="Select a country">
                                <option v-for="(option, index) in countrySelect" :value="option" :key="index">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field horizontal label="Postal Code">
                            <b-input name="postalCode" v-model="input.postalCode" expanded></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Phone Number"
                            :type="{ 'is-danger': $v.input.phoneNumber.$error }"
                            :message="{ 'only number': $v.input.phoneNumber.$error }"
                        >
                            <b-input name="phoneNumber" v-model="input.phoneNumber" type="tel" expanded></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Email"
                            :type="{ 'is-danger': $v.input.email.$error }"
                            :message="{ 'Email Format': $v.input.email.$error }"
                        >
                            <b-input name="email" v-model="input.email" type="email" expanded></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Assigned Role"
                            :type="{ 'is-danger': $v.input.roleIds.$error }"
                            :message="{ 'Please assign the role': $v.input.roleIds.$error }"
                        >
                            <div class="assigned-component">
                                <div class="i-relative">
                                    <b-field>
                                        <b-autocomplete
                                            v-model="unassignRoleName"
                                            :data="unassignRoleQuery.Rows"
                                            :loading="unassignRoleQuery.isLoading"
                                            :keep-first="true"
                                            :clear-on-select="true"
                                            :open-on-focus="true"
                                            placeholder="e.g. Rolename"
                                            icon="magnify"
                                            field="Name"
                                            @focus="getUnassignedRole(unassignRoleName)"
                                            @typing="(value) => getUnassignedRole(value)"
                                            @input="null"
                                            @select="(value) => assignRole(value)"
                                        >
                                        </b-autocomplete>
                                    </b-field>
                                </div>
                                <ul class="list mt-32 list-assigned">
                                    <li class="list-item" v-for="(item, index) in itemRoles" :key="index">
                                        <div class="item-assigned">
                                            <span>{{ item.Name }}</span>
                                            <span class="item-assigned-remove" @click="unassignRole(item.Id)"
                                                ><b-icon icon="close"></b-icon
                                            ></span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Create</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CountryList from 'country-list'
import Query from '@/libs/Query'
import Command from '@/libs/Command'
import { required, numeric, email } from 'vuelidate/lib/validators'

const countryList = CountryList.getNames()
export default {
    name: 'AccountUsersCreate',
    props: {
        msg: String,
    },
    data() {
        return {
            /* Switch */
            isSwitchedCustom: 'Yes',
            /* Select Option */
            genderSelect: ['Male', 'Female'],
            countrySelect: countryList,

            input: {
                username: '',
                password: '',
                confirmPassword: '',
                fullname: '',
                gender: 'Undefined',
                birthDate: null,
                address: '',
                country: null,
                postalCode: '',
                phoneNumber: '',
                email: '',
                roleIds: [],
            },
            createUserCommand: new Command(this.$http, 'POST', 'UserAdd'),

            unassignRoleName: '',
            unassignRoleQuery: new Query(this.$http, 'RolesQuery', { Keyword: '' }, 1, 0, 'Name'),
            selectedRole: null,
            itemRoles: [],
        }
    },
    validations: {
        input: {
            username: {
                required,
            },
            password: {
                required,
            },
            confirmPassword: {
                required,
            },
            phoneNumber: {
                numeric,
            },
            email: {
                email,
            },
            roleIds: {
                required,
            },
        },
    },
    methods: {
        dateFormatter(d) {
            return d.toLocaleDateString('en-GB')
        },
        dateParser(d) {
            let DD = d.substring(0, 1)
            let MM = d.substring(3, 4)
            let YYYY = d.substring(6, 9)
            let parseObj = new Date(Date.parse(YYYY + '-' + MM + '-' + DD))
            return parseObj
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.createUser()
        },
        createUser() {
            this.createUserCommand
                .execute({
                    Users: [
                        {
                            Emabled: this.isSwitchedCustom == 'Yes',
                            UserName: this.input.username,
                            Password: this.input.password,
                            FullName: this.input.fullname,
                            Email: this.input.email,
                            Gender: this.input.gender,
                            DateOfBirth: new Date(this.input.birthDate),
                            Job: this.input.job,
                            Company: this.input.company,
                            Address: this.input.address,
                            Country: this.input.country,
                            PostalCode: this.input.postalCode,
                            PhoneNumber: this.input.phoneNumber,
                            RoleIds: this.input.roleIds,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 201) {
                        this.$router.push({ name: 'userList' })
                    }
                })
        },
        getUnassignedRole(value) {
            this.unassignRoleQuery.execute({
                Keyword: value,
            })
        },
        assignRole(option) {
            let object = null

            if (option) {
                object = this.itemRoles.filter((obj) => obj.Id === option.Id)

                if (object.length === 0) {
                    this.itemRoles.push(option)
                    this.input.roleIds.push(option.Id)
                }
            }
        },
        unassignRole(removeId) {
            this.itemRoles = this.itemRoles.filter((obj) => obj.Id !== removeId)
            this.input.roleIds = this.input.roleIds.filter((item) => item !== removeId)
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
