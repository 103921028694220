<template>
  <BTabContent title="Machine">        
        <div class="detail-item-content">
            
            <LayoutListInline
                padding-x="0"
                padding-y="0"
                gap-x="4"
                horizontal-align="left"
                vertical-align="center"
                :wrap="true"
            >
                <!-- <p> Assign Machine : </p> -->
                <!-- <b-autocomplete
                    v-model="unassignedMachineKeyword"
                    :data="unassignedMachineQuery.Rows"
                    :loading="unassignedMachineQuery.isLoading"
                    :keep-first="true"
                    :clear-on-select="true"
                    :open-on-focus="true"
                    placeholder="Search to assign Machine"
                    icon="magnify"
                    field="SerialNumber"
                    @focus="getUnassignedMachine(unassignedMachineKeyword)"
                    @typing="value => getUnassignedMachine(value)"
                    @input="null"
                    @select="value => assignMachine(value)"
                >
                     <template slot="empty">No results found</template> 
                </b-autocomplete> -->

                <BExtAutoComplete
                    QueryAPIName="MachinesQuery"
                    :QueryParams="{ Keyword: '', ExcludeByCustomerId: '*' }"
                    displayField="SerialNumber"
                    placeholderText="Search to assign Machine"
                    :clearOnSelect="true"
                    @onItemSelect="(value) => assignMachine(value)"
                />

            </LayoutListInline>

            <BListview 
                :hideHeaderSection="true"
                :disableSearchByKeyword="true"
                QueryAPIName="MachinesOfCustomerGet"
                :QueryParams="params"
                @onPageSizeChange="(value) => { params.pageSize = value; }"
                @onPageChange="(value) => { params.currentPage = value; }"
                ref="listview">

                <template v-slot:default="{ result }" >

                    <b-table
                        :data="result"
                        backend-pagination
                        backend-sorting
                        :current-page="params.currentPage"
                        :per-page="params.pageSize"
                        :default-sort-direction="params.sortDirection"
                        :default-sort="params.sortColumn"
                        @sort="
                            (field, order) => {
                                params.sortColumn = field
                                params.sortDirection = order
                            }
                        "
                    >

                        <b-table-column field="SerialNumber" label="Serial Number" sortable v-slot="props">
                            {{ props.row.SerialNumber }}
                        </b-table-column>

                        <b-table-column  field="Licenses" label="License Status" v-slot="props">
                            <div v-if="props.row.Licenses.length > 0">
                                Has license
                            </div>
                            <div v-else>
                                No license
                            </div>
                        </b-table-column>

                        <b-table-column field="control" centered v-slot="props">
                            <b-dropdown position="is-bottom-left" >
                                <button class="button border-0 btn-control" slot="trigger">
                                    <b-icon icon="dots-horizontal" size="is-small"></b-icon>
                                </button>

                                <b-dropdown-item has-link v-if="props.row.Licenses.length == 0">
                                    <router-link :to="{ name: 'machineLicenseAdd', params: { machineId: props.row.Id } }">Create License</router-link>
                                </b-dropdown-item>

                                <b-dropdown-item  @click="confirmUnassign(props.row)">Unassign</b-dropdown-item>
                            </b-dropdown>
                        </b-table-column>

                        <template slot="empty">
                            <BEmptyState title="No assigned machine" subtitle=" " />
                        </template>
                    </b-table>

                </template>

                <template v-slot:loadingState="{ loadingRows }">
                    <b-table :data="loadingRows">
                        <b-table-column label="Serial Number">
                            <b-skeleton height="35px" :animated="true"></b-skeleton>
                        </b-table-column>

                        <b-table-column label="Licenses">
                            <b-skeleton height="35px" :animated="true"></b-skeleton>
                        </b-table-column>

                        <b-table-column field="control">
                            <b-skeleton height="35px" :animated="true"></b-skeleton>
                        </b-table-column>                   
                    </b-table>
                </template>

            </BListview>
        </div>
    </BTabContent>
</template>

<script>
import Query from '@/libs/Query'
import Command from '@/libs/Command'
import BListview from '@/components/common/BListview'
import BExtAutoComplete from '@/components/common/BExtAutoComplete'

export default {
    props: {
        msg: String
    },
    components: {
        BListview,
        BExtAutoComplete
    },
    url: {
        __root: 'params',
        Id: { key: 'cid' },
        currentPage: { key: 'page' },
        pageSize: { key: 'size' },
        sortColumn: { key: 'col' },
        sortDirection: { key: 'dir' },
    },
    data() {
        const params = {
            Id: this.$route.params.customerId,
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'SerialNumber',
            sortDirection: 'asc'
        }

        return {
            params,

            //unassignedMachineKeyword: '',

            // assignedMachineQuery: new Query(
            //     this.$http, 
            //     'MachinesOfCustomerGet', 
            //     { Id: this.$route.params.customerId },
            //     1,
            //     10,
            //     'SerialNumber'),
            // unassignedMachineQuery: new Query(
            //     this.$http,
            //     'MachinesQuery',
            //     { Keyword: '', ExcludeByCustomerId: '*' },
            //     1,
            //     0,
            //     'SerialNumber'
            // ),
            assignMachineCommand: new Command(this.$http, 'POST', 'MachinesOfCustomerAssign'),
            unassignMachineCommand: new Command(this.$http, 'DELETE', 'MachinesOfCustomerUnassign')
        }
    },
    // computed: {
    //     assignedMachines() {
    //         return this.assignedMachineQuery.Rows
    //     }
    // },
    // created() {
    //     this.assignedMachineQuery.execute().then(() => {
    //         this.$path.monitor(() => {
    //             this.assignedMachineQuery.execute()
    //         })
    //     })
    // },
    methods: {
        // getUnassignedMachine(value) {
        //     this.unassignedMachineQuery.execute({
        //         Keyword: value
        //     })
        // },
        assignMachine(value) {
            if(value && value.Id != ''){
                this.assignMachineCommand.execute({
                    CustomerId: this.$route.params.customerId,
                    machineIds: value.Id
                }).then(() => {
                    //this.unassignedMachineQuery.clearResponse()
                    //this.assignedMachineQuery.execute()
                    this.$refs.listview.reload()
                })
            }
        },
        confirmUnassign(machine) {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: "Are you sure you want to unassign machine: <b>'" + machine.SerialNumber + "'</b>?",
                confirmText: 'Unassign',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.unassignMachineCommand
                    .execute({ CustomerId: this.$route.params.customerId, machineIds: machine.Id })
                    .then(resp => {
                        if (resp.status === 204) {
                            //this.assignedMachineQuery.execute({ posId: this.$route.params.customerId })
                            this.$refs.listview.reload()
                        }
                    })
                }
            })
        }
    },
    mounted() {
        this.$refs.listview.startWatch()
        this.$refs.listview.reload()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
