<template>
  <BTabContent :loading="groupQuery.isLoading">
        <div class="detail-item-title">
            <div class="level">
                <div class="level-left">Group</div>
                <div class="level-right" v-if="groupQuery.isLoaded && groups.length === 0">
                    <router-link :to="{ name: 'customerAssignGroup', params: {customerId: $route.params.customerId}}" exact>
                        <button class="button is-small is-info">Assign Customer to a Group</button>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="detail-item-content">
            <ul class="list mt-32 list-assigned">
                <li class="list-item" v-for="(group, index) in groups" :key="index">
                    <div class="item-assigned">
                        <div>
                            <!-- <div
                                v-for="(itemPath, indexPath) in group.Path"
                                :key="indexPath"
                                style="display: inline-block; font-size: larger;"
                            >
                                <span v-if="indexPath > 0">&nbsp;&gt;&nbsp;</span>
                                {{ itemPath.Name }}
                            </div> -->
                            {{group.Name}}
                        </div>
                        <div class="item-assigned-remove" @click="confirmUnassign(group)">
                            <b-tooltip label="Unassign" position="is-right" type="is-light">
                                <b-icon icon="close"></b-icon>
                            </b-tooltip>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </BTabContent>
</template>

<script>
import Query from '@/libs/Query'
import Command from '@/libs/Command'
import { pathParamsMixin } from '@/mixins/pathParams'

export default {
  props: {
    msg: String
  },
  mixins: [pathParamsMixin],
    data() {
        return {            
            groupQuery: new Query(this.$http, 'CustomerGroupsOfCustomerGet', { Id: this.$route.params.customerId }),
            unassignGroupCommand: new Command(this.$http, 'DELETE', 'CustomerGroupsOfCustomerUnassign')
        }
    },
    computed: {
        groups() {
            return this.groupQuery.Rows
        }
    },
    created() {
        this.groupQuery.execute().then(() => {
            this.$path.monitor(() => {
                this.groupQuery.execute()
            })
        })
    },
    methods: {
        confirmUnassign(group) {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message:
                    "Are you sure you want to unassign from group: <b>'" + group.Name + "'</b>?",
                confirmText: 'Unassign',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.unassignGroupCommand.execute({ CustomerId: this.$route.params.customerId, GroupIds: group.Id }).then(resp => {
                        if (resp.status === 204) {
                            this.groupQuery.execute({ posId: this.$route.params.posId })
                        }
                    })
                }
            })
        }
    }
};
</script>
