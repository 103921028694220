<template>
    <MainListLayout>

        <BListview 
            pageTitle="Machine"
            searchPlaceholder="Search Serial Number..."
            QueryAPIName="MachinesQuery"
            :QueryParams="params"
            DeleteAPIName="MachinesRemove"

            @onKeywordSearch="(value) => { params.keyword = value; }"
            @onPageSizeChange="(value) => { params.pageSize = value; }"
            @onPageChange="(value) => { params.currentPage = value; }"
            @onDeleted="(value) => { if(value) { selectedRows = []; } }"
            ref="listview">

            <template v-slot:actionButton>
                <BActionButton
                    v-if="selectedRows.length > 0"
                    :actionPermission="actionPermission"
                    :actionItems="actionItems"
                    :selectedRows="selectedRows"
                />
                <button v-else class="button is-primary ml-16" @click="$router.push({ name: 'machineRegister' })">Register</button>
            </template>

            <template v-slot:filter>
                <LayoutListInline
                    padding-x="0"
                    padding-y="0"
                    gap-x="4"
                    horizontal-align="left"
                    vertical-align="center"
                    :wrap="true"
                >
                    <BFilter
                        tooltip="Machine Model"            
                        :loading="false"
                        v-model="filterModel"
                        :options="machineModelQuery.Rows"
                        @change="
                            (Model) => {
                                params.ModelIds = Model.Id
                            }
                        "
                    ></BFilter>

                    <BFilter
                        tooltip="Machine Assignment"
                        :loading="false"
                        v-model="filterCustomer"
                        :options="filterCustomerOption"
                        @change="
                            (custype) => {
                                params.ExcludeByCustomerId = custype.Id
                                if (custype.Id != '-') params.CustomerIds = ''
                            }
                        "
                    ></BFilter>

                    <BTooltip label="Customer" position="is-top" type="is-dark" :animated="true" v-if="params.ExcludeByCustomerId == '-'" class="customerAssigned">
                        <!-- <b-autocomplete
                            v-model="customerKeyword"
                            :data="customersQuery.Rows"
                            :loading="customersQuery.isLoading"
                            :keep-first="true"
                            :open-on-focus="true"
                            placeholder="Customer name..."
                            icon="magnify"
                            field="Name"
                            @focus="getCustomer(customerKeyword)"
                            @typing="(value) => getCustomer(value)"
                            @select="(value) => selectCustomer(value)"
                        >
                        </b-autocomplete> -->
                        <BCustomerPicker @onItemSelect="(value) => selectCustomer(value)" />
                    </BTooltip>
                   

                    <BTooltip label="Manufacture Date From" position="is-top" type="is-dark" :animated="true">
                        <b-datepicker
                            ref="beginDate"
                            v-model="BeginManufactureDate"
                            placeholder="Manufacture Date From"
                            icon="calendar-today"
                            style="display: block;"
                            :date-formatter="dateFormatter"
                            :date-parser="dateParser"
                            editable
                        >
                        </b-datepicker>
                    </BTooltip>
                    <p> to </p>
                    <BTooltip label="Manufacture Date To" position="is-top" type="is-dark" :animated="true">
                        <b-datepicker
                            ref="beginDate"
                            v-model="EndManufactureDate"
                            placeholder="Manufacture Date To"
                            icon="calendar-today"
                            style="display: block;"
                            :date-formatter="dateFormatter"
                            :date-parser="dateParser"
                            editable
                        >
                        </b-datepicker>
                    </BTooltip>
                </LayoutListInline>
            </template>

            <template v-slot:default="{ result }">
                <b-table
                    :data="result"
                    backend-pagination
                    backend-sorting
                    checkable
                    :current-page="params.currentPage"
                    :per-page="params.pageSize"
                    :checked-rows.sync="selectedRows"
                    :default-sort-direction="params.sortDirection"
                    :default-sort="params.sortColumn"
                    @sort="
                        (field, order) => {
                            params.sortColumn = field
                            params.sortDirection = order
                        }
                    "
                >
                    <b-table-column field="SerialNumber" label="Serial Number" sortable v-slot="props">
                        <router-link :to="{ name: 'machineDetail', params: { machineId: props.row.Id, tabId: 0 } }" v-if="props.row.Id">
                            <span class="item-link">{{ props.row.SerialNumber }}</span>
                        </router-link>
                    </b-table-column>

                    <b-table-column field="Model" label="Model" v-slot="props">
                        <template v-if="props.row.Model">
                            {{ props.row.Model.Name }}
                        </template>
                    </b-table-column>

                    <b-table-column field="ManufacturedDate" label="Manufactured Date" sortable v-slot="props">
                        {{ format_date(props.row.ManufacturedDate) }}
                    </b-table-column>

                    <b-table-column field="Customer" label="Customer" sortable v-slot="props">
                        {{ props.row.Customer }}
                    </b-table-column>

                    <b-table-column field="control" centered v-slot="props">
                        <b-dropdown  v-if="props.row.Id"
                            position="is-bottom-left"
                            v-permit="{ resources: 'Machine', any: true, actions: ['Edit', 'Delete'] }"
                        >
                            <button class="button border-0 btn-control" slot="trigger">
                                <b-icon icon="dots-horizontal" size="is-small"></b-icon>
                            </button>
                            <b-dropdown-item has-link v-permit="{ resources: 'Machine', actions: ['Edit'] }">
                                <router-link :to="{ name: 'machineEdit', params: { machineId: props.row.Id } }"
                                    >Edit</router-link
                                >
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="confirmDelete([props.row])"
                                v-permit="{ resources: 'Machine', actions: ['Delete'] }"
                                >Delete</b-dropdown-item
                            >
                        </b-dropdown>
                    </b-table-column>
                    <template slot="empty">
                        <BEmptyState title="No mached result" subtitle="Please try another criteria" />
                    </template>
                </b-table>
            </template>

            <template v-slot:loadingState="{ loadingRows }">
                <b-table :data="loadingRows">
                    <b-table-column label="Serial Number">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Model">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Manufacture Date">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Customer">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column field="control">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>                   
                </b-table>
            </template>

        </BListview>

    </MainListLayout>
</template>

<script>
import Query from '@/libs/Query'
import { MainListLayout } from '@/layouts'
import BListview from '@/components/common/BListview'
import { format_date } from '@/libs/datetime'
import moment from 'moment'
import BCustomerPicker from '@/components/control/BCustomerPicker'

export default {
    name: 'ManageMachineList',
    props: {
        msg: String,
    },
    components: {
        MainListLayout,
        BListview,
        BCustomerPicker
    },
    url: {
        __root: 'params',

            keyword: { key: 'kw' },
            ModelIds: { key: 'mod' },
            CustomerIds: { key: 'cid' },
            BeginManufactureDate: { key: 'srt' },
            EndManufactureDate: { key: 'end' },
            ExcludeByCustomerId: { key: 'ext' },
            HasOwnerOnly: { key: 'onlycus' },
            currentPage: { key: 'page' },
            pageSize: { key: 'size' },
            sortColumn: { key: 'col' },
            sortDirection: { key: 'dir' },
    },

    data() {
        const params = {
            keyword: '',
            ModelIds: '',
            CustomerIds: '',
            BeginManufactureDate: '',
            EndManufactureDate: '',
            ExcludeByCustomerId: '',
            HasOwnerOnly: false,
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'SerialNumber',
            sortDirection: 'asc'
        }

        return {
            params,

            selectedRows: [],

            filterCustomer: '',
            filterCustomerOption: [
                { Id: '', Name: 'All Machines' },
                { Id: '*', Name: 'Unassigned Machines' },
                { Id: '-', Name: 'Assigned to Customer' },
            ],

            // customerKeyword: '',
            // customersQuery: new Query(this.$http, 'CustomersQuery', { keyword: '' }),

            BeginManufactureDate: null,
            EndManufactureDate: null,

            actionPermission: {
                resources: 'Machine',
                any: true,
                actions: ['Delete'],
            },
            actionItems: [
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(this.selectedRows),
                    permission: { resources: 'Machine', actions: ['Delete'] },
                },
            ],

            // Select Option
            machineModelQuery: new Query(this.$http, 'MachineModelsQuery', { Keyword: '' }, 1, 0),

            filterModel: null,

            format_date,
        }
    },
    watch: {
        filterCustomer() {
            this.params.HasOwnerOnly = this.params.ExcludeByCustomerId == '-'
        },
        BeginManufactureDate() {
            let dateStr = moment(this.BeginManufactureDate).format('YYYY-MM-DD 00:00:00')
            this.params.BeginManufactureDate =
                dateStr != 'Invalid date' && dateStr != '1970-01-01 00:00:00' ? dateStr : ''
        },
        EndManufactureDate() {
            let dateStr = moment(this.EndManufactureDate).format('YYYY-MM-DD 00:00:00')
            this.params.EndManufactureDate =
                dateStr != 'Invalid date' && dateStr != '1970-01-01 00:00:00' ? dateStr : ''
        },
    },
    async created() {
        await this.machineModelQuery.execute().then(() => {
            this.machineModelQuery.Rows.unshift({ Id: '', Name: 'All Models' })

            if (this.params.ModelIds !== '')
                this.filterModel = this.machineModelQuery.Rows.filter((item) => item.Id === this.params.ModelIds)
            else this.filterModel = this.machineModelQuery.firstRow

            switch(this.params.ExcludeByCustomerId) {
                case '' : this.filterCustomer = this.filterCustomerOption[0]; break;
                case '*': this.filterCustomer = this.filterCustomerOption[1]; break;
                case '-': this.filterCustomer = this.filterCustomerOption[2]; break;
                default : this.filterCustomer = this.filterCustomerOption[0];
            }

            //this.$refs.listview.reload()
            this.$refs.listview.startWatch()
        })
    },
    methods: {
        dateFormatter(d) {
            return d.toLocaleDateString('en-GB')
        },
        dateParser(d) {
            let DD = d.substring(0, 1)
            let MM = d.substring(3, 4)
            let YYYY = d.substring(6, 9)
            let parseObj = new Date(Date.parse(YYYY + '-' + MM + '-' + DD))
            return parseObj
        },
        confirmDelete(selectedRow) {
            let formattedItemsName = selectedRow.map((p) => `<b>${p.SerialNumber}</b>`).join('<br/>')
            let nPos = selectedRow.length > 1 ? selectedRow.length : ''
            let confirmMsg = `Are you sure you want to delete the following ${nPos} machine?<br/><br/>`
            let ItemsId = selectedRow.map((p) => p.Id)

            this.$refs.listview.confirmDelete(ItemsId, confirmMsg + formattedItemsName)
        },
        getCustomer(value) {
            this.customersQuery.execute({ keyword: value })
        },
        selectCustomer(value) {
            this.filterCustomer = this.filterCustomerOption[2]
            if (value) {
                this.params.CustomerIds = value.Id
            } else {
                this.params.CustomerIds = ''
            }
        },
    },
    mounted() {
        this.BeginManufactureDate =
            this.params.BeginManufactureDate && this.params.BeginManufactureDate != ''
                ? new Date(this.params.BeginManufactureDate)
                : null
        this.EndManufactureDate =
            this.params.EndManufactureDate && this.params.EndManufactureDate != ''
                ? new Date(this.params.EndManufactureDate)
                : null
    },
}
</script>

<style scoped>
.customerAssigned {
    margin-left: -12px;
}
</style>
