import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    render() {
        const items = [
            {
                label: 'Machine',
                to: 'licenseListMachine',
            },
            {
                label: 'Server',
                to: 'licenseListServer',
            },
        ]
        return (
            <div style={{ display: 'flex' }}>
                <span class='title mr-3'>License</span>
                {items.map((item) => (
                    <router-link
                        to={{ name: item.to }}
                        custom
                        scopedSlots={{
                            default: ({ isActive, navigate }) => (
                                <button
                                    type='button'
                                    onClick={navigate}
                                    class={['button', isActive ? 'is-light is-rounded is-inverted' : 'is-text']}>
                                    {item.label}
                                </button>
                            ),
                        }}
                    />
                ))}
            </div>
        )
    },
})
