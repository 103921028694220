var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MainListLayout',[_c('BListview',{ref:"listview",attrs:{"pageTitle":"Customer Group","searchPlaceholder":"Search Name...","QueryAPIName":"CustomerGroupsQuery","QueryParams":_vm.params,"DeleteAPIName":"CustomerGroupsRemove"},on:{"onKeywordSearch":function (value) { _vm.params.keyword = value; },"onPageSizeChange":function (value) { _vm.params.pageSize = value; },"onPageChange":function (value) { _vm.params.currentPage = value; },"onDeleted":function (value) { if(value) { _vm.selectedRows = []; } }},scopedSlots:_vm._u([{key:"actionButton",fn:function(){return [(_vm.selectedRows.length > 0)?_c('BActionButton',{attrs:{"actionPermission":_vm.actionPermission,"actionItems":_vm.actionItems,"selectedRows":_vm.selectedRows}}):_c('button',{staticClass:"button is-primary ml-16",on:{"click":function($event){return _vm.$router.push({ name: 'customerGroupCreate' })}}},[_vm._v(" Create ")])]},proxy:true},{key:"default",fn:function(ref){
var result = ref.result;
return [_c('b-table',{attrs:{"data":result,"backend-pagination":"","backend-sorting":"","checkable":"","current-page":_vm.params.currentPage,"per-page":_vm.params.pageSize,"checked-rows":_vm.selectedRows,"default-sort-direction":_vm.params.sortDirection,"default-sort":_vm.params.sortColumn},on:{"update:checkedRows":function($event){_vm.selectedRows=$event},"update:checked-rows":function($event){_vm.selectedRows=$event},"sort":function (field, order) {
                        _vm.params.sortColumn = field
                        _vm.params.sortDirection = order
                    }}},[_c('b-table-column',{attrs:{"field":"Name","label":"Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{
                            name: 'customerGroupDetail',
                            params: { customerGroupId: props.row.Id, tabId: 0 },
                        },"exact":""}},[_c('span',{staticClass:"item-link"},[_vm._v(_vm._s(props.row.Name))])])]}}],null,true)}),_c('b-table-column',{attrs:{"field":"ChildCount","label":"No. of Sub-Group","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":props.row.ChildStringify,"position":"is-right","type":"is-light"}},[_vm._v(" "+_vm._s(props.row.ChildCount)+" ")])]}}],null,true)}),_c('b-table-column',{attrs:{"field":"control","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-dropdown',{directives:[{name:"permit",rawName:"v-permit",value:({ resources: 'CustomerGroup', any: true, actions: ['Edit', 'Delete'] }),expression:"{ resources: 'CustomerGroup', any: true, actions: ['Edit', 'Delete'] }"}],attrs:{"position":"is-bottom-left"}},[_c('button',{staticClass:"button border-0 btn-control",attrs:{"slot":"trigger"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-small"}})],1),_c('b-dropdown-item',{directives:[{name:"permit",rawName:"v-permit",value:({ resources: 'CustomerGroup', any: true, actions: ['Edit', 'Delete'] }),expression:"{ resources: 'CustomerGroup', any: true, actions: ['Edit', 'Delete'] }"}],attrs:{"has-link":""}},[_c('router-link',{attrs:{"to":{
                                    name: 'customerGroupEdit',
                                    params: { sitePath: 0, customerGroupId: props.row.Id },
                                },"exact":""}},[_vm._v("Edit")])],1),_c('b-dropdown-item',{directives:[{name:"permit",rawName:"v-permit",value:({ resources: 'CustomerGroup', actions: ['Delete'] }),expression:"{ resources: 'CustomerGroup', actions: ['Delete'] }"}],on:{"click":function($event){return _vm.confirmDelete([props.row])}}},[_vm._v("Delete")])],1)]}}],null,true)}),_c('template',{slot:"empty"},[_c('BEmptyState',{attrs:{"title":"No mached result","subtitle":"Please try another criteria"}})],1)],2)]}},{key:"loadingState",fn:function(ref){
                                var loadingRows = ref.loadingRows;
return [_c('b-table',{attrs:{"data":loadingRows}},[_c('b-table-column',{staticStyle:{"width":"400px"},attrs:{"label":"Name"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1),_c('b-table-column',{attrs:{"label":"No. of Sub-Group"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1),_c('b-table-column',{attrs:{"field":"control"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }