<template>
    <div class="btn-back">
        <button class="button has-background-grey" @click="$emit('click')">
            <b-icon icon="arrow-left" type="is-white"></b-icon>
        </button>
    </div>
</template>

<script>
export default {
    name: 'BBackButton',
    props: {},

    data() {
        return {}
    },
    mounted() {}
}
</script>

<style scoped>
.btn-back {
    position: absolute;
    top: 64px;
    left: 72px;
}
.btn-back .button {
    width: 40px;
    height: 40px;
}
</style>

