<template>
    <!-- <div id="app" class="app-container">
        <div class="app-header" v-if="$router.history.current['path'] !== '/login'">
            <the-header></the-header>
        </div>
        <div class="app-content">
            <router-view></router-view>
        </div>
        <div class="app-footer" v-if="$router.history.current['path'] !== '/login'">
            <the-footer></the-footer>
        </div>
    </div> -->
    <router-view></router-view>
</template>

<script>
// import TheFooter from '@/components/views/ViewFooter.vue'
// import TheHeader from '@/components/views/ViewHeader.vue'

export default {
    name: 'app',
    // components: {
    //     TheFooter,
    //     TheHeader,
    // },
    async created() {
        // this.$http.interceptors.response.use(undefined, function (err) {
        //     return new Promise(function () {
        //         if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
        //           this.$store.dispatch('logout')
        //         }
        //         throw err
        //     })
        // })
        //console.log('App load permissions')
        await this.$store.dispatch('loadMyPermissionsAsync')
    },
    computed: {
        isLoggedIn() {
            return this.$store.getters.isLoggedIn
        },
    },
}
</script>

<style>
/* @import './assets/animate.min.css';
@import './assets/app.css'; */
</style>
