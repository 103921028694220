<template>
    <MainListLayout>
        <div class="level space-16">
            <div class="level-left">
                <span class="title">Dashboard</span>
            </div>
            <div class="level-right"></div>
        </div>

        <div class="columns is-desktop">
            <div class="column">
                <CustomerWorldMap />
            </div>

            <div class="column">
                <CustomerList />
            </div>
        </div>

        <div class="columns is-desktop">
            <div class="column">
                <MachineByModel />
            </div>

            <div class="column">
                <LicenseByType />
            </div>
        </div>

        <div class="columns is-desktop">
            <div class="column">
                <RecentActivation />
            </div>
        </div>
    </MainListLayout>
</template>

<script>
import { MainListLayout } from '@/layouts'
import CustomerWorldMap from '@/views/Dashboard/Widgets/CustomerWorldMap'
import CustomerList from '@/views/Dashboard/Widgets/CustomerList'
import MachineByModel from '@/views/Dashboard/Widgets/MachineByModel'
import LicenseByType from '@/views/Dashboard/Widgets/LicenseByType'
import RecentActivation from '@/views/Dashboard/Widgets/RecentActivation'

export default {
    name: 'Dashboard',
    props: {
        msg: String,
    },
    components: {
        MainListLayout,
        CustomerWorldMap,
        CustomerList,
        MachineByModel,
        LicenseByType,
        RecentActivation,
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
