<template>
    <div class="tab" style="position: relative">
        <BLoading :is-full-page="false" :active="loading" :can-cancel="true" />
        <div v-if="title">
            <LayoutFlexRow verticalAlign="bottom">
                <template #left>
                    <div class="title is-4">{{ title }}</div>
                </template>
                <template #right>
                    <slot name="header-right"></slot>
                </template>
            </LayoutFlexRow>
            <div style="margin-top: 16px; margin-bottom: 16px; border-bottom: 1px solid #ccc;"></div>
        </div>
        <div class="contentTab">
            <div class="container">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BTabContent',
    props: {
        loading: {
            type: [Boolean],
            default: false,
        },
        title: {
            type: [String],
        },
    },
}
</script>

<style scoped>
.tab {
    float: right;
    padding: 16px;
    padding-top: 22px;
    padding-right: 32px;
    width: 85%;
    min-height: 400px;
    display: block;
    background-color: #fff;
}

.content {
    font-size: 14px;
    margin-bottom: 30px;
}
.container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
}
</style>
