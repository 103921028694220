var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BTabContent',{attrs:{"loading":_vm.licenseActivationQuery.isLoading,"title":'Activations ' + _vm.activationNumber},scopedSlots:_vm._u([{key:"header-right",fn:function(){return [(_vm.canActivate)?_c('button',{staticClass:"button is-primary",on:{"click":function($event){return _vm.$router.push({
                    name: 'licenseActivationAdd',
                    params: { licenseId: _vm.$route.params.licenseId },
                })}}},[_vm._v(" Activate ")]):_vm._e()]},proxy:true}])},[_c('BListview',{ref:"listview",attrs:{"hideHeaderSection":true,"disableSearchByKeyword":true,"QueryAPIName":"LicenseActivationsGet","QueryParams":_vm.params,"DeleteAPIName":"LicenseActivationsRemove"},on:{"onPageSizeChange":function (value) { _vm.params.pageSize = value; },"onPageChange":function (value) { _vm.params.currentPage = value; }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var result = ref.result;
return [_c('b-table',{attrs:{"data":result,"backend-pagination":"","backend-sorting":"","current-page":_vm.params.currentPage,"per-page":_vm.params.pageSize,"default-sort-direction":_vm.params.sortDirection,"default-sort":_vm.params.sortColumn},on:{"sort":function (field, order) {
                        _vm.params.sortColumn = field
                        _vm.params.sortDirection = order
                    }}},[[_c('b-table-column',{attrs:{"field":"Name","label":"Hardware Id","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.HardwareId)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"field":"ChildCount","label":"Activated Date","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm.format_date(props.row.ActivatedDate))+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"label":"Download","centered":"","width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.Download(props.row.Id, props.row.HardwareId)}}},[_c('b-icon',{attrs:{"icon":"arrow-down-bold-circle-outline"}})],1)]}}],null,true)}),_c('b-table-column',{attrs:{"label":"","centered":"","width":"30px"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.ConfirmDelete(props.row)}}},[_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"close"}})],1)]}}],null,true)})],_c('template',{slot:"empty"},[_c('BEmptyState',{attrs:{"title":"No activations","subtitle":"Click 'Activate' to create new one"}})],1)],2)]}},{key:"loadingState",fn:function(ref){
                    var loadingRows = ref.loadingRows;
return [_c('b-table',{attrs:{"data":loadingRows}},[_c('b-table-column',{attrs:{"label":"Hardware Id"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1),_c('b-table-column',{attrs:{"label":"Activated Date"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1),_c('b-table-column',{attrs:{"label":"Download"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1),_c('b-table-column',{attrs:{"field":"control"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }