<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

                <!-- Create -->
                <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link :to="{ name: 'licenseList' }" exact
                                ><span class="item-link">License</span></router-link
                            >
                        </li>
                        <li>
                            <router-link
                                :to="{ name: 'licenseDetail', params: { licenseId: $route.params.licenseId, tabs: 0 } }"
                                exact
                                ><span class="item-link"
                                    >{{ itemDetail.Product.Name }} v{{ itemDetail.ProductVersion.Name }}</span
                                ></router-link
                            >
                        </li>
                        <li class="is-active"><a href="#" aria-current="page">Add Activation</a></li>
                    </ul>
                </nav>
                <div class="card mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">
                            Add Activation of {{ itemDetail.Product.Name }} v{{ itemDetail.ProductVersion.Name }}
                        </div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Hardware Id*"
                            :type="{ 'is-danger': $v.input.HardwareId.$error }"
                            :message="{ 'Hardware Id': $v.input.HardwareId.$error }"
                        >
                            <b-input
                                name="name"
                                v-model="input.HardwareId"
                                placeholder="Hardware Id"
                                expanded
                            ></b-input>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button class="button has-border-transparent ml-12" @click="$router.go(-1)">
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Create</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ManageCusGroupCreateSubGroup',
    props: {
        msg: String,
        parentGroupId: String,
    },
    data() {
        return {
            itemDetail: { Product: '', ProductVersion: '' },
            input: {
                HardwareId: '',
            },
            licenseQuery: new Query(this.$http, 'LicensesGet', {
                Ids: this.$route.params.licenseId,
            }),
            addActivationCommand: new Command(this.$http, 'POST', 'LicenseActivationAdd'),
        }
    },
    validations: {
        input: {
            HardwareId: {
                required,
            },
        },
    },
    created() {
        //console.log(this.$route)
        this.getItem()
    },
    computed: {
        isLoading() {
            return this.licenseQuery.isLoading || this.addActivationCommand.isLoading
        },
    },
    methods: {
        getItem() {
            this.licenseQuery.execute().then(() => {
                this.itemDetail = this.licenseQuery.firstRow
            })
        },
        createItem() {
            this.addActivationCommand
                .execute({ LicenseId: this.$route.params.licenseId, HardwareId: this.input.HardwareId })
                .then((resp) => {
                    if (resp.status === 201) {
                        this.$router.push({
                            name: 'licenseDetail',
                            params: { tabId: 1, licenseId: this.$route.params.licenseId },
                        })
                    }
                })
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.createItem()
        },
    },
}
</script>
