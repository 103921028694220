<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">Machine by Model</p>
        </header>

        <div class="card-content bg">
            <div class="content level">    
                <div class="level-item">                                 
                    <apexchart type=pie :width="380" :height="250" :options="chartOptions" :series="series" />                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Query from '@/libs/Query'

export default {
    name: 'MachineByModel',
    props: {
        msg: String
    },
    data() {
        return {
            machineByModelQuery: new Query(this.$http, 'MetricMachineSummaryByModelQuery', { sortColumn: 'Count' }),
            // values: [
            //     { model: 'FLO2-16R', customerCount: 5 },
            //     { model: 'SOLO', customerCount: 7 },
            //     { model: 'TOPFLO', customerCount: 2 }
            // ],

            //series: [5, 7, 2],
            series: [],
            chartOptions: {
                //labels: ['FLO2-16R', 'SOLO', 'TOPFLO'],
                labels: [],
                legend:{
                    horizontalAlign: 'left', 
                },
                responsive: [
                    {
                        breakpoint: 1240,
                        options: {
                            chart: {
                                width: 300
                            },
                            legend: {
                                position: 'bottom',
                                horizontalAlign: 'center',
                            }
                        }
                    },
                    {
                        breakpoint: 1090,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom',
                                horizontalAlign: 'center',
                            }
                        }
                    }
                ]
            }
        }
    },
    created() {        
        this.machineByModelQuery.execute().then(() => {
            //console.log(this.machineByModelQuery.Rows)
            this.machineByModelQuery.Rows.forEach(item => {
                this.chartOptions.labels.push(item.Model.Name)
                this.series.push(item.Count)
            });
        })
    },
    methods: {
       
    }
}
</script>

<style scoped>
    .card{
        min-height: 316px;
    }
</style>