<template>
    <BDetailView
        :loading="isLoading"
        @backButtonClick="$router.push({ name: 'machineList' })"
        prevPathName="Machine"
        @prevPathClick="$router.push({ name: 'machineList' })"
        :currentPathName="machineQuery.firstRow.SerialNumber"
        typeName="Machine"
        :viewName="machineQuery.firstRow.SerialNumber"
        :tabItems="tabItems"
        :actionPermission="actionPermission"
        :actionItems="actionItems"
    />
</template>

<script>

import Command from '@/libs/Command'
import Query from '@/libs/Query'
import DetailTab from '@/views/Manage/Machine/DetailTabs/DetailTab.vue'
import CustomerTab from '@/views/Manage/Machine/DetailTabs/CustomerTab.vue'
import LicenseTab from '@/views/Manage/Machine/DetailTabs/LicenseTab.vue'
import WarrantyTab from '@/views/Manage/Machine/DetailTabs/WarrantyTab.vue'
import AuditLogTab from '@/views/Manage/Machine/DetailTabs/AuditLogTab.vue'

export default {
    name: 'ManageMachineDetail',
    props: {
        customerId: {
            type: [String]
        },
        tabId: {
            type: [String, Number],
            default: 0
        }
    },
    components: {
        DetailTab
    },
    data() {
        return {
            tabItems: [
                { component: DetailTab, label: 'Details' },
                { component: CustomerTab, label: 'Customer' },
                { component: LicenseTab, label: 'License' },
                { component: WarrantyTab, label: 'Warranty' },
                { component: AuditLogTab, label: 'Audit Log' }
            ],
            actionPermission: {
                resources: 'Machine',
                any: true,
                actions: ['Edit', 'Delete']
            },
            actionItems: [
                {
                    label: 'Edit',
                    do: () =>
                        this.$router.push({
                            name: 'machineEdit',
                            params: { machineId: this.$route.params.machineId }
                        }),
                    permission: { resources: 'Machine', actions: ['Edit'] }
                },
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(),
                    permission: { resources: 'Machine', actions: ['Delete'] }
                }
            ],
            machineQuery: new Query(this.$http, 'MachinesGet', {
                Ids: this.$route.params.machineId
            }),
            deleteMachineCommand: new Command(this.$http, 'DELETE', 'MachinesRemove')
        }
    },
    created() {
        this.machineQuery.execute()
    },
    computed: {
        isLoading() {
            return this.machineQuery.isLoading || this.deleteMachineCommand.isLoading
        }
    },
    methods: {
        confirmDelete() {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: "Are you sure you want to delete this Machine: <b>'" + this.machineQuery.firstRow.SerialNumber + "'</b>?",
                confirmText: 'Delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteCustomerCommand.execute({ Ids: this.$route.params.machineId }).then(resp => {
                        if (resp.status === 204) {
                            this.$router.push({ name: 'machineList' })
                        }
                    })
                }
            })
        }
    }
}
</script>