<template>
    <div id="login" class="h-100">
        <div class="content-container-login">
            <div class="login-container">
                <div class="card box">
                    <div class="card-content login-card">
                        <h1 class="has-text-centered login-title">
                            <img src="/logo.png" alt="Coloriance Server" class="login-logo" />
                            &nbsp;Coloriance Admin
                        </h1>
                        <b-loading
                            :is-full-page="isFullPage"
                            :active.sync="isLoading"
                            :can-cancel="falseFlag"
                        ></b-loading>
                        <b-field label="Username" :type="checkUsername.type" :message="checkUsername.message">
                            <b-input
                                name="username"
                                v-model="input.username"
                                type="text"
                                tabindex="1"
                                @keyup.enter.native="login"
                                expanded
                            ></b-input>
                        </b-field>
                        <b-field label="Password" :type="checkPassword.type" :message="checkPassword.message">
                            <b-input
                                name="password"
                                v-model="input.password"
                                type="password"
                                tabindex="2"
                                @keyup.enter.native="login"
                                expanded
                            ></b-input>
                        </b-field>
                        <div class="field login-remember">
                            <!-- <b-checkbox :value="falseFlag" type="is-danger" tabindex="4">
                                Remember me
                            </b-checkbox> -->
                        </div>
                        <button class="button is-fullwidth is-info mb-8" @click="login" type="submit" tabindex="3">
                            Sign in
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="incorrect" class="has-text-centered has-text-danger mt-8">
                Username or Password is incorrect. Please try again!
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    props: {
        msg: String,
    },
    data() {
        return {
            trueFlag: true,
            falseFlag: false,
            incorrect: false,
            input: {
                username: '',
                password: '',
            },
            checkUsername: {
                type: '',
                message: '',
            },
            checkPassword: {
                type: '',
                message: '',
            },
            isLoading: false,
            isFullPage: true,
        }
    },
    methods: {
        checkInputUsername() {
            if (this.input.username) {
                this.checkUsername.type = ''
                this.checkUsername.message = ''
                return true
            }

            this.checkUsername.type = 'is-danger'
            this.checkUsername.message = 'Username is required'
            return false
        },
        checkInputPassword() {
            if (this.input.password) {
                this.checkPassword.type = ''
                this.checkPassword.message = ''
                return true
            }

            this.checkPassword.type = 'is-danger'
            this.checkPassword.message = 'Password is required'
            return false
        },
        checkForm() {
            if (this.checkInputUsername() && this.checkInputPassword()) {
                return true
            }

            return false
        },
        login() {
            let data = {
                username: this.input.username,
                password: this.input.password,
            }

            this.incorrect = false

            if (this.checkForm()) {
                this.isLoading = true

                this.$store
                    .dispatch('loginAsync', data)
                    .then(() => {
                        this.isLoading = false
                        this.$router.push({ name: 'dashboard' })
                    })
                    .catch(() => {
                        this.incorrect = true
                        this.isLoading = false
                    })
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-container {
    width: 400px;
}
.login-card {
    padding: 8px;
}
.login-title {
    margin-bottom: 32px;
    font-size: 30px;
}
.login-logo {
    width: 44px;
    vertical-align: middle;
}
.login-remember {
    margin: 40px 0 12px;
}
</style>
