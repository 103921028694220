<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view">
                <!-- Assign-->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-edit">
                        <div class="edit-title">Assign Customer to a Group</div>
                    </header>
                    <div class="card-content">
                        <div class="level m-control-1">
                            <div class="level-left">
                                <div class="i-relative">                                    
                                    <b-field>
                                        <BSearchBox
                                            ref="searchBox"
                                            class="level-item"
                                            :initValue="$path.params.keyword"
                                            placeholder="Search Name..."
                                            @submit="value => { $path.params.keyword = value; $path.push('search') }"
                                        />
                                    </b-field>
                                </div>
                            </div>
                            <div class="level-right">
                                <!--
                                <b-dropdown cusition="is-bottom-left" v-model="modeDisplay" @change="modeChage">
                                    <button class="button" slot="trigger">
                                        <span>{{ modeDisplay }}</span><b-icon icon="menu-down"></b-icon>
                                    </button>
                                    <b-dropdown-item :value="'List'">List</b-dropdown-item>
                                    <b-dropdown-item :value="'Card'">Card</b-dropdown-item>
                                </b-dropdown>
                                -->
                                <div class="level-right">
                                    <BPageSize
                                        class="level-item"
                                        v-model="$path.params.pageSize"
                                        :options="[10,20,50,100]"
                                        @change="$path.push('pageSize')"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="level m-control-1">
                            <div class="level-left"><div style="height:36px;"></div></div>
                            <div class="level-right">
                                <BPagingCounter
                                    class="level-item"
                                    :loading="customerGroupQuery.isLoading"
                                    :beginRow="customerGroupQuery.beginRow"
                                    :endRow="customerGroupQuery.endRow"
                                    :totalRows="customerGroupQuery.TotalRows"
                                />
                            </div>
                        </div>

                        <div style="margin-bottom: 8px; padding: 8px; font-size: bold; font-size: larger">
                            <span v-for="item in currentPath" :key="item.Id">{{ item.Name }} &gt;</span>
                        </div>

                        <div v-if="!isLoading" class="border-0" >

                            <button
                                v-if="groupLevel > 0"
                                class="button w-100 is-radiusless"
                                style="justify-content: flex-start;"
                                @click="browseBackGroup"
                                >
                                <b-icon icon="arrow-up"></b-icon>
                            </button>
                            <button
                                v-else
                                class="button w-100 is-radiusless"
                                style="justify-content: flex-start;"
                                disabled
                                >
                                <b-icon icon="arrow-down"></b-icon>
                            </button>
                            
                            <div v-if="customerGroupQuery.Rows.length > 0">
                                <button 
                                    v-for="item in customerGroupQuery.Rows" :key="item.Id"
                                    class="button w-100 is-radiusless" 
                                    style="justify-content: space-between; border-top: 0;"
                                    @click="browseGroup(item.Id, item.Path)"
                                    >
                                    <span class="item-link">{{ item.Name }}</span>
                                    <b-icon icon="chevron-right"></b-icon>
                                </button>
                            </div>
                            <div v-else>
                                <button 
                                    class="button w-100 is-radiusless" 
                                    style="justify-content: space-between;  border-top: 0;"
                                    disabled
                                    >
                                    <b-icon icon="contain-start"></b-icon>
                                    <span class="item-link">End Path</span>
                                    <b-icon icon="contain-end"></b-icon>
                                </button>
                            </div>

                        </div>
                        <div v-if="!isLoading" class="pagination-container">
                            <!-- <b-pagination
                                :total="totalItem"
                                :per-page="pageSize"
                                :current.sync="currentPage"
                                :simple="isSimple"
                                :rounded="isRounded"
                                @change="pageChange">
                            </b-pagination> -->
                            <BPagination
                                :total="customerGroupQuery.TotalRows"
                                :per-page="$path.params.pageSize"
                                :current="$path.params.currentPage"
                                @change="page => { $path.params.currentPage=page; $path.push('paging') }"
                            />
                        </div>
                    </div>
                    
                    <footer class="card-footer card-footer-edit">
                        <div class="level w-100">
                            <div class="level-left">
                                <router-link :to="{ name:'customerDetail', params:{ customerId: cusEditId, tabId: 1}}" exact>
                                    <button class="button has-border-transparent ml-12">Cancel</button>
                                </router-link>
                            </div>
                            <div class="level-right">
                                <button v-if="currentPath.length > 0" class="button is-info" @click="confirmAssignGroup">Assign Customer to a Group</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { pathParamsMixin } from '@/mixins'
import Query from '@/libs/Query'
import Command from '@/libs/Command'

export default {
    name: 'ManageCusAssignGroup',
    props: {
        'msg': String
    },
    mixins: [pathParamsMixin],
    pathParams: {
        params: {
            keyword: '',
            ParentId: '',
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'Name',
            sortDirection: 'asc',
            cache: true
        }
    },
    data() {
        return {
            cusEditId: this.$route.params.customerId,

            customerGroupQuery: new Query(this.$http, 'CustomerGroupsQuery', { keyword: '', ParentId:'' }),
            assignCustomerGroupCommand: new Command(this.$http, 'POST', 'CustomerGroupsOfCustomerAssign'),

            /* Table */
            items: [],
            currentPage: 1,
            pageSize: 10,
            sortDirection: 'Ascending',
            sortField: 'Name',
            sortOrder: 'asc',
            isSimple: false,
            isRounded: false,
            sequence: null,
            totalItem: 0,
            startItem: 0,
            endItem: 0,

            /* Tree */
            groupLevel: 0,
            currentId: null,
            currentPath: [],
            backId: null,
            backPath: [],
            /* Delete Item */
            deleteId: null,
            deleteName: [],

            /* Search */
            isExistKeyword: false,
            keyword: ''
        }
    }, 
    created() {
        this.customerGroupQuery.execute(this.$path.params).then(() => {
            this.$path.monitor(() => {
                this.customerGroupQuery.execute(this.$path.params)
            })
        })
    },
    computed: {
        isLoading() {
            return (
                this.customerGroupQuery.isLoading ||
                this.assignCustomerGroupCommand.isLoading
            )
        }
    },  
    methods: {        
        browseGroup(groupId, groupPath) {
            this.groupLevel = this.groupLevel + 1
            this.backId = this.currentId
            this.backPath = this.currentPath
            this.currentId = groupId
            this.currentPath = groupPath
            this.$path.params.ParentId = groupId
            this.$path.push('clickItem')
        },
        browseBackGroup() {
            this.groupLevel = this.groupLevel -1
            this.currentId = this.backId
            this.currentPath = this.backPath
            if(this.groupLevel <= 0) {
                this.currentId = null
                this.currentPath = []
            }
            this.$path.params.ParentId = this.backId
            this.$path.push('clickItem')
        },
        confirmAssignGroup() {
            let arrGroupId = []
            arrGroupId.push(this.currentId)
            let confirmMsg = `Are you sure you want to assign this customer?<br/><br/>`

            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: confirmMsg,
                confirmText: 'Assign',
                type: 'is-info',
                hasIcon: true,
                onConfirm: () => {
                    this.assignCustomerGroupCommand.execute({
                        CustomerId: this.cusEditId,
                        GroupIds: arrGroupId
                    }).then(resp => {
                        if (resp.status === 201) {
                            this.$router.push({ name: 'customerDetail', params:{ customerId: this.cusEditId, tabId: 1 } })
                        }
                    })
                }
            })
        }
    }
}
</script>


