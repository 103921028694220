export default class Query {
    // States
    //
    get isLoading() {
        return this.__isLoading
    }

    get isLoaded() {
        return this.__isLoaded
    }

    constructor(
        http,
        queryOpName,
        params = {},
        //paramExtractFn = null,
        currentPage = 1,
        pageSize = 10,
        sortColumn = 'Name',
        sortDirection = 'asc'
    ) {
        this.__isLoading = false
        this.__isLoaded = false

        // Request Params
        //
        this.Sequence = 1

        this.__http = http
        this.__queryOpName = queryOpName

        this.params = params
        // this.__paramExtractFn = paramExtractFn

        this.currentPage = currentPage
        this.pageSize = pageSize
        this.sortColumn = sortColumn
        this.sortDirection = sortDirection

        if (this.currentPage < 1) this.currentPage = 1
        //if (this.pageSize < 1) this.pageSize = 10

        // Response Values
        //
        this.TotalRows = 0
        this.Rows = []
    }

    clearResponse() {
        this.TotalRows = 0
        this.Rows = []
    }

    get hasRow() {
        return this.TotalRows > 0
    }
    get firstRow() {
        return this.TotalRows > 0 ? this.Rows[0] : {}
    }

    get beginRow() {
        return (this.currentPage - 1) * this.pageSize + 1
    }
    get endRow() {
        return this.beginRow - 1 + this.Rows.length
    }

    execute(mergeParams = null) {
        //console.log('mergeParams: ', mergeParams.keyword)
        // Assign mergeParams
        //
        if (mergeParams) {
            for (let p in mergeParams) {
                //if (this.params && this.params[p] != undefined)  if not found key in params then create
                    this.params[p] = mergeParams[p]

                if (this[p]) 
                    this[p] = mergeParams[p]
            }
        }

        let pagingParams = {
            sequence: this.Sequence++,
            startRow: (this.currentPage - 1) * this.pageSize,
            pageSize: this.pageSize,
            sortColumn: this.sortColumn,
            sortDirection: this.sortDirection == 'asc' ? 0 : 1,
        }

        // console.log('params: ', this.params)
        // console.log('pagingParams: ', pagingParams)

        //let extParam = this.__paramExtractFn ? this.__paramExtractFn(this.params) : this.params
        const { pageSize, sortColumn, sortDirection, ...customParams } = this.params
        // console.log('extParam: ', extParam)

        let reqParams = Object.assign(pagingParams, customParams)

        //console.log('reqParams: ', reqParams.keyword)
        this.__isLoading = true

        let pm = this.__http({
            url: '/json/reply/' + this.__queryOpName,
            params: reqParams,
        })
            .then((resp) => {
                // console.log('resp: ', resp)
                this.TotalRows = resp.data.TotalRows
                this.Rows = resp.data.Rows
                // console.log('this: ', this)
            })
            .finally(() => {
                this.__isLoading = false
                this.__isLoaded = true
            })

        return pm
    }
}
