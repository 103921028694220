<template>
  <BTabContent :loading="myAccountGet.isLoading">
    <div v-if="details">
        <div class="columns">
            <div class="column">
                <!--
                <div class="detail-item-title">Enabled</div>
                <div class="detail-item-content">&nbsp;</div>
                -->
                <div class="detail-item-title">Username</div>
                <div class="detail-item-content">{{ details.UserName }}&nbsp;</div>
                <div class="detail-item-title">Full Name</div>
                <div class="detail-item-content">{{ details.FullName }}&nbsp;</div>
                <div class="detail-item-title">Gender</div>
                <div class="detail-item-content">{{ details.Gender }}&nbsp;</div>
                <div class="detail-item-title">Date of Birth</div>
                <div class="detail-item-content">{{ details.DateOfBirth }}&nbsp;</div>
                <div class="detail-item-title">Phone Number</div>
                <div class="detail-item-content">{{ details.PhoneNumber }}&nbsp;</div>
            <!-- </div>
            <div class="column"> -->
                <div class="detail-item-title">Job Title</div>
                <div class="detail-item-content">{{ details.Job }}&nbsp;</div>
                <div class="detail-item-title">Address</div>
                <div class="detail-item-content">{{ details.Address }}&nbsp;</div>
                <div class="detail-item-title">Country</div>
                <div class="detail-item-content">{{ details.Country }}&nbsp;</div>
                <div class="detail-item-title">Postal Code</div>
                <div class="detail-item-content">{{ details.PostalCode }}&nbsp;</div>                
                <div class="detail-item-title">Email</div>
                <div class="detail-item-content">{{ details.Email }}&nbsp;</div>
            </div>
        </div>
    </div>
    <div v-else>
        <!-- <p>Details not found</p> -->
    </div>
  </BTabContent>
</template>

<script>
import Get from '@/libs/Get'

export default {
  props: {
    msg: String
  },
  data() {    
    return {
      myAccountGet: new Get(this.$http, 'MyAccountGet', {})
    }
  },
  created() {        
      this.myAccountGet.execute()
  },
  computed: {
      details() {
          return this.myAccountGet.Data
      }
  }
};
</script>