<template>
  <BTabContent :loading="customerGroupQuery.isLoading">
        <div v-if="details">
            <div class="detail-item-title">Name</div>
            <div class="detail-item-content">{{ details.Name }}&nbsp;</div>  
            <!-- <div class="detail-item-title">No. of Sub-Group</div>
            <div class="detail-item-content">
                <b-tooltip :label="details.ChildStringify" position="is-right" type="is-light">
                    {{ details.ChildCount }}
                </b-tooltip>    
                &nbsp;
            </div> -->
            <div class="detail-item-title">No. of Customer</div>
            <div class="detail-item-content">
                <b-tooltip :label="details.CustomerStringify" position="is-right" type="is-light">
                    {{ details.CustomerCount }}
                </b-tooltip>  
                &nbsp;
            </div>
        </div>
        <div v-else>
            <!-- <p>Details not found</p> -->
        </div>
    </BTabContent>
</template>

<script>
import { pathParamsMixin } from '@/mixins'
import Query from '@/libs/Query'

export default {
  props: {
    msg: String
  },
  mixins: [pathParamsMixin],
  pathParams: {
        params: {
        }
    },
  data() {
    return {
        customerGroupQuery: new Query(this.$http, 'CustomerGroupsGet', { Ids: this.$route.params.customerGroupId })
    }
  },
  created() {        
      this.customerGroupQuery.execute().then(() => {
          this.$path.monitor(() => {
              this.customerGroupQuery.execute({ Ids: this.$route.params.customerGroupId })
          })
      })
  },
  computed: {
      details() {
          return this.customerGroupQuery.firstRow
      }
  }
};
</script>