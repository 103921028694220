<template>
    <BDetailView
        :loading="isLoading"
        @backButtonClick="$router.push({ name: backLinkTo })"
        prevPathName="License"
        @prevPathClick="$router.push({ name: backLinkTo })"
        :currentPathName="ItemDetail.Product.Name"
        typeName="License"
        :viewName="ItemDetail.Product.Name"
        :subViewName="ItemDetail.ActivationKey"
        :tabItems="tabItems"
        :actionPermission="actionPermission"
        :actionItems="actionItems"
    />
</template>

<script>

import Command from '@/libs/Command'
import Query from '@/libs/Query'
import DetailTab from '@/views/Manage/License/DetailTabs/DetailTab.vue'
import ActivatoionTab from '@/views/Manage/License/DetailTabs/ActivationTab.vue'

export default {
    name: 'ManageLicenseDetail',
    props: {
        licenseId: {
            type: [String]
        },
        tabId: {
            type: [String, Number],
            default: 0
        }
    },
    components: {
        DetailTab,
        ActivatoionTab
    },
    data() {
        return {
            tabItems: [
                { component: DetailTab, label: 'Details' },
                { component: ActivatoionTab, label: 'Activations' },
            ],
            actionPermission: {
                resources: 'License',
                any: true,
                actions: ['Edit', 'Delete']
            },
            actionItems: [
                {
                    label: 'Edit',
                    do: () =>
                        this.$router.push({
                            name: 'licenseEdit',
                            params: { licenseId: this.$route.params.licenseId }
                        }),
                    permission: { resources: 'License', actions: ['Edit'] }
                },
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(),
                    permission: { resources: 'License', actions: ['Delete'] }
                }
            ],
            licenseQuery: new Query(this.$http, 'LicensesGet', {
                Ids: this.$route.params.licenseId
            }),
            deleteLicenseCommand: new Command(this.$http, 'DELETE', 'LicensesRemove'),
            isLoad: false
        }
    },
    async created() {
        this.isLoad = true
        this.licenseQuery.Rows = [{ Product:'', Customer: '', ProductVersion: "" }]
        await this.$http({
            url: '/json/reply/SupportedWarrantyStatusesGet ',
        })
        .then((resp) => {
            let defaultItem = { Id: '', Name: 'All Status' }
            let dataArray = []
            resp.data.forEach((element) => {
                dataArray.push({ Id: element, Name: element })
            })

            this.statusOptions = dataArray
            this.statusOptions.unshift(defaultItem)
            this.filterStatus = defaultItem
        })
        .finally(() => {
            this.isLoad = false
        })

        await this.licenseQuery.execute()
    },
    computed: {
        isLoading() {
            return this.licenseQuery.isLoading || this.deleteLicenseCommand.isLoading || this.isLoad
        },
        ItemDetail() {
            if(this.licenseQuery.hasRow)
                return this.licenseQuery.firstRow
            else
                return { Product:'', Customer: '', ProductVersion: "" }
        },
        backLinkTo() {
            if(this.licenseQuery.hasRow){
                if(this.licenseQuery.firstRow.ActivationMethod == "Machine")
                    return "licenseListMachine"
                else
                    return "licenseListServer"
            }
            else
                return "licenseListMachine"
        }
    },
    methods: {
        confirmDelete() {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: "Are you sure you want to delete this License: <b>'" + this.licenseQuery.firstRow.Name + "'</b>?",
                confirmText: 'Delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteLicenseCommand.execute({ Ids: this.$route.params.licenseId }).then(resp => {
                        if (resp.status === 204) {
                            this.$router.push({ name: 'licenseList' })
                        }
                    })
                }
            })
        }
    }
}
</script>