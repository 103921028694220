<template>
  <BTabContent :loading="assignedRoleOfUserQuery.isLoading">
    <div class="detail-item-title">Assigned Roles</div>
    <div class="detail-item-content">
        <div class="i-relative">                
            <b-field>
                <b-autocomplete
                    v-model="unassignRoleName"
                    :data="unassignRoleQuery.Rows"
                    :loading="unassignRoleQuery.isLoading"
                    :keep-first="true"
                    :clear-on-select="true"
                    :open-on-focus="true"
                    placeholder="e.g. Rolename"
                    icon="magnify"
                    field="Name"
                    @focus="getUnassignedRole(unassignRoleName)"
                    @typing="value => getUnassignedRole(value)"
                    @input="null"
                    @select="value => assignRole(value)">
                    
                </b-autocomplete>
            </b-field>
        </div>    
        <ul class="list mt-32 list-assigned">
            <li class="list-item" v-for=" (item, index) in assignedRoleOfUserQuery.Rows " :key="index">
                <div class="item-assigned">
                    <span>{{ item.Name }}</span>
                    <div class="item-assigned-remove" @click="confirmUnassign(item)">
                        <b-tooltip label="Unassign" position="is-right" type="is-light">
                            <b-icon icon="close"></b-icon>
                        </b-tooltip>
                    </div>
                </div>
            </li>
        </ul>
    </div>
  </BTabContent>
</template>

<script>
import Query from '@/libs/Query'
import Command from '@/libs/Command'

export default {
  props: {
    msg: String
  },
  data() {    
    return {
        unassignRoleName: '',
        unassignRoleQuery: new Query(
            this.$http,
            'RolesQuery',
            { Keyword: '' },
            1,
            0,
            'Name'
        ),
        assignedRoleOfUserQuery: new Query(this.$http, 'RolesOfUserGet', {
            UserId: this.$route.params.userId
        }),
        assignRoleCommand: new Command(this.$http, 'POST', 'RolesOfUserAssign'),
        unassignRoleCommand: new Command(this.$http, 'DELETE', 'RolesOfUserUnassign')
    }
  },
  created() {        
      this.assignedRoleOfUserQuery.execute()
  },
  methods: {
        getUnassignedRole(value) {
            this.unassignRoleQuery.execute({
                Keyword: value
            })
        },
        assignRole(option) {
            let object = null

            if(option) {
                object = this.assignedRoleOfUserQuery.Rows.filter(obj => obj.Id === option.Id)
                
                if(object.length === 0) {
                    // this.itemRoles.push(option)
                    // this.input.roleIds.push(option.Id)

                    this.assignRoleCommand.execute({ UserId: this.$route.params.userId, RoleIds: option.Id }).then(resp => {
                        if (resp.status === 201) {
                            this.assignedRoleOfUserQuery.execute({ UserId: this.$route.params.userId })
                        }
                    })
                }
            }
        },
        confirmUnassign(item) {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message:
                    "Are you sure you want to unassign from group: <b>'" + item.Name + "'</b>?",
                confirmText: 'Unassign',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.unassignRoleCommand.execute({ UserId: this.$route.params.userId, RoleIds: item.Id }).then(resp => {
                        if (resp.status === 204) {
                            this.assignedRoleOfUserQuery.execute()
                        }
                    })
                }
            })
        }
    }
};
</script>