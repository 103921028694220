<template>
    <BTabContent>
        <div class="detail-item-content">

            <BListview 
            pageTitle="Version"
            searchPlaceholder="Search version..."
            QueryAPIName="ProductVersionsOfProductQuery"
            :QueryParams="params"
            DeleteAPIName="ProductVersionsRemove"

            @onKeywordSearch="(value) => { params.keyword = value; }"
            @onPageSizeChange="(value) => { params.pageSize = value; }"
            @onPageChange="(value) => { params.currentPage = value; }"
            @onDeleted="(value) => { if(value) { selectedRows = []; } }"
            ref="listview">

                <template v-slot:actionButton>
                    <BActionButton
                        v-if="selectedRows.length > 0"
                        :actionPermission="actionPermission"
                        :actionItems="actionItems"
                        :selectedRows="selectedRows"
                    />
                    <button
                        v-else
                        class="button is-info"
                        @click="$router.push({ name: 'versionCreate', params: { productId: $route.params.productId } })"
                    >
                        Create new version
                    </button>
                </template>

                <template v-slot:default="{ result }" >
                    <b-table
                        :data="result"
                        backend-pagination
                        backend-sorting
                        checkable
                        :checked-rows.sync="selectedRows"
                        :current-page="params.currentPage"
                        :per-page="params.pageSize"
                        :default-sort-direction="params.sortDirection"
                        :default-sort="params.sortColumn"
                        @sort="
                            (field, order) => {
                                params.sortColumn = field
                                params.sortDirection = order
                            }
                        "
                        truncate-column
                    >
                        <b-table-column field="Name" label="Version Name" sortable v-slot="props">
                            {{ props.row.Name }}
                        </b-table-column>

                        <b-table-column field="control" centered v-slot="props">
                            <b-dropdown
                                position="is-bottom-left"
                                v-permit="{ resources: 'Product', any: true, actions: ['Edit', 'Delete'] }"
                                v-if="props.row.Status !== 'Expired' && props.row.Status !== 'Cancelled'"
                            >
                                <button class="button border-0 btn-control" slot="trigger">
                                    <b-icon icon="dots-horizontal" size="is-small"></b-icon>
                                </button>
                                <b-dropdown-item has-link v-permit="{ resources: 'Product', actions: ['Edit'] }">
                                    <router-link
                                        :to="{
                                            name: 'versionEdit',
                                            params: { productId: $route.params.productId, versionId: props.row.Id },
                                        }"
                                        exact
                                        >Edit</router-link
                                    >
                                </b-dropdown-item>
                                <b-dropdown-item
                                    @click="confirmDelete([props.row])"
                                    v-permit="{ resources: 'Product', actions: ['Delete'] }"
                                    >Delete</b-dropdown-item
                                >
                            </b-dropdown>
                        </b-table-column>

                        <template slot="empty">
                            <BEmptyState title="No mached result" subtitle="Please try another criteria" />
                        </template>
                    </b-table>
                </template>

                <template v-slot:loadingState="{ loadingRows }">
                    <b-table :data="loadingRows">
                        <b-table-column label="Version Name">
                            <b-skeleton height="35px" :animated="true"></b-skeleton>
                        </b-table-column>

                        <b-table-column field="control">
                            <b-skeleton height="35px" :animated="true"></b-skeleton>
                        </b-table-column>                   
                    </b-table>
                </template>


            </BListview>

        </div>
    </BTabContent>
</template>

<script>
import BListview from '@/components/common/BListview'

export default {
    props: {
        msg: String,
    },
    components: {
        BListview
    },
    url: {
        __root: 'params',

        keyword: { key: 'kw' },
        ProductId: { key: 'pid' },
        currentPage: { key: 'page' },
        pageSize: { key: 'size' },
        sortColumn: { key: 'col' },
        sortDirection: { key: 'dir' },
    },
    data() {
        const params = {
            keyword: '',
            ProductId: this.$route.params.productId,
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'Name',
            sortDirection: 'asc'
        }

        return {
            params,

            selectedRows: [],

            actionPermission: {
                resources: 'Product',
                any: true,
                actions: ['Delete'],
            },
            actionItems: [
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(this.selectedRows),
                    permission: { resources: 'Product', actions: ['Delete'] },
                },
            ],
        }
    },

    methods: {
        confirmDelete(selectedRow) {
            let formattedItemsName = selectedRow.map((p) => `<b>${p.Name}</b>`).join('<br/>')
            let nPos = selectedRow.length > 1 ? selectedRow.length : ''
            let confirmMsg = `Are you sure you want to delete the following ${nPos} versions?<br/><br/>`
            let ItemsId = selectedRow.map((p) => p.Id)

            this.$refs.listview.confirmDelete(ItemsId, confirmMsg + formattedItemsName)
        },
    },
    mounted() {
        this.$refs.listview.reload()
        this.$refs.listview.startWatch()
    }
}
</script>

<style></style>
