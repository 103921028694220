import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'

import router from './router'
import store from './store'
import VueSync from './libs/vueSync'

import VueLayoutSystem from './layouts'
import { Fragment } from './layouts'

import client from '@/libs/api-client'
import 'lato-font/css/lato-font.css'
import 'material-design-icons/iconfont/material-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'

import CommonComponent from '@/components/common'
import App from './App.vue'
import Buefy from 'buefy'
import '@/assets/animate.min.css'
import '@/assets/app.css'
import '@/assets/_style.scss'
import '@creativebulma/bulma-badge/dist/bulma-badge.min.css'

import VCalendar from 'v-calendar'
import numFormat from 'vue-filter-number-format'
import Vue2Filters from 'vue2-filters'
import VueBus from 'vue-bus'
import { NotificationProgrammatic as Notification } from 'buefy/dist/components/notification'
import { VPermission } from '@/directives/v-permission'
import { VPermit } from '@/directives/v-permit'
import VueSkeletonLoading from '@/custom_modules/vue-skeleton-loading'
import VueQriously from 'vue-qriously'
import Vuelidate from 'vuelidate'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'
import VuePluralize from 'vue-pluralize'

Vue.config.productionTip = false
// Vue.config.performance = true

Vue.use(VueCompositionApi)
Vue.use(VueLayoutSystem)
Vue.use(Fragment)

Vue.use(VueSync)
Vue.use(Buefy)
Vue.use(VCalendar)

Vue.filter('numFormat', numFormat)
Vue.use(Vue2Filters)
Vue.use(VueBus)
Vue.directive('permission', VPermission)
Vue.directive('permit', VPermit)
Vue.use(VueSkeletonLoading)
Vue.use(CommonComponent)
Vue.use(VueQriously)
Vue.use(Vuelidate)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.filter('formatOnlyDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
    }
})
Vue.use(VuePluralize)

Vue.prototype.$http = client

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
