var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BTabContent',[_c('div',{staticClass:"detail-item-content"},[_c('BListview',{ref:"listview",attrs:{"pageTitle":"Version","searchPlaceholder":"Search version...","QueryAPIName":"ProductVersionsOfProductQuery","QueryParams":_vm.params,"DeleteAPIName":"ProductVersionsRemove"},on:{"onKeywordSearch":function (value) { _vm.params.keyword = value; },"onPageSizeChange":function (value) { _vm.params.pageSize = value; },"onPageChange":function (value) { _vm.params.currentPage = value; },"onDeleted":function (value) { if(value) { _vm.selectedRows = []; } }},scopedSlots:_vm._u([{key:"actionButton",fn:function(){return [(_vm.selectedRows.length > 0)?_c('BActionButton',{attrs:{"actionPermission":_vm.actionPermission,"actionItems":_vm.actionItems,"selectedRows":_vm.selectedRows}}):_c('button',{staticClass:"button is-info",on:{"click":function($event){return _vm.$router.push({ name: 'versionCreate', params: { productId: _vm.$route.params.productId } })}}},[_vm._v(" Create new version ")])]},proxy:true},{key:"default",fn:function(ref){
var result = ref.result;
return [_c('b-table',{attrs:{"data":result,"backend-pagination":"","backend-sorting":"","checkable":"","checked-rows":_vm.selectedRows,"current-page":_vm.params.currentPage,"per-page":_vm.params.pageSize,"default-sort-direction":_vm.params.sortDirection,"default-sort":_vm.params.sortColumn,"truncate-column":""},on:{"update:checkedRows":function($event){_vm.selectedRows=$event},"update:checked-rows":function($event){_vm.selectedRows=$event},"sort":function (field, order) {
                            _vm.params.sortColumn = field
                            _vm.params.sortDirection = order
                        }}},[_c('b-table-column',{attrs:{"field":"Name","label":"Version Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.Name)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"field":"control","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.Status !== 'Expired' && props.row.Status !== 'Cancelled')?_c('b-dropdown',{directives:[{name:"permit",rawName:"v-permit",value:({ resources: 'Product', any: true, actions: ['Edit', 'Delete'] }),expression:"{ resources: 'Product', any: true, actions: ['Edit', 'Delete'] }"}],attrs:{"position":"is-bottom-left"}},[_c('button',{staticClass:"button border-0 btn-control",attrs:{"slot":"trigger"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-small"}})],1),_c('b-dropdown-item',{directives:[{name:"permit",rawName:"v-permit",value:({ resources: 'Product', actions: ['Edit'] }),expression:"{ resources: 'Product', actions: ['Edit'] }"}],attrs:{"has-link":""}},[_c('router-link',{attrs:{"to":{
                                        name: 'versionEdit',
                                        params: { productId: _vm.$route.params.productId, versionId: props.row.Id },
                                    },"exact":""}},[_vm._v("Edit")])],1),_c('b-dropdown-item',{directives:[{name:"permit",rawName:"v-permit",value:({ resources: 'Product', actions: ['Delete'] }),expression:"{ resources: 'Product', actions: ['Delete'] }"}],on:{"click":function($event){return _vm.confirmDelete([props.row])}}},[_vm._v("Delete")])],1):_vm._e()]}}],null,true)}),_c('template',{slot:"empty"},[_c('BEmptyState',{attrs:{"title":"No mached result","subtitle":"Please try another criteria"}})],1)],2)]}},{key:"loadingState",fn:function(ref){
                                    var loadingRows = ref.loadingRows;
return [_c('b-table',{attrs:{"data":loadingRows}},[_c('b-table-column',{attrs:{"label":"Version Name"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1),_c('b-table-column',{attrs:{"field":"control"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }