<template>
    <ul class="menu">
        <li
            v-for="item in items"
            :key="item.name + 'parent'"
            v-permit="{
                resources: item.resources,
                any: true,
                actions: ['View', 'Create', 'Edit', 'Delete'],
            }"
            :class="{ active: item.is_active }"
        >
            <span v-if="item.is_active" class="menu-item" :class="{ 'has-text-weight-bold': item.is_active }">
                <span class="icon" :class="{ 'no-icon': !item.childs || item.childs.length == 0 }">
                    <i class="mdi mdi-24px mdi-menu-down"></i>
                </span>
                <span>{{ item.title }}</span>
            </span>
            <router-link v-else :to="{ name: item.routeName }" class="menu-item">
                <span class="icon" :class="{ 'no-icon': !item.childs || item.childs.length == 0 }">
                    <i class="mdi mdi-24px mdi-menu-right"></i>
                </span>
                <span>{{ item.title }}</span>
            </router-link>

            <!-- <transition name="slide" appear> -->
            <ul :key="item.name" v-show="item.is_active && item.childs && item.childs.length > 0" class="submenu">
                <li
                    v-for="child in item.childs"
                    :key="child.name"
                    v-permit="{
                        resources: child.resources,
                        any: true,
                        actions: ['View', 'Create', 'Edit', 'Delete'],
                    }"
                    :class="{ active: child.is_active }"
                >
                    <span
                        v-if="child.is_active"
                        class="submenu-item"
                        :class="{ 'has-text-weight-bold': child.is_active }"
                        >{{ child.title }}</span
                    >
                    <router-link v-else :to="{ name: child.routeName }" class="submenu-item">{{
                        child.title
                    }}</router-link>
                </li>
            </ul>
            <!-- </transition> -->
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default() {
                return [
                    {
                        name: 'Menu1',
                        title: 'Menu 1',
                        routeName: 'menu1',
                        resources: ['Menu1'],
                        childs: [],
                    },
                ]
            },
        },
    },
    watch: {
        $route(to, from) {
            // console.log("$route changed: ", to)
            let active_item = this.resolve_active_state(this.items)
            // console.log("menu resolved: ", active_item)
        },
    },
    created() {
        this.resolve_active_state(this.items)
    },
    methods: {
        resolve_active_state(items) {
            // Resolve which item is selected
            //
            let active_item = null

            items.forEach((item) => {
                // Find selected child
                let selected_child = null
                let childs = item.childs || []
                childs.forEach((child) => {
                    let is_active = child.routeName === this.$route.name
                    this.$set(child, 'is_active', is_active)
                    if (child.is_active) selected_child = child
                })

                // Set active by itself or it's child has selected
                let is_active = item.routeName === this.$route.name || selected_child ? true : false
                this.$set(item, 'is_active', is_active)
                if (item.is_active) active_item = item
            })

            return active_item
        },
    },
}
</script>

<style lang="scss" scoped>
* {
    --highlight-color: #e5e5e5;
}

a {
    color: #444;
}

.router-link-active {
    font-weight: 600;
}

.menu {
    display: block;

    & > li {
        min-height: 27px;
        // margin-bottom: 10px;
        // padding-top: 4px;
        border-left: 4px solid transparent;

        &.active {
            // color: var(--app-accent-color);
            border-left: 4px solid var(--app-accent-color);
        }

        &:not(.active):hover {
            border-left: 4px solid var(--highlight-color);
            background-color: var(--highlight-color);
        }

        .menu-item {
            display: flex;
            align-items: center;

            padding: 6px 0;
            padding-left: 12px;

            letter-spacing: 0.03em;

            .no-icon {
                color: transparent;
            }
        }
    }
}

.submenu {
    & > li {
        &.active {
            color: var(--app-accent-color);
        }

        &:not(.active):hover {
            background-color: var(--highlight-color);
        }

        .submenu-item {
            display: block;
            // min-height: 20px;
            padding: 6px 0;
            padding-left: 48px;

            letter-spacing: 0.02em;
        }
    }
}

// .slide-enter-active {
//     -moz-transition-duration: 0.3s;
//     -webkit-transition-duration: 0.3s;
//     -o-transition-duration: 0.3s;
//     transition-duration: 0.3s;
//     -moz-transition-timing-function: ease-in;
//     -webkit-transition-timing-function: ease-in;
//     -o-transition-timing-function: ease-in;
//     transition-timing-function: ease-in;
// }

// .slide-leave-active {
//     -moz-transition-duration: 0.3s;
//     -webkit-transition-duration: 0.3s;
//     -o-transition-duration: 0.3s;
//     transition-duration: 0.3s;
//     -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
//     -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
//     -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
//     transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
// }

// .slide-enter-to,
// .slide-leave {
//     max-height: 100px;
//     overflow: hidden;
// }

// .slide-enter,
// .slide-leave-to {
//     overflow: hidden;
//     max-height: 0;
// }
</style>
