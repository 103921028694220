<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view">
                <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

                <!-- Edit -->
                <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/product" exact><span class="item-link">Product</span></router-link>
                        </li>
                        <li>
                            <router-link :to="'/product/' + productId + '/tab/0'" exact
                                ><span class="item-link">{{ itemDetail.Name }}</span></router-link
                            >
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page">Edit {{ itemDetail.Name }}</a>
                        </li>
                    </ul>
                </nav>
                <div class="card mb-48">
                    <header class="card-header card-header-edit">
                        <div class="edit-title">Edit {{ itemDetail.Name }}</div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Product Name*"
                            :type="{ 'is-danger': $v.input.Name.$error }"
                            :message="{ 'Product Name': $v.input.Name.$error }"
                        >
                            <b-input
                                name="serialNumber"
                                v-model="input.Name"
                                ref="productSerialNumber"
                                placeholder="Product Name"
                                expanded
                            ></b-input>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-edit">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm">Update</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ManageProductEdit',
    props: {
        msg: String,
    },
    data() {
        return {
            /* Parameter */
            productId: this.$route.params.productId,
            productModels: [],

            input: {
                SerialNumber: '',
                Model: {},
                ManufacturedDate: new Date(),
            },
            // Detail Item
            itemDetail: {},

            // Command
            productQuery: new Query(this.$http, 'ProductsGet', { Ids: this.$route.params.productId }),
            editProductCommand: new Command(this.$http, 'PUT', 'ProductsUpdate'),
        }
    },
    validations: {
        input: {
            Name: {
                required,
            },
        },
    },
    created() {
        this.getItem()
    },
    computed: {
        isLoading() {
            return this.productQuery.isLoading || this.editProductCommand.isLoading
        },
    },
    methods: {
        getItem() {
            this.productQuery.execute().then(() => {
                this.itemDetail = this.productQuery.firstRow
                this.input.Name = this.itemDetail.Name
            })
        },
        updateItem() {
            this.editProductCommand
                .execute({
                    Products: [
                        {
                            Id: this.productId,
                            Name: this.input.Name,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$router.go(-1)
                    }
                })
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.updateItem()
        },
    },
}
</script>
