<template>
    <BDetailView
        :loading="isLoading"
        @backButtonClick="$router.push({ name: 'customerList' })"
        prevPathName="Customer"
        @prevPathClick="$router.push({ name: 'customerList' })"
        :currentPathName="customerQuery.firstRow.Name"
        typeName="Customer"
        :viewName="customerQuery.firstRow.Name"
        :tabItems="tabItems"
        :actionPermission="actionPermission"
        :actionItems="actionItems"
    />
</template>

<script>

import Command from '@/libs/Command'
import Query from '@/libs/Query'
import ManageCustomerDetail from '@/views/Manage/Customer/DetailTabs/CustomerDetailTab.vue'
import ManageCustomerGroup from '@/views/Manage/Customer/DetailTabs/CustomerGroupTab.vue'
import ManageCustomerMachine from '@/views/Manage/Customer/DetailTabs/CustomerMachineTab.vue'

export default {
    name: 'ManageCustomerDetail',
    props: {
        customerId: {
            type: [String]
        },
        tabId: {
            type: [String, Number],
            default: 0
        }
    },
    components: {
        ManageCustomerDetail
    },
    data() {
        return {
            tabItems: [
                { component: ManageCustomerDetail, label: 'Details' },
                { component: ManageCustomerGroup, label: 'Group' },
                { component: ManageCustomerMachine, label: 'Machine' }        
            ],
            actionPermission: {
                resources: 'Customer',
                any: true,
                actions: ['Edit', 'Delete']
            },
            actionItems: [
                {
                    label: 'Edit',
                    do: () =>
                        this.$router.push({
                            name: 'customerEdit',
                            params: { sitePath: 1, posId: this.posId }
                        }),
                    permission: { resources: 'Customer', actions: ['Edit'] }
                },
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(),
                    permission: { resources: 'Customer', actions: ['Delete'] }
                }
            ],
            customerQuery: new Query(this.$http, 'CustomersGet', {
                Ids: this.$route.params.customerId
            }),
            deleteCustomerCommand: new Command(this.$http, 'DELETE', 'CustomersRemove')
        }
    },
    created() {
        this.customerQuery.execute()
    },
    computed: {
        isLoading() {
            return this.customerQuery.isLoading || this.deleteCustomerCommand.isLoading
        }
    },
    methods: {
        confirmDelete() {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: "Are you sure you want to delete this Customer: <b>'" + this.customerQuery.firstRow.Name + "'</b>?",
                confirmText: 'Delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteCustomerCommand.execute({ Ids: this.$route.params.customerId }).then(resp => {
                        if (resp.status === 204) {
                            this.$router.push({ name: 'customerList' })
                        }
                    })
                }
            })
        }
    }
}
</script>