<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active="isLoading"></b-loading>

                <!-- Edit -->
                <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/customers" exact><span class="item-link">Customer</span></router-link>
                        </li>
                        <li>
                            <router-link :to="'/customer/' + customerId + '/tab/0'" exact
                                ><span class="item-link">{{ itemDetail.Name }}</span></router-link
                            >
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page">Edit {{ itemDetail.Name }}</a>
                        </li>
                    </ul>
                </nav>
                <div class="card mb-48">
                    <header class="card-header card-header-edit">
                        <div class="edit-title">Edit {{ itemDetail.Name }}</div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Name*"
                            :type="{ 'is-danger': $v.input.Name.$error }"
                            :message="{ 'Customer Name': $v.input.Name.$error }"
                        >
                            <b-input name="name" v-model="input.Name" ref="cusName" placeholder="" expanded></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Address*"
                            :type="{ 'is-danger': $v.input.Address.$error }"
                            :message="{ 'Customer Address': $v.input.Address.$error }"
                        >
                            <b-input
                                name="address"
                                v-model="input.Address"
                                ref="cusAddress"
                                placeholder=""
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field horizontal label="Location">
                            <b-field
                                :type="{ 'is-danger': $v.input.lat.$error }"
                                :message="{ 'POS Latitude': $v.input.lat.$error }"
                            >
                                <b-input
                                    name="lat"
                                    v-model="input.lat"
                                    ref="cusLat"
                                    placeholder="Latitude"
                                    expanded
                                ></b-input>
                            </b-field>
                            <b-field
                                :type="{ 'is-danger': $v.input.lng.$error }"
                                :message="{ 'POS Longitude': $v.input.lat.$error }"
                            >
                                <b-input
                                    name="lng"
                                    v-model="input.lng"
                                    ref="cusLng"
                                    placeholder="Longitude"
                                    expanded
                                ></b-input>
                            </b-field>
                        </b-field>

                        <b-field
                            horizontal
                            label="Country*"
                            :type="{ 'is-danger': $v.input.CountryCode.$error }"
                            :message="{ 'Please select country': $v.input.CountryCode.$error }"
                        >
                            <b-select name="country" v-model="input.CountryCode" placeholder="Select a country">
                                <option
                                    v-for="(option, index) in countryListGet.Data"
                                    :value="option.Code"
                                    :key="index"
                                >
                                    {{ option.Name }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field
                            horizontal
                            label="Phone Number*"
                            :type="{ 'is-danger': $v.input.PhoneNumber.$error }"
                            :message="{ 'Customer Phone Number': $v.input.PhoneNumber.$error }"
                        >
                            <b-input
                                name="phone"
                                v-model="input.PhoneNumber"
                                ref="cusPhoneNumber"
                                type="tel"
                                placeholder=""
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Email*"
                            :type="{ 'is-danger': $v.input.Email.$error }"
                            :message="{ 'Customer Email': $v.input.Email.$error }"
                        >
                            <b-input
                                name="email"
                                v-model="input.Email"
                                ref="cusEmail"
                                type="email"
                                placeholder=""
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="Server URL"
                            :type="{ 'is-danger': $v.input.ServerUrl.$error }"
                            :message="{ 'URL format': $v.input.ServerUrl.$error }"
                        >
                            <b-input name="serverURL" v-model="input.ServerUrl" ref="cusServerURL" expanded></b-input>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-edit">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm">Update</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Get from '@/libs/Get'
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required, numeric, email, url } from 'vuelidate/lib/validators'

export default {
    name: 'ManageCustomerEdit',
    props: {
        msg: String,
        cusId: Number,
    },
    data() {
        return {
            // Parameter
            customerId: this.$route.params.customerId,

            // Input
            input: {
                Id: '',
                Name: '',
                Address: '',
                lat: '',
                lng: '',
                PhoneNumber: '',
                Email: '',
                ServerUrl: '',
                CountryCode: '',
            },

            // Detail Item
            itemDetail: {},

            // Command
            customerQuery: new Query(this.$http, 'CustomersGet', {
                Ids: this.$route.params.customerId,
            }),
            editCustomerCommand: new Command(this.$http, 'PUT', 'CustomersUpdate'),
            countryListGet: new Get(this.$http, 'SupportedCountriesGet'),
        }
    },
    validations: {
        input: {
            Name: {
                required,
            },
            Address: {
                required,
            },
            PhoneNumber: {
                required,
                numeric,
            },
            Email: {
                required,
                email,
            },
            ServerUrl: {
                url,
            },
            lat: {
                required,
            },
            lng: {
                required,
            },
            CountryCode: {
                required,
            },
        },
    },
    created() {
        this.countryListGet.execute({}).then(() => {
            this.getItem()
        })
    },
    computed: {
        isLoading() {
            return this.customerQuery.isLoading || this.editCustomerCommand.isLoading
        },
    },
    methods: {
        getItem() {
            this.customerQuery.execute().then(() => {
                this.itemDetail = this.customerQuery.firstRow
                this.input = Object.assign(this.input, this.itemDetail)
                this.input.CountryCode = this.itemDetail.Country.Code
                //console.log('this.input: ', this.input)
                if (this.customerQuery.firstRow.Location) {
                    this.input.lat = this.customerQuery.firstRow.Location.Latitude
                    this.input.lng = this.customerQuery.firstRow.Location.Longitude
                }
            })
        },
        updateCustomer() {
            let locationText = ''
            if (this.input.lat.length > 0 && this.input.lng.length > 0)
                locationText = this.input.lat + ' , ' + this.input.lng
            let cId = this.input.Id

            this.editCustomerCommand
                .execute({
                    Customers: [
                        {
                            Id: this.customerId,
                            Name: this.input.Name,
                            Address: this.input.Address,
                            Location: {
                                Latitude: this.input.lat,
                                Longitude: this.input.lng,
                            },
                            PhoneNumber: this.input.PhoneNumber,
                            Email: this.input.Email,
                            ServerUrl: this.input.ServerUrl,
                            CountryCode: this.input.CountryCode,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$router.go(-1)
                    }
                })
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.updateCustomer()
        },
    },
}
</script>
