<template>
    <div class="tab-box">
        <!-- Tab Menu -->
        <div class="tab-item">
            <button
                v-for="(item, index) in items"
                :key="index"
                class="tab-link"
                :class="{ active: currentTabComponent == item.component }"
                @click="tabClick(index)"
            >
                {{ item.label }}
            </button>
        </div>

        <!-- Tab Content -->
        <keep-alive>
            <component :is="currentTabComponent"></component>
        </keep-alive>

        <div class="clear-float"></div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        currentTabComponent: {
            type: Object,
        },
    },
    methods: {
        tabClick(index) {
            this.$emit("tabClick", index)
        },
    },
}
</script>

<style lang="scss" scoped>
/* TABS */
.tab-box {
    display: block;
    background-color: #fff;
    height: 100%;

    .tab-item {
        float: left;
        background-color: #fff;
        width: 15%;
        padding-top: 16px;
        padding-bottom: 16px;

        .tab-link {
            display: block;
            width: 100%;
            padding: 6px 16px 4px;
            margin: 4px 0;
            border: none;
            border-left: 4px solid transparent;
            background-color: #fff;
            text-align: left;
            cursor: pointer;
            transition: 0.3s;

            &:focus {
                outline: none;
            }

            &:hover {
                background-color: #ccc;
            }

            &.active {
                border-left: 4px solid #00a1c9;
                background-color: #fff;
                font-weight: 500;
            }
        }
    }

    .tab-content {
        float: right;
        padding: 16px;
        padding-top: 22px;
        padding-right: 32px;
        width: 85%;
        display: none;
        background-color: #fff;

        &.active {
            float: right;
            padding: 16px;
            padding-top: 22px;
            padding-right: 32px;
            width: 85%;
            display: block;
            background-color: #fff;
        }
    }
}
.clear-float {
    float: none;
    clear: both;
}
</style>
