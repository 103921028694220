var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BTabContent',{attrs:{"title":"Machine"}},[_c('div',{staticClass:"detail-item-content"},[_c('LayoutListInline',{attrs:{"padding-x":"0","padding-y":"0","gap-x":"4","horizontal-align":"left","vertical-align":"center","wrap":true}},[_c('BExtAutoComplete',{attrs:{"QueryAPIName":"MachinesQuery","QueryParams":{ Keyword: '', ExcludeByCustomerId: '*' },"displayField":"SerialNumber","placeholderText":"Search to assign Machine","clearOnSelect":true},on:{"onItemSelect":function (value) { return _vm.assignMachine(value); }}})],1),_c('BListview',{ref:"listview",attrs:{"hideHeaderSection":true,"disableSearchByKeyword":true,"QueryAPIName":"MachinesOfCustomerGet","QueryParams":_vm.params},on:{"onPageSizeChange":function (value) { _vm.params.pageSize = value; },"onPageChange":function (value) { _vm.params.currentPage = value; }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var result = ref.result;
return [_c('b-table',{attrs:{"data":result,"backend-pagination":"","backend-sorting":"","current-page":_vm.params.currentPage,"per-page":_vm.params.pageSize,"default-sort-direction":_vm.params.sortDirection,"default-sort":_vm.params.sortColumn},on:{"sort":function (field, order) {
                              _vm.params.sortColumn = field
                              _vm.params.sortDirection = order
                          }}},[_c('b-table-column',{attrs:{"field":"SerialNumber","label":"Serial Number","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.SerialNumber)+" ")]}}],null,true)}),_c('b-table-column',{attrs:{"field":"Licenses","label":"License Status"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.Licenses.length > 0)?_c('div',[_vm._v(" Has license ")]):_c('div',[_vm._v(" No license ")])]}}],null,true)}),_c('b-table-column',{attrs:{"field":"control","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-dropdown',{attrs:{"position":"is-bottom-left"}},[_c('button',{staticClass:"button border-0 btn-control",attrs:{"slot":"trigger"},slot:"trigger"},[_c('b-icon',{attrs:{"icon":"dots-horizontal","size":"is-small"}})],1),(props.row.Licenses.length == 0)?_c('b-dropdown-item',{attrs:{"has-link":""}},[_c('router-link',{attrs:{"to":{ name: 'machineLicenseAdd', params: { machineId: props.row.Id } }}},[_vm._v("Create License")])],1):_vm._e(),_c('b-dropdown-item',{on:{"click":function($event){return _vm.confirmUnassign(props.row)}}},[_vm._v("Unassign")])],1)]}}],null,true)}),_c('template',{slot:"empty"},[_c('BEmptyState',{attrs:{"title":"No assigned machine","subtitle":" "}})],1)],2)]}},{key:"loadingState",fn:function(ref){
                          var loadingRows = ref.loadingRows;
return [_c('b-table',{attrs:{"data":loadingRows}},[_c('b-table-column',{attrs:{"label":"Serial Number"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1),_c('b-table-column',{attrs:{"label":"Licenses"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1),_c('b-table-column',{attrs:{"field":"control"}},[_c('b-skeleton',{attrs:{"height":"35px","animated":true}})],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }