<template>
    <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
        <ul>
            <li @click="$emit('prevPathClick')">
                <a class="item-link">{{prevPathName}}</a>
            </li>
            <li v-if="hasDotDotDot">
                <span class="item-link">...</span>
            </li>
            <li v-if="hasParent" @click="$emit('parentPathClick')">
                <a class="item-link">{{parentPathName}}</a>
            </li>
            <li class="is-active">
                <a>{{ currentPathName }}</a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'BBreadcrumb',
    props: {
        prevPathName: {
            type: [String],
        },
        currentPathName: {
            type: [String],
        },
        hasDotDotDot:{
            type: [Boolean],
            default: false
        },
        hasParent:{
            type: [Boolean],
            default: false
        },
        parentPathName: {
            type: [String],
        }
    },

    data() {
        return {}
    },
    mounted() {}
}
</script>

<style scoped>
.breadcrumb a {
    color: #167df0;
}
.breadcrumb li + li::before {
    color: #ccc;
    font-weight: bold;
    content: "/";
}
span.item-link{
    display: inline-block;
    padding: 0 12px;
}
</style>

