<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view">
                <b-loading :is-full-page="false" :active.sync="IsLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Create {{ TitleType }} License</div>
                    </header>
                    <div class="card-content">
                        <BValidateField
                            label="Customer"
                            :validator="$v.input.customer"
                            message="Please select Customer"
                        >
                            <!-- <b-autocomplete
                                v-model="customerKeyword"
                                :data="customerRows"
                                :loading="customerQuery.isLoading"
                                :keep-first="true"
                                :open-on-focus="true"
                                :clearable="true"
                                placeholder="Search Customer name..."
                                icon="magnify"
                                field="Name"
                                @focus="getCustomer(customerKeyword)"
                                @typing="(value) => getCustomer(value)"
                                @select="(value) => selectCustomer(value)"
                                style="width:50%;"
                            >
                            </b-autocomplete> -->

                            <!-- <BExtAutoComplete
                                QueryAPIName="CustomersQuery"
                                placeholderText="Search Customer name..."
                                @onItemSelect="(value) => selectCustomer(value)"
                            /> -->

                            <BCustomerPicker @onItemSelect="(value) => selectCustomer(value)" />
                        </BValidateField>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;">
                        <BValidateField label="Product" :validator="$v.input.product" message="Please select Product">
                            <!-- <b-autocomplete
                                v-model="productKeyword"
                                :data="productRows"
                                :loading="productQuery.isLoading"
                                :keep-first="true"
                                :open-on-focus="true"
                                :clearable="true"
                                placeholder="Search Product name..."
                                icon="magnify"
                                field="Name"
                                @focus="getProduct(productKeyword)"
                                @typing="(value) => getProduct(value)"
                                @select="(value) => selectProduct(value)"
                                :disabled="input.customer == ''"
                                style="width:50%;"
                            > 
                            </b-autocomplete>-->

                            <BExtAutoComplete
                                QueryAPIName="ProductsQuery"
                                placeholderText="Search Product name..."
                                @onItemSelect="(value) => selectProduct(value)"
                                :isDisabled="input.customer == ''"
                                ref="productAutoComplate"
                            />
                        </BValidateField>

                        <BValidateField
                            label="Product Version"
                            :validator="$v.input.productVersion"
                            message="Please select Product Version"
                        >
                            <!-- <b-autocomplete
                                v-model="productVersionKeyword"
                                :data="productVersionRows"
                                :loading="productVersionQuery.isLoading"
                                :keep-first="true"
                                :open-on-focus="true"
                                :clearable="true"
                                placeholder="Search Version Name"
                                icon="magnify"
                                field="Name"
                                @focus="getVersion(productVersionKeyword)"
                                @typing="(value) => getVersion(value)"
                                @select="(value) => selectVersion(value)"
                                :disabled="input.customer == '' || input.product == ''"
                                style="width:50%;"
                            >
                            </b-autocomplete> -->

                            <BExtAutoComplete
                                QueryAPIName="ProductVersionsOfProductQuery"
                                :QueryParams="{ Keyword: '', ProductId: input.product }"
                                placeholderText="Search Version name..."
                                @onItemSelect="(value) => selectVersion(value)"
                                :isDisabled="input.customer == '' || input.product == ''"
                                ref="productVersionAutoComplate"
                            />
                        </BValidateField>

                        <BValidateField
                            v-if="ActivationKeyVisible"
                            label="Serial Number"
                            :validator="$v.input.selectedSerialNo"
                            message="Please enter at lease one serial number"
                        >
                            <div>
                                <!-- <b-input name="licensePeriod" v-model="input.ActivationKey" ref="licensePeriod" placeholder="Serial Number" expanded></b-input> -->
                                <!-- <b-autocomplete
                                    v-model="serialNumberKeyword"
                                    :data="serialNumberQuery.Rows"
                                    :loading="serialNumberQuery.isLoading"
                                    :keep-first="true"
                                    :open-on-focus="true"
                                    clear-on-select="true"
                                    placeholder='Type Serial number...'
                                    icon="magnify"
                                    field="SerialNumber"
                                    @focus="getSerialNumber(serialNumberKeyword)"
                                    @typing="value => getSerialNumber(value)"
                                    @select="value => selectSerialNumber(value)"
                                >
                                </b-autocomplete>

                                <ul class="list list-assigned">
                                    <li class="list-item" v-for="(machine, index) in selectedSerialNumbers" :key="index">
                                        <div class="item-assigned">
                                            <span>{{ machine.SerialNumber }}</span>
                                            <div class="item-assigned-remove" @click="confirmRemove(machine)">
                                                <b-tooltip label="Unassign" position="is-right" type="is-light">
                                                    <b-icon icon="close"></b-icon>
                                                </b-tooltip>
                                            </div>
                                        </div>
                                    </li>
                                </ul> -->

                                <multiselect
                                    v-model="input.selectedSerialNo"
                                    id="ajax"
                                    label="SerialNumber"
                                    track-by="SerialNumber"
                                    placeholder=""
                                    open-direction="bottom"
                                    :disabled="
                                        input.customer == '' || input.product == '' || input.productVersion == ''
                                    "
                                    :options="SerialNoList"
                                    :multiple="true"
                                    :searchable="true"
                                    :loading="isDropdownLoading"
                                    :internal-search="false"
                                    :clear-on-select="false"
                                    :close-on-select="false"
                                    :options-limit="300"
                                    :limit="100"
                                    :limit-text="limitText"
                                    :max-height="600"
                                    :show-no-results="false"
                                    :hide-selected="true"
                                    selectLabel=""
                                    @search-change="asyncFind"
                                >
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="custom__tag">
                                            <span>{{ option.SerialNumber }}</span>
                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                        </span>
                                    </template>

                                    <template slot="clear" slot-scope="props">
                                        <div
                                            class="multiselect__clear"
                                            v-if="input.selectedSerialNo.length"
                                            @mousedown.prevent.stop="clearAll(props.search)"
                                        ></div>
                                    </template>

                                    <span slot="noOptions">No machine available.</span>
                                </multiselect>
                            </div>
                        </BValidateField>

                        <BValidateField
                            v-if="!ActivationKeyVisible && input.productVersion != ''"
                            label="Features"
                            :validator="$v.selectedRows"
                            message="Please enter at lease one feature"
                        >
                            <div>
                                <b-table :data="input.features" checkable :checked-rows.sync="selectedRows">
                                    <b-table-column label="Name" v-slot="props">
                                        {{ props.row.Name }}
                                    </b-table-column>

                                    <b-table-column label="Limits" width="130" v-slot="props">
                                        <div v-if="props.row.IsLimitable">
                                            <b-numberinput
                                                type="is-outlined"
                                                icon-pack="fa"
                                                controls-position="compact"
                                                :min="1"
                                                v-model="props.row.Limits"
                                                style="width:130px"
                                            ></b-numberinput>
                                        </div>
                                        <div v-else>
                                            <div style="text-align:left; line-height:35px;">N/A</div>
                                        </div>
                                    </b-table-column>

                                    <template slot="empty">
                                        <section class="section">
                                            <div class="content has-text-grey has-text-centered">
                                                <p>
                                                    <b-icon icon="blur" size="is-mediam"> </b-icon>
                                                </p>
                                                <p>No Item.</p>
                                            </div>
                                        </section>
                                    </template>
                                </b-table>
                            </div>
                        </BValidateField>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;">
                        <BValidateField
                            label="License Type"
                            :validator="$v.input.Type"
                            message="Please select a License Type"
                        >
                            <b-select
                                name="licenseType"
                                v-model="input.Type"
                                ref="licenseType"
                                placeholder="Select a License Type"
                            >
                                <option v-for="(option, index) in licenseTypes" :value="option" :key="index">
                                    {{ option }}
                                </option>
                            </b-select>
                        </BValidateField>

                        <BValidateField
                            v-if="input.Type != 'Perpetual'"
                            label="Period"
                            :validator="$v.input.period"
                            message="Please select a Period"
                        >
                            <b-period
                                v-model="input.period"
                                @change="
                                    (value) => {
                                        this.input.period = value
                                    }
                                "
                            />
                        </BValidateField>

                        <BValidateField
                            v-if="input.Type != 'Perpetual'"
                            label="Max Activations"
                            :validator="$v.input.maxActivation"
                            message="Value is out of range"
                        >
                            <BMaxActivation
                                :value="input.maxActivation"
                                :maxActivate="maxActivationFromProduct"
                                @onChange="
                                    (value) => {
                                        input.maxActivation = value
                                    }
                                "
                            />
                        </BValidateField>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;">
                        <b-field horizontal label="Note">
                            <b-input type="textarea" name="note" v-model="input.note" ref="note"></b-input>
                        </b-field>
                    </div>

                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Create</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import Get from '@/libs/Get'
import { required, numeric, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators'
import BPeriod from '@/components/control/BPeriod'
import BMaxActivation from '@/components/control/BMaxActivation'
import Multiselect from 'vue-multiselect'
import BExtAutoComplete from '@/components/common/BExtAutoComplete'
import BCustomerPicker from '@/components/control/BCustomerPicker'

export default {
    name: 'ManageLicenseCreate',
    props: {
        msg: String,
    },
    components: {
        BPeriod,
        BMaxActivation,
        Multiselect,
        BExtAutoComplete,
        BCustomerPicker
    },
    data() {
        return {
            /* Select Option */
            licenseTypes: ['Trial', 'Subscription', 'Perpetual'],

            maxActivationFromProduct: 1,

            selectedRows: [],
            ActivationKeyVisible: false,

            input: {
                product: '',
                productVersion: '',
                customer: '',
                Type: null,
                ActivationKey: '',
                selectedSerialNo: [],
                period: 1,
                maxActivation: 1,
                features: [],
                note: '',
            },
            selectedSerialNumbers: [],

            // customerKeyword: '',
            // customerRows: [],
            // customerQuery: new Query(this.$http, 'CustomersQuery', { Keyword: '' }, 1, 0, 'Name'),

            // productKeyword: '',
            // productQuery: new Query(this.$http, 'ProductsQuery', { Keyword: '' }, 1, 0, 'Name'),
            // productRows: [],

            // productVersionKeyword: '',
            // productVersionQuery: new Query(
            //     this.$http,
            //     'ProductVersionsOfProductQuery',
            //     { ProductId: '', Keyword: '' },
            //     1,
            //     0,
            //     'Name'
            // ),
            // productVersionRows: [],

            serialNumberKeyword: '',
            serialNumberQuery: new Query(
                this.$http,
                'MachinesQuery',
                { Keyword: '', CustomerIds: '', NoActiveLicenseOnly: true },
                1,
                0,
                'SerialNumber'
            ),

            schemaGet: new Get(this.$http, 'LicenseSchemaGet', { ProductVersionId: '' }),

            createLicenseCommand: new Command(this.$http, 'POST', 'LicensesAdd'),

            SerialNoList: [],
            isDropdownLoading: false,
            TitleType: this.$route.params.type == 'm' ? 'Machine' : 'Server'
        }
    },
    validations() {
        return {
            input: {
                product: {
                    required,
                },
                productVersion: {
                    required,
                },
                customer: {
                    required,
                },
                selectedSerialNo: {
                    required: requiredIf(function() {
                        return this.ActivationKeyVisible
                    }),
                },
                Type: {
                    required,
                },
                period: {
                    required,
                    numeric,
                    minValue: minValue(1)
                },
                maxActivation: {
                    numeric,
                    required,
                    minValue: minValue(1),
                    maxValue: maxValue(this.maxActivationFromProduct),
                },
            },
            selectedRows: {
                required: requiredIf(function() {
                    return !this.ActivationKeyVisible
                }),
            },
        }
    },
    computed: {
        assignedLicenses() {
            return this.assignedLicenseQuery.Rows
        },
        IsLoading() {
            return this.createLicenseCommand.isLoading || this.schemaGet.isLoading
        },
    },
    created() {},
    methods: {
        // async getCustomer(value) {
        //     await this.customerQuery.execute({ Keyword: value })
        //     this.customerRows = this.customerQuery.Rows
        //     this.customerRows.unshift({ Id: '', Name: '' })
        // },
        selectCustomer(value) {
            this.input.customer = value ? value.Id : ''

            if(this.input.customer == ''){
                //this.productKeyword = ''
                this.input.product = ''
                this.$refs.productAutoComplate.clear()
                
                //this.productVersionKeyword = ''
                this.input.productVersion = ''
                this.$refs.productVersionAutoComplate.clear()
            } else {
                this.serialNumberQuery.execute({ Keyword: '', CustomerIds: this.input.customer }).then(() => {
                    this.SerialNoList = this.serialNumberQuery.Rows
                })
                //this.productQuery.execute({ Keyword: '' })
            }
        },
        // getProduct(value) {
        //     this.productQuery.execute({ Keyword: value })
        //     this.productRows = this.productQuery.Rows
        //     this.productRows.unshift({ Id: '', Name: '' })
        // },
        selectProduct(value) {
            this.input.product = value ? value.Id : ''
            
            if(this.input.product == ''){
                //this.productVersionKeyword = ''
                this.input.productVersion = ''
                this.$refs.productVersionAutoComplate.clear()
            }else {
                this.ActivationKeyVisible = value.ActivationMethod == 'Machine'
                this.maxActivationFromProduct = value.MaxActivations
                //this.productVersionQuery.execute({ ProductId: value.Id, Keyword: '' })
            }
        },
        // getVersion(value) {
        //     this.productVersionQuery.execute({ ProductId: this.input.product, Keyword: value })
        //     this.productVersionRows = this.productVersionQuery.Rows
        //     this.productVersionRows.unshift({ Id: '', Name: '' })
        // },
        selectVersion(value) {
            this.input.productVersion = value ? value.Id : ''

            if (this.input.productVersion != '' && this.ActivationKeyVisible == false) {
                this.schemaGet.execute({ ProductVersionId: value.Id }).then(() => {
                    this.input.features = this.schemaGet.Data.Features
                    this.input.features.forEach((element) => {
                        element.Limits = 1
                    })
                })
            }
        },
        
        getFeature(value) {},
        selectFeature(value) {
            value.IsEnabled = true
            value.Limits = 0
            this.input.features.push(value)
        },
        getSerialNumber(value) {
            this.serialNumberQuery.execute({ keyword: value, CustomerIds: this.input.customer })
        },
        selectSerialNumber(value) {
            this.selectedSerialNumbers.push(value)
        },
        confirmRemove(machine) {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: "Are you sure you want to remove machine: <b>'" + machine.SerialNumber + "'</b>?",
                confirmText: 'Remove',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.selectedSerialNumbers = this.selectedSerialNumbers.filter((obj) => obj.Id !== machine.Id)
                },
            })
        },

        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.createLicense()
        },
        createLicense() {
            let features = this.selectedRows
            features.forEach((element) => {
                element.IsEnabled = true
            })

            let items = []

            //this.selectedSerialNumbers.forEach(element => {
            this.input.selectedSerialNo.forEach((element) => {
                items.push({
                    CustomerId: this.input.customer,
                    ProductVersionId: this.input.productVersion,
                    ActivationKey: element.SerialNumber,
                    Type: this.input.Type,
                    Periods: this.input.period,
                    MaxActivations: this.input.maxActivation,
                    Features: features,
                    Note: this.input.note,
                })
            })

            if (items.length == 0) {
                items.push({
                    CustomerId: this.input.customer,
                    ProductVersionId: this.input.productVersion,
                    Type: this.input.Type,
                    Periods: this.input.period,
                    MaxActivations: this.input.maxActivation,
                    Features: features,
                    Note: this.input.note,
                })
            }

            this.createLicenseCommand.execute({ Licenses: items }).then((resp) => {
                if (resp.status === 201) {
                    // if(this.ActivationKeyVisible)
                    //     this.$router.push({ name: 'licenseListMachine' })
                    // else
                    //     this.$router.push({ name: 'licenseListServer' })
                    this.$router.go(-1)
                }
            })
        },

        limitText(count) {
            return `and ${count} other Serials Number`
        },
        asyncFind(query) {
            this.isDropdownLoading = true
            this.serialNumberQuery.execute({ Keyword: query, CustomerIds: this.input.customer }).then(() => {
                this.SerialNoList = this.serialNumberQuery.Rows
                this.isDropdownLoading = false
            })
        },
        clearAll() {
            this.input.selectedSerialNo = []
        },
    },
}
</script>

<style>
fieldset[disabled] .multiselect {
    pointer-events: none;
}
.multiselect__spinner {
    position: absolute;
    right: 1px;
    top: 1px;
    width: 48px;
    height: 35px;
    background: #fff;
    display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid transparent;
    border-top-color: whitesmoke;
    box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
    animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
    animation-iteration-count: infinite;
}
.multiselect__spinner:after {
    animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
    animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
    transition: opacity 0.4s ease-in-out;
    opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
    opacity: 0;
}
.multiselect,
.multiselect__input,
.multiselect__single {
    font-family: inherit;
    font-size: 16px;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}
.multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #35495e;
}
.multiselect * {
    box-sizing: border-box;
}
.multiselect:focus {
    outline: none;
}
.multiselect--disabled {
    background: #ededed;
    pointer-events: none;
    opacity: 0.6;
}
.multiselect--active {
    z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
    transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
    position: relative;
    display: inline-block;
    min-height: 20px;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    background: #fff;
    padding: 0 0 0 5px;
    width: 100%;
    transition: border 0.1s ease;
    box-sizing: border-box;
    margin-bottom: 8px;
    vertical-align: top;
}
.multiselect__input:-ms-input-placeholder {
    color: #35495e;
}
.multiselect__input::placeholder {
    color: #35495e;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
    width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
    border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
    border-color: #a8a8a8;
    outline: none;
}
.multiselect__single {
    padding-left: 5px;
    margin-bottom: 8px;
}
.multiselect__tags-wrap {
    display: inline;
}
.multiselect__tags {
    min-height: 40px;
    display: block;
    padding: 8px 40px 0 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
}
.multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 4px 26px 4px 10px;
    border-radius: 5px;
    margin-right: 10px;
    color: #fff;
    line-height: 1;
    background: whitesmoke;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}
.multiselect__tag-icon {
    cursor: pointer;
    margin-left: 7px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-weight: 700;
    font-style: normal;
    width: 22px;
    text-align: center;
    line-height: 22px;
    transition: all 0.2s ease;
    border-radius: 5px;
}
.multiselect__tag-icon:after {
    content: '\D7';
    color: #266d4d;
    font-size: 14px;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
    background: #369a6e;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
    color: #fff;
}
.multiselect__current {
    min-height: 40px;
    overflow: hidden;
    padding: 8px 30px 0 12px;
    white-space: nowrap;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
    line-height: 16px;
    box-sizing: border-box;
    display: block;
    margin: 0;
    text-decoration: none;
    cursor: pointer;
}
.multiselect__select {
    position: absolute;
    width: 40px;
    height: 38px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
    text-align: center;
    transition: transform 0.2s ease;
}
.multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: '';
}
.multiselect__placeholder {
    color: #adadad;
    display: inline-block;
    margin-bottom: 10px;
    padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
    display: none;
}
.multiselect__content-wrapper {
    position: absolute;
    display: block;
    background: #fff;
    width: 100%;
    max-height: 240px;
    overflow: auto;
    border: 1px solid #e8e8e8;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 50;
    -webkit-overflow-scrolling: touch;
}
.multiselect__content {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    min-width: 100%;
    vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
    bottom: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: none;
    border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
    display: none;
}
.multiselect__element {
    display: block;
}
.multiselect__option {
    display: block;
    padding: 12px;
    min-height: 40px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
}
.multiselect__option:after {
    top: 0;
    right: 0;
    position: absolute;
    line-height: 40px;
    padding-right: 12px;
    padding-left: 20px;
    font-size: 13px;
}
.multiselect__option--highlight {
    background: whitesmoke;
    outline: none;
    color: #000;
}
.multiselect__option--highlight:after {
    content: attr(data-select);
    background: whitesmoke;
    color: #000;
}
.multiselect__option--selected {
    background: #f3f3f3;
    color: #35495e;
    font-weight: 700;
}
.multiselect__option--selected:after {
    content: attr(data-selected);
    color: silver;
}
.multiselect__option--selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff;
}
.multiselect__option--selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
    background: #ededed;
    color: #a6a6a6;
}
.multiselect__option--disabled {
    background: #ededed !important;
    color: #a6a6a6 !important;
    cursor: text;
    pointer-events: none;
}
.multiselect__option--group {
    background: #ededed;
    color: #35495e;
}
.multiselect__option--group.multiselect__option--highlight {
    background: #35495e;
    color: #fff;
}
.multiselect__option--group.multiselect__option--highlight:after {
    background: #35495e;
}
.multiselect__option--disabled.multiselect__option--highlight {
    background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
    background: #ff6a6a;
    color: #fff;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
    background: #ff6a6a;
    content: attr(data-deselect);
    color: #fff;
}
.multiselect-enter-active,
.multiselect-leave-active {
    transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
    opacity: 0;
}
.multiselect__strong {
    margin-bottom: 8px;
    line-height: 20px;
    display: inline-block;
    vertical-align: top;
}
[dir='rtl'] .multiselect {
    text-align: right;
}
[dir='rtl'] .multiselect__select {
    right: auto;
    left: 1px;
}
[dir='rtl'] .multiselect__tags {
    padding: 8px 8px 0 40px;
}
[dir='rtl'] .multiselect__content {
    text-align: right;
}
[dir='rtl'] .multiselect__option:after {
    right: auto;
    left: 0;
}
[dir='rtl'] .multiselect__clear {
    right: auto;
    left: 12px;
}
[dir='rtl'] .multiselect__spinner {
    right: auto;
    left: 1px;
}
@keyframes spinning {
    0% {
        transform: rotate(0);
    }
    to {
        transform: rotate(2turn);
    }
}
</style>
