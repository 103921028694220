<template>
  <BTabContent :loading="roleQuery.isLoading">
    <div v-if="details">

            <div class="detail-item-title">Name</div>
            <div class="detail-item-content">{{ details.Name }}&nbsp;</div>
            <div class="detail-item-title">Description</div>
            <div class="detail-item-content">{{ details.Description }}&nbsp;</div>

    </div>
    <div v-else>
        <!-- <p>Details not found</p> -->
    </div>
  </BTabContent>
</template>

<script>
import Query from '@/libs/Query'

export default {
  props: {
    msg: String
  },
  data() {    
    return {
        roleQuery: new Query(this.$http, 'RolesGet', {
            Ids: this.$route.params.roleId
        }),
    }
  },
  created() {        
      this.roleQuery.execute()
  },
  computed: {
      details() {
          return this.roleQuery.firstRow
      }
  }
};
</script>