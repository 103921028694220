var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-content bg"},[_c('div',{staticClass:"content"},[_c('b-table',{attrs:{"data":_vm.customersQuery.Rows,"backend-pagination":"","backend-sorting":"","selected":_vm.selected,"focusable":"","current-page":_vm.$path.params.currentPage,"per-page":_vm.$path.params.pageSize,"default-sort-direction":_vm.$path.params.sortDirection,"default-sort":_vm.$path.params.sortColumn},on:{"update:selected":function($event){_vm.selected=$event},"sort":function (field, order) {
                        _vm.$path.params.sortColumn = field
                        _vm.$path.params.sortDirection = order
                        _vm.$path.push('customersList')
                    },"click":_vm.rowSelected}},[_c('b-table-column',{staticStyle:{"width":"400px"},attrs:{"field":"Name","label":"Name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('router-link',{attrs:{"to":{ name: 'customerDetail', params: { customerId: props.row.Id, tabId: 0 } }}},[_c('span',{staticClass:"item-link"},[_vm._v(_vm._s(props.row.Name))])])]}}])}),_c('b-table-column',{attrs:{"field":"Country","label":"Country","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.Country.Name)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"MachineCount","label":"No. of Machine","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-tooltip',{attrs:{"label":props.row.MachineStringify,"position":"is-right","type":"is-light"}},[_vm._v(" "+_vm._s(props.row.MachineCount)+" ")])]}}])}),_c('template',{slot:"empty"},[_c('BEmptyState',{attrs:{"title":"No any item","subtitle":""}})],1)],2),_c('div',{staticClass:"pagination-container"},[_c('BPagination',{attrs:{"total":_vm.customersQuery.TotalRows,"per-page":_vm.$path.params.pageSize,"current":_vm.$path.params.currentPage},on:{"change":function (page) {
                            _vm.$path.params.currentPage = page
                            _vm.$path.push('paging')
                        }}})],1)],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Customers")])])}]

export { render, staticRenderFns }