<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view">
                <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

                <!-- Edit -->
                <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/license" exact><span class="item-link">License</span></router-link>
                        </li>
                        <li>
                            <router-link :to="'/license/' + licenseID + '/tab/0'" exact
                                ><span class="item-link"
                                    >{{ itemDetail.Product.Name }} v{{ itemDetail.ProductVersion.Name }}</span
                                ></router-link
                            >
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page"
                                >Edit {{ itemDetail.Product.Name }} v{{ itemDetail.ProductVersion.Name }}</a
                            >
                        </li>
                    </ul>
                </nav>
                <div class="card mb-48">
                    <header class="card-header card-header-edit">
                        <div class="edit-title">
                            Edit {{ itemDetail.Product.Name }} v{{ itemDetail.ProductVersion.Name }}
                        </div>
                    </header>
                    <div class="card-content">
                        <b-field horizontal label="Customer">
                            {{ itemDetail.Customer.Name }}
                        </b-field>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;">
                        <b-field horizontal label="Product">
                            {{ itemDetail.Product.Name }}
                        </b-field>

                        <b-field horizontal label="Product Version">
                            {{ itemDetail.ProductVersion.Name }}
                        </b-field>

                        <b-field horizontal label="Features" v-if="input.features">
                            <div>
                                <b-table :data="input.features" checkable :checked-rows.sync="selectedRows">
                                    <b-table-column label="Name" v-slot="props">
                                        {{ props.row.Name }}
                                    </b-table-column>

                                    <b-table-column label="Limits" width="130" v-slot="props">
                                        <div v-if="props.row.IsLimitable">
                                            <b-numberinput
                                                v-model="props.row.Limits"
                                                style="width:130px"
                                            ></b-numberinput>
                                        </div>
                                        <div v-else>
                                            <div style="text-align:left;line-height:35px;">N/A</div>
                                        </div>
                                    </b-table-column>

                                    <template slot="empty">
                                        <section class="section">
                                            <div class="content has-text-grey has-text-centered">
                                                <p>
                                                    <b-icon icon="blur" size="is-mediam"> </b-icon>
                                                </p>
                                                <p>No Item.</p>
                                            </div>
                                        </section>
                                    </template>
                                </b-table>
                            </div>
                        </b-field>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;">
                        <b-field
                            horizontal
                            label="Serial Number*"
                            :type="{ 'is-danger': $v.input.ActivationKey.$error }"
                            :message="{ 'Serial number': $v.input.ActivationKey.$error }"
                            v-if="ActivationKeyVisible"
                        >
                            <b-input
                                name="licensePeriod"
                                v-model="input.ActivationKey"
                                ref="licensePeriod"
                                placeholder="Serial Number"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field
                            horizontal
                            label="License Type*"
                            :type="{ 'is-danger': $v.input.Type.$error }"
                            :message="{ 'Please select a License Type': $v.input.Type.$error }"
                        >
                            <b-select
                                name="licenseType"
                                v-model="input.Type"
                                ref="licenseType"
                                placeholder="Select a License Type"
                            >
                                <option v-for="(option, index) in licenseTypes" :value="option" :key="index">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field horizontal label="Period*" v-if="input.Type != 'Perpetual'">
                            <b-period
                                v-model="input.period"
                                @change="
                                    (value) => {
                                        this.input.period = value
                                    }
                                "
                            />
                        </b-field>

                        <b-field 
                            horizontal 
                            label="Max Activations*"
                            :type="{ 'is-danger': $v.input.maxActivation.$error }"
                            :message="{ 'Value is out of range': $v.input.maxActivation.$error }"
                            >
                            <BMaxActivation :value="input.maxActivation" :maxActivate="maxActivationFromProduct" @onChange="(value) => { input.maxActivation = value }" />
                        </b-field>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;">
                        <b-field horizontal label="Note">
                            <b-input type="textarea" name="note" v-model="input.note" ref="note"></b-input>
                        </b-field>
                    </div>

                    <footer class="card-footer card-footer-edit">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm">Update</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Get from '@/libs/Get'
import Query from '@/libs/Query'
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators'
import BPeriod from '@/components/control/BPeriod'
import BMaxActivation from '@/components/control/BMaxActivation'

export default {
    name: 'ManageLicenseEdit',
    props: {
        msg: String,
    },
    components: {
        BPeriod,
        BMaxActivation,
    },
    data() {
        return {
            licenseID: this.$route.params.licenseId,
            itemDetail: { Customer: {}, Product: {}, ProductVersion: {} },

            /* Select Option */
            licenseTypes: ['Trial', 'Subscription', 'Perpetual'],

            selectedRows: [],
            ActivationKeyVisible: false,

            maxActivationFromProduct: 1,

            input: {
                product: '',
                productVersion: '',
                customer: '',
                Type: null,
                ActivationKey: '',
                period: 1,
                maxActivation: 1,
                features: [],
                note: '',
            },

            licenseGet: new Query(this.$http, 'LicensesGet', { Ids: [] }, 1, 10, 'RemainingPeriod'),
            productGet: new Query(this.$http, 'ProductsGet', { Ids: [] }, 1, 10, 'Name'),
            schemaGet: new Get(this.$http, 'LicenseSchemaGet', { ProductVersionId: '' }),
            editLicenseCommand: new Command(this.$http, 'PUT', 'LicensesUpdate'),
        }
    },
    validations() {
        return {
            input: {
                Type: {
                    required,
                },
                period: {
                    required,
                    numeric,
                },
                maxActivation: {
                    numeric,
                    required,
                    minValue: minValue(1),
                    maxValue: maxValue(this.maxActivationFromProduct)
                }
            }
        }
    },
    created() {
        this.getItem()
    },
    computed: {
        isLoading() {
            return (
                this.licenseGet.isLoading ||
                this.productGet.isLoading ||
                this.schemaGet.isLoading ||
                this.editLicenseCommand.isLoading
            )
        },
    },
    methods: {
        async getItem() {
            await this.licenseGet.execute({ Ids: this.licenseID })
            this.itemDetail = this.licenseGet.firstRow
            this.input.Type = this.itemDetail.Type
            this.ActivationKeyVisible = this.itemDetail.Product.ActivationMethod == 'Machine'
            this.input.ActivationKey = this.itemDetail.ActivationKey
            this.input.period = this.itemDetail.Periods
            this.input.maxActivation = this.itemDetail.MaxActivations
            this.input.note = this.itemDetail.Note

            await this.productGet.execute({ Ids: this.itemDetail.Product.Id })
            this.maxActivationFromProduct = this.productGet.firstRow.MaxActivations

            await this.schemaGet.execute({ ProductVersionId: this.itemDetail.ProductVersion.Id })
            this.input.features = this.schemaGet.Data.Features
            if (this.input.features) {
                this.input.features.forEach((element) => {
                    element.Limits = 0

                    let exist = this.itemDetail.Features.filter((i) => i.Name == element.Name)

                    if (exist.length > 0) {
                        element.Limits = exist[0].Limits
                        this.selectedRows.push(element)
                    }
                })
            }
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.updateLicense()
        },
        updateLicense() {
            let features = this.selectedRows
            features.forEach((element) => {
                element.IsEnabled = true
            })

            this.editLicenseCommand
                .execute({
                    Licenses: [
                        {
                            Id: this.licenseID,
                            Type: this.input.Type,
                            Periods: this.input.period,
                            MaxActivations: this.input.maxActivation,
                            Features: features,
                            Note: this.input.note,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$router.go(-1)
                    }
                })
        },
    },
}
</script>
