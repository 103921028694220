<template>
    <MainListLayout>       

        <BListview 
            pageTitle="Customer"
            searchPlaceholder="Search Name, Address..."
            QueryAPIName="CustomersQuery"
            :QueryParams="params"
            DeleteAPIName="CustomersRemove"

            @onKeywordSearch="(value) => { params.keyword = value; }"
            @onPageSizeChange="(value) => { params.pageSize = value; }"
            @onPageChange="(value) => { params.currentPage = value; }"
            @onDeleted="(value) => { if(value) { selectedRows = []; } }"
            ref="listview">

            <template v-slot:actionButton>
                <BActionButton
                    v-if="selectedRows.length > 0"
                    :actionPermission="actionPermission"
                    :actionItems="actionItems"
                    :selectedRows="selectedRows"
                />
                <button v-else class="button is-primary ml-16" @click="$router.push({ name: 'customerCreate' })">Create</button>
            </template>
            
            <template v-slot:default="{ result, alert }" >

                <!-- <button @click="alert('BBBBBBB')">Test</button> -->

                <b-table
                    :data="result"
                    backend-pagination
                    backend-sorting
                    checkable
                    :current-page="params.currentPage"
                    :per-page="params.pageSize"
                    :checked-rows.sync="selectedRows"
                    :default-sort-direction="params.sortDirection"
                    :default-sort="params.sortColumn"
                    @sort="
                        (field, order) => {
                            params.sortColumn = field
                            params.sortDirection = order
                        }
                    "
                >
                

                    <b-table-column field="Name" label="Name" style="width: 400px;" sortable v-slot="props">
                        <router-link
                            :to="{
                                name: 'customerDetail',
                                params: { customerId: props.row.Id, tabId: 0 },
                            }"
                        >
                            <span class="item-link">{{ props.row.Name }}</span>
                        </router-link>
                        <br />
                        <span class="has-text-grey-light">{{ props.row.Address }}</span>
                    </b-table-column>

                    <b-table-column field="ServerUrl" label="Server URL" sortable v-slot="props">
                        {{ props.row.ServerUrl }}
                    </b-table-column>

                    <b-table-column field="MachineCount" label="No. of Machine" sortable v-slot="props">
                        <b-tooltip :label="props.row.MachineStringify" position="is-right" type="is-light">
                            {{ props.row.MachineCount }}
                        </b-tooltip>
                    </b-table-column>

                    <b-table-column field="control" centered v-slot="props">
                        <b-dropdown
                            position="is-bottom-left"
                            v-permit="{ resources: 'Customer', any: true, actions: ['Edit', 'Delete'] }"
                        >
                            <button class="button border-0 btn-control" slot="trigger">
                                <b-icon icon="dots-horizontal" size="is-small"></b-icon>
                            </button>
                            <b-dropdown-item has-link v-permit="{ resources: 'Customer', actions: ['Edit'] }">
                                <router-link :to="{ name: 'customerEdit', params: { customerId: props.row.Id } }"
                                    >Edit</router-link
                                >
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="confirmDelete([props.row])"
                                v-permit="{ resources: 'Customer', actions: ['Delete'] }"
                                >Delete</b-dropdown-item
                            >
                        </b-dropdown>
                    </b-table-column>
                    <template slot="empty">
                        <BEmptyState title="No mached result" subtitle="Please try another criteria" />
                    </template>
                </b-table>

            </template>

            <template v-slot:loadingState="{ loadingRows }">
                <b-table :data="loadingRows">
                    <b-table-column label="Name" style="width: 400px;">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Server URL">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="No. of Machine">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column field="control">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>                   
                </b-table>
            </template>
            
        </BListview>

    </MainListLayout>
</template>

<script>
import { MainListLayout } from '@/layouts'
import BListview from '@/components/common/BListview'

export default {
    name: 'ManageCustomerList',
    props: {
        msg: String,
    },
    components: {
        MainListLayout,
        BListview
    },
    url: {
        __root: 'params',

        keyword: { key: 'kw' },
        currentPage: { key: 'page' },
        pageSize: { key: 'size' },
        sortColumn: { key: 'col' },
        sortDirection: { key: 'dir' },
    },
    data() {

        const params = {
            keyword: '',
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'Name',
            sortDirection: 'asc'
        }

        return {
            params,

            selectedRows: [],

            actionPermission: {
                resources: 'Customer',
                any: true,
                actions: ['Delete'],
            },
            actionItems: [
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(this.selectedRows),
                    permission: { resources: 'Customer', actions: ['Delete'] },
                },
            ],
        }
    },
    
    methods: {
        confirmDelete(selectedRow) {
            let formattedItemsName = selectedRow.map((p) => `<b>${p.Name}</b>`).join('<br/>')
            let nPos = selectedRow.length > 1 ? selectedRow.length : ''
            let confirmMsg = `Are you sure you want to delete the following ${nPos} customer?<br/><br/>`
            let ItemsId = selectedRow.map((p) => p.Id)

            this.$refs.listview.confirmDelete(ItemsId, confirmMsg + formattedItemsName)
        }
    },
    mounted() {
        this.$refs.listview.reload()
        this.$refs.listview.startWatch()
    }
}
</script>
