<template>
  <BTabContent :loading="isLoading">
    <div v-if="itemPermissions">
        <div class="detail-item-title">Permission</div>
          <div class="detail-item-content">
              <div v-for=" (item, index) in itemPermissions " :key="index">
                  <div class="field">
                      <b-checkbox 
                          v-model="itemPermissions[index].HasPermission"
                          :value=" item.HasPermission "
                          @click.native="setPageAllPermission(index)"
                      >
                          {{ item.Resource }}
                      </b-checkbox>
                  </div>
                  <span 
                      class="field" 
                      v-for=" (action, indexAction) in item.Actions "
                      :key="indexAction" 
                      style="padding-left: 32px; margin-bottom: 16px; width:100px; display: inline-block;"
                  >
                      <b-checkbox 
                          v-model="itemPermissions[index].Actions[indexAction].HasPermission"
                          :value=" action.HasPermission "
                          :checked=" itemPermissions[index].Actions[indexAction].HasPermission "
                          :disabled=" !itemPermissions[index].HasPermission "
                      >
                          {{ action.Action }}
                      </b-checkbox>
                  </span>
                  <hr/>
              </div> 
              <div class="is-pulled-right">
                  <b-button type="is-primary" @click="updatePermission">Update</b-button>
              </div>
          </div>
    </div>
    <div v-else>
        <h3>No Permission Resource</h3>
    </div>
  </BTabContent>
</template>

<script>
import Command from '@/libs/Command'

export default {
  props: {
    msg: String
  },
  created() {        
      this.getPermission()
  },
  data() {
    return {
        roleName: '',
        permissionLoading: false,
        itemPermissions: {},
        defaultPermissions: {},
        updatePermissionCommand: new Command(this.$http, 'PUT', 'PermissionsOfRoleUpdate')
    }
  },
  computed: {
      isLoading() {
          return this.permissionLoading || this.updatePermissionCommand.isLoading
      }
  },
  methods: {
    getPermission() {
        this.permissionLoading = true

        this.$http({
            url: '/json/reply/PermissionsOfRoleGet?RoleId=' + this.$route.params.roleId,            
        })
        .then(resp => {
            this.roleName = resp.data.RoleName
            this.itemPermissions = resp.data.Permissions
            this.defaultPermissions = this.itemPermissions            
        })
        .finally(()=>{
            this.permissionLoading = false
        })        
    },
    setPageAllPermission(index) {
        if(this.itemPermissions[index].HasPermission === true) {
            for(let i=0; i<this.itemPermissions[index].Actions.length; i++) {
                this.itemPermissions[index].Actions[i].HasPermission = false
            } 
        } else {
            for(let i=0; i<this.itemPermissions[index].Actions.length; i++) {
                this.itemPermissions[index].Actions[i].HasPermission = true
            } 
        }
    },
    updatePermission() {
        
        this.$buefy.dialog.confirm({
            title: 'Confirm',
            message:
                "Do you want to update Permission for the Role: <b>'" + this.roleName + "'</b>?",
            confirmText: 'Update',
            type: 'is-info',
            hasIcon: true,
            onConfirm: () => {
                this.updatePermissionCommand.execute({ RoleId: this.$route.params.roleId, Permissions: this.itemPermissions }).then(resp => {
                    if (resp.status === 200) {
                        this.$router.push({ name: 'roleDetail', params: { userId: this.$route.params.userId, tabId: 2 } }) 
                    }
                })
            }
        })
        
    }
  }
};
</script>