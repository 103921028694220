<template>
    <div class="h-100">
       
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="updateVersionCommand.isLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Edit Version</div>
                    </header>
                    <div class="card-content">

                        <b-field horizontal label="Version Name" :type="{'is-danger': $v.input.Name.$error}" :message="{ 'Please enterversion name': !$v.input.Name.required}">
                            <b-input type="text" name="vname" v-model="input.Name" ref="note"></b-input>
                        </b-field>

                    </div>

                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button class="button has-border-transparent ml-12" @click="$router.go(-1)"  data-cy="cancel">Cancel</button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Update</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
    name: 'ManageVersionEdit',
    props: {
        'msg': String
    },
    data() {
        return {
            input: {
                Name: ''
            },
            getVersionCommand: new Query(this.$http, 'ProductVersionsGet', { Ids: this.$route.params.versionId }),
            updateVersionCommand: new Command(this.$http, 'PUT', 'ProductVersionsUpdate'),
        }
    },
    validations: {
        input: {
            Name: {
                required
            }
        }            
    },
    created() {
        this.getVersionCommand.execute().then(() => {            
            let item = this.getVersionCommand.firstRow
            this.input.Name = item.Name            
        })
    },
    computed: {
        isLoading() {
            return this.getVersionCommand.isLoading || this.updateVersionCommand.isLoading
        }
    },
    methods: {
        submitForm() {
            this.$v.$reset();
            this.$v.$touch();

            if (this.$v.$error) 
                return;  
            this.updateItem()
        },
        updateItem() {            
            this.updateVersionCommand
            .execute({ 
                ProductVersions: [{
                    Id: this.$route.params.versionId,
                    Name: this.input.Name
                }] 
            })
            .then(resp => {
                if(resp.status === 200){
                    this.$router.go(-1)
                }
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.is-remark{
    color: red;
    font-size: 12px;
    font-style: italic;
}
</style>
