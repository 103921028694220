<template>
    <b-field>
        <b-input
            class="search-box"
            v-model="searchValue"
            type="search"
            icon="magnify"
            :placeholder="placeholder"
            @keyup.native.enter="onSubmit()"
        >
        </b-input>
        <button class="delete is-small" :style="{ display: canSearch ? 'block' : 'none' }" @click="onClear()"></button>
        <p class="control">
            <b-button
                label="Search"
                :type="{ 'is-primary is-outlined': canSearch }"
                :disabled="!canSearch"
                @click="onSubmit()"
            />
        </p>
    </b-field>
</template>

<script>
export default {
    name: 'BSearchBox',
    props: {
        placeholder: {
            type: [String],
            default: 'Search...',
        },
        initValue: {
            type: [String],
            default: '',
        },
    },
    data() {
        return {
            searchValue: '',
            submittedValue: '',
        }
    },
    mounted() {
        // console.log('value: ', this.value)
        this.searchValue = this.initValue.toString()
    },
    computed: {
        canSearch() {
            return this.searchValue && this.searchValue.length > 0
        },
    },
    methods: {
        onClear() {
            this.searchValue = ''
            this.onSubmit()
        },
        onSubmit() {
            this.submittedValue = this.searchValue
            this.$emit('submit', this.submittedValue.toString())
        },
        clearDirty() {
            // console.log('clearDirty')
            this.searchValue = this.submittedValue
        },
    },
}
</script>

<style lang="scss" scoped>
.field /deep/ .has-addons {
    position: relative;
    display: flex;
    align-items: center;
}

button.delete {
    position: absolute;
    right: 80px;
    z-index: 10;
}
</style>
