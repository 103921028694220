var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu"},_vm._l((_vm.items),function(item){return _c('li',{directives:[{name:"permit",rawName:"v-permit",value:({
            resources: item.resources,
            any: true,
            actions: ['View', 'Create', 'Edit', 'Delete'],
        }),expression:"{\n            resources: item.resources,\n            any: true,\n            actions: ['View', 'Create', 'Edit', 'Delete'],\n        }"}],key:item.name + 'parent',class:{ active: item.is_active }},[(item.is_active)?_c('span',{staticClass:"menu-item",class:{ 'has-text-weight-bold': item.is_active }},[_c('span',{staticClass:"icon",class:{ 'no-icon': !item.childs || item.childs.length == 0 }},[_c('i',{staticClass:"mdi mdi-24px mdi-menu-down"})]),_c('span',[_vm._v(_vm._s(item.title))])]):_c('router-link',{staticClass:"menu-item",attrs:{"to":{ name: item.routeName }}},[_c('span',{staticClass:"icon",class:{ 'no-icon': !item.childs || item.childs.length == 0 }},[_c('i',{staticClass:"mdi mdi-24px mdi-menu-right"})]),_c('span',[_vm._v(_vm._s(item.title))])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(item.is_active && item.childs && item.childs.length > 0),expression:"item.is_active && item.childs && item.childs.length > 0"}],key:item.name,staticClass:"submenu"},_vm._l((item.childs),function(child){return _c('li',{directives:[{name:"permit",rawName:"v-permit",value:({
                    resources: child.resources,
                    any: true,
                    actions: ['View', 'Create', 'Edit', 'Delete'],
                }),expression:"{\n                    resources: child.resources,\n                    any: true,\n                    actions: ['View', 'Create', 'Edit', 'Delete'],\n                }"}],key:child.name,class:{ active: child.is_active }},[(child.is_active)?_c('span',{staticClass:"submenu-item",class:{ 'has-text-weight-bold': child.is_active }},[_vm._v(_vm._s(child.title))]):_c('router-link',{staticClass:"submenu-item",attrs:{"to":{ name: child.routeName }}},[_vm._v(_vm._s(child.title))])],1)}),0)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }