<template>
    <div>
        <b-autocomplete
            v-model="year"
            placeholder="Year"
            :keep-first="false"
            :open-on-focus="true"
            :data="yearData"
            :clearable="true"
            style="width:100px; display: inline-block;"
            @select="(value) => selectYear(value)"
        >
        </b-autocomplete>
        <p>Year</p>

        <b-autocomplete
            v-model="month"
            placeholder="Month"
            :keep-first="false"
            :open-on-focus="true"
            :data="monthData"
            :clearable="true"
            style="width:100px; display: inline-block;"
            @select="(value) => selectMonth(value)"
        >
        </b-autocomplete>
        <p>Month</p>
    </div>
</template>

<script>
export default {
    props: {
        value:{
            type: [Number],
            default: 1
        }
    },
    data() {
        
        return {
            yearData: [0,1,2,3,4,5,6,7,8,9,10],
            year: null,
            monthData: [0,1,2,3,4,5,6,7,8,9,10,11,12],
            month: null
        }
    },
    methods: {
        calculate() {
            if(this.value == 0){
                this.year = 0
                this.month = 0
            } else {
                this.year = Math.floor(this.value / 12)
                this.month = this.value % 12
            }
        },
        selectYear(value){
            this.year = value ? value : 0
        },
        selectMonth(value){
            this.month = value ? value : 0
        }
    },
    computed: {
        monthTotal() {
            return this.year * 12 + this.month
        }
    },
    watch: {
        year() {
            //if(this.year == 0 && this.month == 0) this.month = 1
            // this.$emit('input', this.monthTotal)
            this.$emit('change', this.monthTotal)
        },
        month() {
            //if(this.year == 0 && this.month == 0) this.month = 1
            // this.$emit('input', this.monthTotal)
            this.$emit('change', this.monthTotal)
        },
        value() {
            this.calculate()
        }
    },
    mounted() {
        this.calculate()
    }
}
</script>

<style scoped>
    div {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        align-content: stretch;
    }
    .autocomplete >>> input{
        border: 1px solid #ccc !important;
    }
    p{
        padding: 8px 20px 8px 8px;
    }

    span{
        margin: 0 8px;
        min-height: 35px;
        display: inline-flex;
        align-items: center;
        /* border: 1px solid aqua; */
    }
</style>