let vue
let duringPopState
// newValue,
const pophandlers = []
let __root = null
// var scheduled = false

// const startLocalSync = (storageName) => {
//     return function(vm, path) {
//         var currentlyUpdating = false
//         window.addEventListener('storage', function(event) {
//             if (event.key == storageName + path && !currentlyUpdating)
//                 vue.set(vm, event.key.replace(storageName, ''), JSON.parse(event.newValue))
//             currentlyUpdating = true
//             vm.$nextTick(function() {
//                 currentlyUpdating = false
//             })
//         })

//         var existingValue = localStorage.getItem(storageName + path)
//         if (existingValue) vue.set(vm, path, JSON.parse(existingValue))

//         vm.$watch(
//             path,
//             function(newVal, oldVal) {
//                 if (currentlyUpdating) return
//                 currentlyUpdating = true
//                 localStorage.setItem(storageName + path, JSON.stringify(newVal))
//                 vm.$nextTick(function() {
//                     currentlyUpdating = false
//                 })
//             },
//             {
//                 deep: true,
//                 immidiate: true,
//             }
//         )

//         return function() {
//             // Stop syncing, deconstruct, etc.
//         }
//     }
// }

// const isValidDate = (date) => {
//     return date instanceof Date && !isNaN(date.getTime())
// }

const getPropType = (obj, showFullClass) => {
    // get toPrototypeString() of obj (handles all types)
    if (showFullClass && typeof obj === 'object') {
        return Object.prototype.toString.call(obj)
    }
    if (obj == null) {
        return (obj + '').toLowerCase()
    } // implicit toString() conversion

    var deepType = Object.prototype.toString
        .call(obj)
        .slice(8, -1)
        .toLowerCase()
    if (deepType === 'generatorfunction') {
        return 'function'
    }

    // Prevent overspecificity (for example, [object HTMLDivElement], etc).
    // Account for functionish Regexp (Android <=2.3), functionish <object> element (Chrome <=57, Firefox <=52), etc.
    // String.prototype.match is universally supported.

    return deepType.match(/^(array|bigint|date|error|function|generator|regexp|symbol)$/)
        ? deepType
        : typeof obj === 'object' || typeof obj === 'function'
        ? 'object'
        : typeof obj
}

const defaultConverter = {
    fromUrl: (urlValue, targetType) => {
        if (targetType === 'string') return urlValue.toString()
        if (targetType === 'date') return new Date(urlValue)

        return urlValue
    },
    toUrl: (propValue) => {
        const propType = getPropType(propValue)

        if (propType === 'date') {
            return propValue.toISOString()
        }

        return propValue
    },
}

const createUrlFromPropValue = (propName, propValue) => {
    var pattern, url
    if (propValue && typeof propValue == 'object') {
        propValue = JSON.stringify(propValue)
    }
    url = window.location.toString()
    pattern = new RegExp('\\b(' + propName + '=).*?(&|$)')
    if (url.search(pattern) >= 0) {
        if (!propValue)
            return url
                .replace(pattern, '')
                .replace('&&', '&')
                .replace('?&', '?')
        else return url.replace(pattern, '$1' + encodeURIComponent(propValue) + '$2')
    } else {
        if (!propValue) return url
        else
            return (url + (url.indexOf('?') > 0 ? '&' : '?') + propName + '=' + encodeURIComponent(propValue))
                .replace('&&', '&')
                .replace('?&', '?')
    }
}

const getUrlValue = (propName) => {
    var pattern, result, url
    url = window.location.toString()
    pattern = new RegExp('\\b' + propName + '=(.*?)(&|$)')
    result = pattern.exec(url)
    if ((result != null ? result.length : void 0) >= 1) {
        var val = decodeURIComponent(result[1])
        try {
            val = JSON.parse(val)
        } catch (e) {
            // Wasnt json. Do nothing.
        }
        if (val == 'false') return false
        if (typeof val == 'undefined') return null
        return val
    }
}

const getUrlSyncFn = (prop) => {
    let fromUrl,
        toUrl,
        history = false
    if (typeof prop == 'object') {
        fromUrl = prop.fromUrl
        toUrl = prop.toUrl
        history = prop.history ?? false
        prop = prop.key ?? prop
    }
    fromUrl = fromUrl && typeof fromUrl === 'function' ? fromUrl : defaultConverter.fromUrl
    toUrl = toUrl && typeof toUrl === 'function' ? toUrl : defaultConverter.toUrl

    return (vm, path) => {
        const o = __root ? vm[__root] : vm
        // console.log(o, path)
        const propType = getPropType(o[path])
        // console.log(`key: ${prop}, type: ${propType}, value: ${vm[path]}`)

        // Get value if there's already in url
        let urlValue = getUrlValue(prop)
        if (urlValue) {
            let initialPropValue = fromUrl(urlValue, propType)
            // Set value from url -> vue's data
            vue.set(o, path, initialPropValue)
        } else {
            // Set current value -> url
            const toUrlValue = toUrl(o[path])
            var newUrl = createUrlFromPropValue(prop, toUrlValue)
            window.history.replaceState(null, '', newUrl)
        }

        const watchedPath = __root ? `${__root}.${path}` : path

        vm.$watch(
            watchedPath,
            (newVal, oldVal) => {
                var newUrl

                if (duringPopState) {
                    return
                }
                // Update new value -> url
                newUrl = createUrlFromPropValue(prop, newVal)

                if (history) {
                    window.history.pushState(null, '', newUrl)
                } else {
                    window.history.replaceState(null, '', newUrl)
                }

                pophandlers.map((handler) => {
                    handler()
                })
            },
            { deep: true, sync: true }
        )

        // Handler: url -> vue's data on url changed
        var handler = () => {
            // if (duringPopState) return;
            duringPopState = true
            let newValue = getUrlValue(prop)
            if (newValue) {
                let newPropValue = fromUrl(newValue, propType)
                vue.set(o, path, newPropValue)
            }

            vm.$nextTick(() => {
                duringPopState = false
            })
        }
        pophandlers.push(handler)

        return function() {}
    }
}

const sync = {
    destroyed: function() {
        this._stopSyncFuncs.map((fn) => {
            fn()
        })
    },
    asyncData: function() {},
    created: function() {
        // console.warn("[vue-sync] created()")
        if (!vue) {
            console.warn('[vue-sync] not installed!')
            return
        }
        var vm = this
        vm._stopSyncFuncs = []

        // handle `url` options
        var urlObject = vm.$options.url
        if (typeof urlObject == 'function') urlObject = urlObject()
        if (urlObject) {
            // On the server-side, check if we have a $route, and init the data from there.
            if (typeof window == 'undefined' && typeof vm.$route !== 'undefined') {
                Object.keys(urlObject).map((prop) => {
                    if (!urlObject.hasOwnProperty(prop)) return
                    var val = decodeURIComponent(vm.$route.query[prop])
                    try {
                        val = JSON.parse(val)
                    } catch (e) {
                        // Wasnt json. Do nothing.
                    }
                    vue.set(vm, prop, val)
                })
            } else if (typeof window !== 'undefined') {
                window.addEventListener('popstate', () => {
                    pophandlers.map((h) => {
                        h()
                    })
                })
                Object.keys(urlObject).map((prop) => {
                    // If __root is defined then use as parent object of all props
                    //
                    if (prop == '__root' && urlObject[prop]) {
                        __root = urlObject[prop]
                        return
                    }

                    // Extract each props to url
                    //
                    if (urlObject.hasOwnProperty(prop)) {
                        var syncFn = getUrlSyncFn(urlObject[prop])
                        vm._stopSyncFuncs.push(syncFn(vm, prop))
                    }
                })
            } else {
                console.log(
                    '[vue-sync] If you run this server-side, please make available a global `context` object with the request query params.'
                )
            }
        }
    },
}

export default {
    install: (Vue, options) => {
        vue = Vue

        Vue.mixin(sync)

        // console.log('VueSync installed')
    },
}
