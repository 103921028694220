<template>
  <BTabContent :loading="productQuery.isLoading">
    <div v-if="details && !productQuery.isLoading">
      <div class="detail-item-title">Product Name</div>
      <div class="detail-item-content">{{ details.Name }}&nbsp;</div>
      <div class="detail-item-title">Activation Method</div>
      <div class="detail-item-content">{{ details.ActivationMethod }}&nbsp;</div>                            
      <div class="detail-item-title">Max Activations</div>
      <div class="detail-item-content">{{ details.MaxActivations }}&nbsp;</div>
      <div class="detail-item-title">Multiple Activations</div>
      <div class="detail-item-content">
          <span v-if="details.MultipleActivations">Yes</span><span v-else>No</span></div>
    </div>
    <div v-else>
        <!-- <p>Details not found</p> -->
    </div>
  </BTabContent>
</template>

<script>
import Query from '@/libs/Query'

export default {
  props: {
    msg: String
  },
  data() {    
    return {
      productQuery: new Query(this.$http, 'ProductsGet', { Ids: this.$route.params.productId })
    }
  },
  created() {        
      this.productQuery.execute()
  },
  computed: {
      details() {
          return this.productQuery.firstRow
      }
  }
};
</script>