<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Register Machine</div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Model*"
                            :type="{ 'is-danger': $v.input.ModelId.$error }"
                            :message="{ 'Please select Model': !$v.input.ModelId.required }"
                        >
                            <b-select
                                name="model"
                                v-model="input.ModelId"
                                ref="machineModel"
                                placeholder="Select a Machine Model"
                            >
                                <option
                                    v-for="(option, index) in machineModelsQuery.Rows"
                                    :value="option.Id"
                                    :key="index"
                                >
                                    {{ option.Name }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field
                            horizontal
                            label="Serial number*"
                            :type="{ 'is-danger': $v.input.SerialNumber.$error }"
                            :message="{ 'Serial Number': $v.input.SerialNumber.$error }"
                        >
                            <div>
                                <b-input
                                    name="serialNumber"
                                    v-model="input.SerialNumber"
                                    ref="machineSerialNumber"
                                    placeholder="One item per line."
                                    type="textarea"
                                    expanded
                                    rows="10"
                                ></b-input>
                                <p v-if="totalText != ''" style="margin:5px 0; color:#999;">{{ totalText }}</p>
                            </div>
                        </b-field>

                        <b-field
                            horizontal
                            label="Manufactured date"
                            :type="{ 'is-danger': $v.input.manufactured.$error }"
                            :message="{ 'Please select Manufactured date': $v.input.manufactured.$error }"
                        >
                            <b-datepicker
                                v-model="input.manufactured"
                                placeholder="Type or select a date..."
                                icon="calendar-today"
                                style="display: block; width:130px;"
                                :date-formatter="dateFormatter"
                                :date-parser="dateParser"
                                editable
                            >
                            </b-datepicker>
                        </b-field>

                        <b-field horizontal label="Customer">
                            <div class="assigned-component">
                                <div class="i-relative">
                                    <b-field>
                                        <!-- <b-autocomplete
                                            v-model="unassignedCustomerKeyword"
                                            :data="unassignedCustomerQuery.Rows"
                                            :loading="unassignedCustomerQuery.isLoading"
                                            :keep-first="true"
                                            :open-on-focus="true"
                                            :clearable="true"
                                            placeholder="Search to assign Customer"
                                            icon="magnify"
                                            field="Name"
                                            @focus="getUnassignedCustomer(unassignedCustomerKeyword)"
                                            @typing="(value) => getUnassignedCustomer(value)"
                                            @input="null"
                                            @select="(value) => selectCustomer(value)"
                                            style="width:50%;"
                                        >
                                        </b-autocomplete> -->
                                        <BCustomerPicker @onItemSelect="(value) => selectCustomer(value)" />
                                    </b-field>
                                </div>
                            </div>
                        </b-field>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;" v-if="input.CustomerId != ''">
                        <h1 class="title">License</h1>
                        <b-field
                            horizontal
                            label="Product*"
                            :type="{ 'is-danger': $v.input.lProduct.$error }"
                            :message="{ 'Please select a Product': $v.input.lProduct.$error }"
                        >
                            <!-- <b-autocomplete
                                v-model="productKeyword"
                                :data="productQuery.Rows"
                                :loading="productQuery.isLoading"
                                :keep-first="true"
                                :open-on-focus="true"
                                :clearable="true"
                                placeholder="Search Product name..."
                                icon="magnify"
                                field="Name"
                                @focus="getProduct(productKeyword)"
                                @typing="(value) => getProduct(value)"
                                @select="(value) => selectProduct(value)"
                                style="width:50%;"
                            >
                            </b-autocomplete> -->

                            <BExtAutoComplete
                                QueryAPIName="ProductsQuery"
                                placeholderText="Search Product name..."
                                @onItemSelect="(value) => selectProduct(value)"
                                ref="productAutoComplate"
                            />
                        </b-field>

                        <b-field
                            horizontal
                            label="Version*"
                            :type="{ 'is-danger': $v.input.lProductVersion.$error }"
                            :message="{ 'Please select a Version': $v.input.lProductVersion.$error }"
                        >
                            <!-- <b-autocomplete
                                v-model="productVersionKeyword"
                                :data="productVersionQuery.Rows"
                                :loading="productVersionQuery.isLoading"
                                :keep-first="true"
                                :open-on-focus="true"
                                :clearable="true"
                                placeholder="Search Version Name"
                                icon="magnify"
                                field="Name"
                                @focus="getVersion(productVersionKeyword)"
                                @typing="(value) => getVersion(value)"
                                @select="(value) => selectVersion(value)"
                                :disabled="input.lProduct == ''"
                                style="width:50%;"
                            >
                            </b-autocomplete> -->

                            <BExtAutoComplete
                                QueryAPIName="ProductVersionsOfProductQuery"
                                :QueryParams="{ Keyword: '', ProductId: input.lProduct }"
                                placeholderText="Search Version name..."
                                @onItemSelect="(value) => selectVersion(value)"
                                :isDisabled="input.lProduct == ''"
                                ref="productVersionAutoComplate"
                            />
                        </b-field>

                        <b-field horizontal label="Features" v-if="input.lFeatures.length > 0">
                            <div>
                                <b-table :data="input.lFeatures" checkable :checked-rows.sync="selectedRows">
                                    <b-table-column label="Name" v-slot="props">
                                        {{ props.row.Name }}
                                    </b-table-column>

                                    <b-table-column label="Limits" width="130" v-slot="props">
                                        <div v-if="props.row.IsLimitable">
                                            <b-numberinput
                                                v-model="props.row.Limits"
                                                style="width:130px"
                                            ></b-numberinput>
                                        </div>
                                        <div v-else>
                                            <div style="text-align:left;">N/A</div>
                                        </div>
                                    </b-table-column>

                                    <template slot="empty">
                                        <section class="section">
                                            <div class="content has-text-grey has-text-centered">
                                                <p>
                                                    <b-icon icon="blur" size="is-mediam"> </b-icon>
                                                </p>
                                                <p>No Item.</p>
                                            </div>
                                        </section>
                                    </template>
                                </b-table>
                            </div>
                        </b-field>

                        <b-field
                            horizontal
                            label="License Type*"
                            :type="{ 'is-danger': $v.input.lType.$error }"
                            :message="{ 'Please select a License Type': $v.input.lType.$error }"
                        >
                            <b-select
                                name="licenseType"
                                v-model="input.lType"
                                ref="licenseType"
                                placeholder="Select a License Type"
                            >
                                <option v-for="(option, index) in licenseTypes" :value="option" :key="index">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field
                            horizontal
                            label="Period*"
                            v-if="input.lType != 'Perpetual'"
                            :type="{ 'is-danger': $v.input.lPeriod.$error }"
                            :message="{ 'Please select a Period': $v.input.lPeriod.$error }"
                        >
                            <b-period
                                v-model="input.lPeriod"
                                @change="
                                    (value) => {
                                        this.input.lPeriod = value
                                    }
                                "
                                v-if="input.lType != 'Perpetual'"
                            />
                        </b-field>

                        <b-field 
                            horizontal 
                            label="Max Activations*"
                            :type="{ 'is-danger': $v.input.lMaxActivations.$error }"
                            :message="{ 'Value is out of range': $v.input.lMaxActivations.$error }"
                            >
                            <BMaxActivation :value="input.lMaxActivations" :maxActivate="maxActivationFromProduct" @onChange="(value) => { input.lMaxActivations = value }" />
                        </b-field>

                        <b-field horizontal label="Note">
                            <b-input type="textarea" v-model="input.lNote" ref="note"></b-input>
                        </b-field>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;" v-if="input.CustomerId != ''">
                        <div>
                            <h1 class="title">Warranty</h1>

                            <b-field
                                horizontal
                                label="Period*"
                                :type="{ 'is-danger': $v.input.wPeriod.$error }"
                                :message="{ 'Please select a Period': $v.input.wPeriod.$error }"
                            >
                                <b-period
                                    v-model="input.wPeriod"
                                    @change="
                                        (value) => {
                                            this.input.wPeriod = value
                                        }
                                    "
                                />
                            </b-field>

                            <b-field
                                horizontal
                                label="Begin date"
                                :type="{ 'is-danger': $v.input.wBeginDate.$error }"
                                :message="{ 'Please select Begin Date': $v.input.wBeginDate.$error }"
                            >
                                <b-datepicker
                                    ref="beginDate"
                                    v-model="input.wBeginDate"
                                    placeholder=""
                                    icon="calendar-today"
                                    style="display: block;width:130px;"
                                    :date-formatter="dateFormatter"
                                    :date-parser="dateParser"
                                    editable
                                >
                                </b-datepicker>
                            </b-field>

                            <b-field horizontal label="Note">
                                <b-input type="textarea" name="note" v-model="input.wNote" ref="note"></b-input>
                            </b-field>
                        </div>
                    </div>

                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Register</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import Get from '@/libs/Get'
import { required, numeric, minValue, maxValue, requiredIf } from 'vuelidate/lib/validators'
import BPeriod from '@/components/control/BPeriod'
import BMaxActivation from '@/components/control/BMaxActivation'
import BExtAutoComplete from '@/components/common/BExtAutoComplete'
import BCustomerPicker from '@/components/control/BCustomerPicker'

export default {
    components: {
        BPeriod,
        BMaxActivation,
        BCustomerPicker,
        BExtAutoComplete
    },
    name: 'ManageMachineCreate',
    props: {
        msg: String,
    },
    data() {
        return {
            maxActivationFromProduct: 1,
            input: {
                SerialNumber: '',
                ModelId: null,
                manufactured: new Date(),
                CustomerId: '',
                LicenseId: '',

                lProduct: '',
                lProductVersion: '',
                lType: null,
                lPeriod: 1,
                lMaxActivations: 1,
                lFeatures: [],
                lNote: '',

                wPeriod: 1,
                wBeginDate: new Date(),
                wNote: '',
            },
            createMachineCommand: new Command(this.$http, 'POST', 'MachinesAdd'),
            machineModelsQuery: new Query(this.$http, 'MachineModelsQuery', { keyword: '', cache: true }),

            // Assigned Customer
            // unassignedCustomerKeyword: '',
            // unassignedCustomerQuery: new Query(
            //     this.$http,
            //     'CustomersQuery',
            //     { Keyword: '', ExcludeByMachineId: '' },
            //     1,
            //     0,
            //     'Name'
            // ),

            // productKeyword: '',
            // productQuery: new Query(
            //     this.$http,
            //     'ProductsQuery',
            //     { Keyword: '', ActivationMethods: 'Machine' },
            //     1,
            //     0,
            //     'Name'
            // ),

            // productVersionKeyword: '',
            // productVersionQuery: new Query(
            //     this.$http,
            //     'ProductVersionsOfProductQuery',
            //     { ProductId: '', Keyword: '' },
            //     1,
            //     0,
            //     'Name'
            // ),

            schemaGet: new Get(this.$http, 'LicenseSchemaGet', { ProductVersionId: '' }),

            selectedRows: [],
            licenseTypes: ['Trial', 'Subscription', 'Perpetual'],

            totalText: '',
        }
    },
    validations() {
        return {
            input: {
                SerialNumber: {
                    required,
                },
                ModelId: {
                    required,
                },
                manufactured: {
                    required,
                },

                //License
                lProduct: {
                    required: requiredIf(function() {
                        return this.input.CustomerId != ''
                    }),
                },
                lProductVersion: {
                    required: requiredIf(function() {
                        return this.input.CustomerId != ''
                    }),
                },
                lType: {
                    required: requiredIf(function() {
                        return this.input.CustomerId != ''
                    }),
                },
                lPeriod: {
                    required: requiredIf(function() {
                        return this.input.CustomerId != ''
                    }),
                },
                lMaxActivations: {
                    numeric,
                    minValue: minValue(1),
                    maxValue: maxValue(this.maxActivationFromProduct),
                    required: requiredIf(function() {
                        return this.input.CustomerId != ''
                    }),
                },

                //Warranty
                wPeriod: {
                    required: requiredIf(function() {
                        return this.input.CustomerId != ''
                    }),
                },
                wBeginDate: {
                    required: requiredIf(function() {
                        return this.input.CustomerId != ''
                    }),
                },
            }
        }
    },
    created() {
        this.machineModelsQuery.execute().then(() => {
            this.input.ModelId = this.machineModelsQuery.firstRow.Id
        })
    },
    computed: {
        isLoading() {
            return this.createMachineCommand.isLoading || this.machineModelsQuery.isLoading
        },
        assignedCustomers() {
            return this.assignedCustomerQuery.Rows
        },
        SerialNumber() {
            return this.input.SerialNumber
        },
    },
    methods: {
        dateFormatter(d) {
            return d.toLocaleDateString('en-GB')
        },
        dateParser(d) {
            let DD = d.substring(0, 1)
            let MM = d.substring(3, 4)
            let YYYY = d.substring(6, 9)
            let parseObj = new Date(Date.parse(YYYY + '-' + MM + '-' + DD))
            return parseObj
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.createMachine()
        },
        createMachine() {
            let items = []
            let serials = this.input.SerialNumber.split('\n')
            serials = serials.filter((so) => so.trim() != '')
            serials.forEach((element) => {
                let license = undefined
                let warranty = undefined
                if (this.input.CustomerId != '') {
                    warranty = {
                        Period: this.input.wPeriod,
                        BeginDate: this.input.wBeginDate,
                        Note: this.input.wNote,
                    }

                    license = {
                        ProductVersionId: this.input.lProductVersion,
                        Type: this.input.lType,
                        Periods: this.input.lPeriod,
                        MaxActivations: this.input.lMaxActivations,
                        Features: this.input.lFeatures,
                        Note: this.input.lNote,
                    }
                }

                if (license && warranty) {
                    items.push({
                        CustomerId: this.input.CustomerId,
                        Warranty: warranty,
                        License: license,
                        ModelId: this.input.ModelId,
                        SerialNumber: element.trim(),
                        ManufacturedDate: this.input.manufactured,
                    })
                } else {
                    items.push({
                        CustomerId: this.input.CustomerId,
                        //"Warranty": warranty,
                        //"License": license,
                        ModelId: this.input.ModelId,
                        SerialNumber: element.trim(),
                        ManufacturedDate: this.input.manufactured,
                    })
                }
            })

            this.createMachineCommand
                .execute({
                    Machines: items,
                })
                .then((resp) => {
                    if (resp.status === 201) {
                        this.$router.push({ name: 'machineList' })
                    }
                })
        },
        // getUnassignedCustomer(value) {
        //     this.unassignedCustomerQuery.execute({
        //         Keyword: value,
        //     })
        // },
        selectCustomer(selectedItem) {
            if (selectedItem) {
                this.input.CustomerId = selectedItem.Id
            } else {
                this.input.CustomerId = ''
            }
        },
        // getProduct(value) {
        //     this.productQuery.execute({ Keyword: value })
        // },
        selectProduct(value) {
            this.input.lProduct = value ? value.Id : ''

            if(this.input.lProduct == ''){
                this.input.lProductVersion = ''
                this.$refs.productVersionAutoComplate.clear()
            }else {
                this.ActivationKeyVisible = value.ActivationMethod == 'Machine'
                this.maxActivationFromProduct = value.MaxActivations
            }            
        },
        // getVersion(value) {
        //     this.productVersionQuery.execute({ ProductId: this.input.lProduct, Keyword: value })
        // },
        selectVersion(value) {
            this.input.lProductVersion = value.Id

            this.schemaGet.execute({ ProductVersionId: value.Id }).then(() => {
                if (this.schemaGet.Data.Features) {
                    this.input.lFeatures = this.schemaGet.Data.Features
                    this.input.lFeatures.forEach((element) => {
                        element.Limits = 0
                    })
                } else {
                    this.input.lFeatures = []
                }
            })
        },
    },

    watch: {
        SerialNumber() {
            //let len = this.input.SerialNumber.split("\n").length
            let serialsNo = this.input.SerialNumber.split('\n')
            serialsNo = serialsNo.filter((so) => so.trim() != '')
            this.totalText = 'Total ' + serialsNo.length + ' ' + this.$pluralize('Serial number', serialsNo.length)
        },
    },
}
</script>
