<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view">
                <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

                <!-- Edit -->
                <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/machine" exact><span class="item-link">Machine</span></router-link>
                        </li>
                        <li>
                            <router-link :to="'/machine/' + machineId + '/tab/0'" exact
                                ><span class="item-link">{{ itemDetail.SerialNumber }}</span></router-link
                            >
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page">Edit {{ itemDetail.SerialNumber }}</a>
                        </li>
                    </ul>
                </nav>
                <div class="card mb-48">
                    <header class="card-header card-header-edit">
                        <div class="edit-title">Edit {{ itemDetail.SerialNumber }}</div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Model*"
                            :type="{ 'is-danger': $v.input.Model.$error }"
                            :message="{ 'Please select model': $v.input.Model.$error && !$v.input.Model.required }"
                        >
                            <b-select
                                name="model"
                                v-model="input.Model.Id"
                                ref="machineModel"
                                placeholder="Select a machine model"
                            >
                                <option
                                    v-for="(option, index) in machineModelsQuery.Rows"
                                    :value="option.Id"
                                    :key="index"
                                >
                                    {{ option.Name }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field
                            horizontal
                            label="Serial number*"
                            :type="{ 'is-danger': $v.input.SerialNumber.$error }"
                            :message="{ SerialNumber: $v.input.SerialNumber.$error && !$v.input.SerialNumber.required }"
                        >
                            <b-input
                                name="serialNumber"
                                v-model="input.SerialNumber"
                                ref="machineSerialNumber"
                                expanded
                            ></b-input>
                        </b-field>

                        <b-field horizontal label="Manufactured date" message="">
                            <b-datepicker
                                v-model="input.ManufacturedDate"
                                placeholder="Type or select a date..."
                                icon="calendar-today"
                                style="display: block;"
                                :date-formatter="dateFormatter"
                                :date-parser="dateParser"
                                editable
                            >
                            </b-datepicker>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-edit">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm">Update</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ManageMachineEdit',
    props: {
        msg: String,
    },
    data() {
        return {
            /* Parameter */
            machineId: this.$route.params.machineId,
            machineModels: [],

            input: {
                SerialNumber: '',
                Model: {},
                ManufacturedDate: new Date(),
            },
            // Detail Item
            itemDetail: {},

            // Command
            machineQuery: new Query(this.$http, 'MachinesGet', { Ids: this.$route.params.machineId }),
            machineModelsQuery: new Query(this.$http, 'MachineModelsQuery', { keyword: '', cache: true }),
            editmachineCommand: new Command(this.$http, 'PUT', 'MachinesUpdate'),
        }
    },
    validations: {
        input: {
            SerialNumber: {
                required,
            },
            Model: {
                required,
            },
        },
    },
    created() {
        this.machineModelsQuery.execute().then(() => {
            this.getItem()
        })
    },
    computed: {
        isLoading() {
            return this.machineQuery.isLoading || this.machineModelsQuery.isLoading || this.editmachineCommand.isLoading
        },
    },
    methods: {
        dateFormatter(d) {
            return d.toLocaleDateString('en-GB')
        },
        dateParser(d) {
            let DD = d.substring(0, 1)
            let MM = d.substring(3, 4)
            let YYYY = d.substring(6, 9)
            let parseObj = new Date(Date.parse(YYYY + '-' + MM + '-' + DD))
            return parseObj
        },
        getItem() {
            this.machineQuery.execute().then(() => {
                this.itemDetail = this.machineQuery.firstRow
                //this.input = Object.assign(this.input, this.itemDetail)
                this.input.SerialNumber = this.itemDetail.SerialNumber
                this.input.Model = this.itemDetail.Model
                this.input.ManufacturedDate = new Date(this.itemDetail.ManufacturedDate)
            })
        },
        updateMachine() {
            this.editmachineCommand
                .execute({
                    Machines: [
                        {
                            Id: this.machineId,
                            ModelId: this.input.Model.Id,
                            SerialNumber: this.input.SerialNumber,
                            ManufacturedDate: this.input.ManufacturedDate,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$router.go(-1)
                    }
                })
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.updateMachine()
        },
    },
}
</script>
