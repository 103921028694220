<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view">
                <b-loading :is-full-page="false" :active="isLoading"></b-loading>

                <!-- Edit -->
                <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/roles" exact><span class="item-link">Role</span></router-link>
                        </li>
                        <li>
                            <router-link
                                :to="{ name: 'RoleDetail', params: { roleId: this.$route.params.roleId, tabId: 0 } }"
                                exact
                                ><span class="item-link">{{ itemDetail.Name }}</span></router-link
                            >
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page">Edit {{ itemDetail.Name }}</a>
                        </li>
                    </ul>
                </nav>
                <div class="card mb-32 mb-48">
                    <header class="card-header card-header-edit">
                        <div class="edit-title">Edit {{ itemDetail.Name }}</div>
                    </header>
                    <div class="card-content">
                        <div class="content">
                            <b-field
                                horizontal
                                label="Name*"
                                :type="{ 'is-danger': $v.input.name.$error }"
                                :message="{ 'a Role name': $v.input.name.$error }"
                            >
                                <b-input name="name" v-model="input.name" ref="roleName" expanded></b-input>
                            </b-field>

                            <b-field horizontal label="Description">
                                <b-input
                                    name="description"
                                    v-model="input.description"
                                    ref="roleDescription"
                                    expanded
                                ></b-input>
                            </b-field>
                        </div>
                    </div>
                    <footer class="card-footer card-footer-edit">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-primary" @click="submitForm">Update</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CountryList from 'country-list'
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'AccountRolesEdit',
    props: {
        msg: String,
        roleId: Number,
    },
    data() {
        return {
            roleEditId: this.$route.params.roleId,

            input: {
                id: '',
                name: '',
                description: '',
            },
            itemDetail: {},
            roleQuery: new Query(this.$http, 'RolesGet', {
                Ids: this.$route.params.roleId,
            }),
            editRoleCommand: new Command(this.$http, 'PUT', 'RolesUpdate'),
        }
    },
    validations: {
        input: {
            name: {
                required,
            },
        },
    },
    created() {
        this.getItem()
    },
    computed: {
        isLoading() {
            return this.roleQuery.isLoading || this.editRoleCommand.isLoading
        },
    },
    methods: {
        checkBackDetailPage() {
            if (this.sitePath === '0') {
                this.backDetailPage = false
            } else {
                this.backDetailPage = true
            }
        },
        getItem() {
            this.roleQuery.execute().then(() => {
                this.itemDetail = this.roleQuery.firstRow
                //this.input = Object.assign(this.input, this.itemDetail)
                this.input.id = this.itemDetail.Id
                this.input.name = this.itemDetail.Name
                this.input.description = this.itemDetail.Description
            })
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.updateRole()
        },
        updateRole() {
            this.editRoleCommand
                .execute({
                    Roles: [
                        {
                            Id: this.input.id,
                            Name: this.input.name,
                            Description: this.input.description,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$router.go(-1)
                    }
                })
        },
    },
}
</script>
