<template>
  <BTabContent :loading="auditLogQuery.isLoading">
    <div v-if="details">
        <div class="columns">
            <div class="column">
                <div class="detail-item-title">Timestamp</div>
                <div class="detail-item-content">{{ format_date(details.Timestamp) }}&nbsp;</div>  
                <div class="detail-item-title">User</div>
                <div class="detail-item-content">{{ details.User }}&nbsp;</div>  
                <div class="detail-item-title">Role</div>
                <div class="detail-item-content">
                    {{ details.Role }}&nbsp;

                    <!-- <div v-for="(roleObj, index) in details.Role" :key="index" style="display:inline-block;">
                        <span v-if="index > 0">,&nbsp;</span><span>{{ roleObj }}</span>
                    </div> -->
                </div>  
                <div class="detail-item-title">Action</div>
                <div class="detail-item-content">{{ details.Action }}&nbsp;</div>  
                <div class="detail-item-title">Resource</div>
                <div class="detail-item-content">{{ details.Resource }}&nbsp;</div>  
                <div class="detail-item-title">RefKey</div>
                <div class="detail-item-content">{{ details.RefKey }}&nbsp;</div>  
                <div class="detail-item-title">Result</div>
                <div class="detail-item-content">{{ details.Result }}&nbsp;</div>  
                <div class="detail-item-title">Data</div>
                <div class="detail-item-content" style="overflow: scroll"><pre>{{ dataStringify(details.Data) }}</pre></div>  
            </div>
        </div>
    </div>
    <div v-else>
        <!-- <p>Details not found</p> -->
    </div>
  </BTabContent>
</template>

<script>
import Query from '@/libs/Query'
import { format_date } from '@/libs/datetime'

export default {
  props: {
    msg: String
  },
  data() {    
    return {
        auditLogQuery: new Query(this.$http, 'AuditLogsGet', {
            Ids: this.$route.params.auditLogId
        }),

        format_date
    }
  },
  created() {        
      this.auditLogQuery.execute()
  },
  computed: {
      details() {
          return this.auditLogQuery.firstRow
      }
  },
    methods: {
        dataStringify(data) {
            return JSON.stringify(JSON.parse(data), undefined, 2)
        }
    }
};
</script>