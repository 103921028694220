<template>
    <div class="app-container">
        <div class="app-header">
            <app-header />
        </div>
        <div class="app-menu">
            <app-menu />
        </div>
        <div class="app-content">
            <div class="list-layout">
                <slot></slot>
            </div>
        </div>
        <div class="app-footer">
            <app-footer />
        </div>
    </div>
</template>

<script>
import AppHeader from '@/components/views/AppHeader'
import AppFooter from '@/components/views/AppFooter'
import AppMenu from '@/components/views/MainMenu'

export default {
    components: {
        AppHeader,
        AppFooter,
        AppMenu,
    },
}
</script>

<style scoped>
.app-container {
    width: 100%;
    min-width: 1024px;
    height: 100%;

    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        'header header'
        'menu   content'
        'footer footer';
}

.app-header {
    grid-area: header;
    width: 100%;
}
.app-footer {
    grid-area: footer;
    width: 100%;
}

.app-menu {
    grid-area: menu;
    height: 100%;
    width: 200px;
    overflow: hidden;
}

.app-content {
    grid-area: content;
    overflow: hidden;
}

.list-layout {
    width: 100%;
    height: 100%;
    padding: 50px 60px 30px;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
