<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="createCustomerGroupCommand.isLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Create Customer Group</div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Name*"
                            :type="{ 'is-danger': $v.input.name.$error }"
                            :message="{ 'Customer Group Name': $v.input.name.$error }"
                        >
                            <b-input
                                name="name"
                                v-model="input.name"
                                ref="groupName"
                                placeholder="Customer Group Name"
                                expanded
                            ></b-input>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Create</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ManageCusGroupCreate',
    props: {
        msg: String,
    },
    data() {
        return {
            input: {
                name: '',
                ParentId: '',
                CustomerIds: '',
            },
            createCustomerGroupCommand: new Command(this.$http, 'POST', 'CustomerGroupsAdd'),
        }
    },
    validations: {
        input: {
            name: {
                required,
            },
        },
    },
    methods: {
        createItem() {
            this.createCustomerGroupCommand
                .execute({
                    Groups: [
                        {
                            Name: this.input.name,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 201) {
                        this.$router.push({ name: 'customerGroupList' })
                    }
                })
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.createItem()
        },

        // checkInputCusGroupName() {
        //     if (this.input.name) {
        //         this.checkCusGroupName.type = ''
        //         this.checkCusGroupName.message = ''
        //         return true
        //     }

        //     this.checkCusGroupName.type = 'is-danger'
        //     this.checkCusGroupName.message = 'a Customer Group Name'
        //     this.$nextTick(() => this.$refs.cusGroupName.focus())

        //     return false
        // },
        // checkForm() {
        //     if (this.checkInputCusGroupName()) {
        //         return true
        //     }

        //     return false
        // },
        // getCusAsyncDataFocus: debounce(function() {

        //     this.isFetchingCus = true
        //     this.isExistKeywordCus = false

        //     let apiPath = '?' + 'StartRow=0' +
        //         '&' + 'PageSize=10' +
        //         '&' + 'SortColumn=Name' +
        //         '&' + 'SortDirection=Ascending' +
        //         '&' + 'ExcludeByGroupId=' + this.input.id

        //     Axios({
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         url: urlPointOfSales + apiPath
        //     })
        //     .then(resp => {
        //         this.isFetchingCus = false
        //         this.dataPointOfSales = resp.data.Rows
        //     })
        //     .catch((error) => {
        //         this.dataPointOfSales = []
        //         throw error
        //     })
        //     .finally(() => {
        //         this.isFetchingCus = false
        //         if(this.keywordCus.length > 0 && this.keywordCus !== null ){
        //             this.isExistKeywordCus =  true
        //         } else {
        //             this.isExistKeywordCus = false
        //         }
        //     })
        // }, 250),
        // getCusAsyncDataTyping: debounce(function(name) {

        //     this.isFetchingCus = true
        //     this.isExistKeywordCus = false

        //     let apiPath = '?' + 'StartRow=0' +
        //         '&' + 'PageSize=10' +
        //         '&' + 'SortColumn=Name' +
        //         '&' + 'SortDirection=Ascending' +
        //         '&' + 'ExcludeByGroupId=' + this.input.id

        //     if (name.length > 0) {
        //         apiPath = apiPath + '&' + 'Keyword=' + name
        //     }

        //     Axios({
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         url: urlPointOfSales + apiPath
        //     })
        //     .then(resp => {
        //         this.isFetchingCus = false
        //         this.dataPointOfSales = resp.data.Rows
        //     })
        //     .catch((error) => {
        //         this.dataPointOfSales = []
        //         throw error
        //     })
        //     .finally(() => {
        //         this.isFetchingCus = false
        //         if(this.keywordCus.length > 0 && this.keywordCus !== null ){
        //             this.isExistKeywordCus =  true
        //         } else {
        //             this.isExistKeywordCus = false
        //         }
        //     })
        // }, 250),
        // createCusGroup() {
        //     this.isLoading = true

        //     Axios({
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         url: urlCusGroups,
        //         data: {
        //             Groups: {
        //                 Name: this.input.name,
        //                 CusIds: this.input.cusIds,
        //                 ParentId: ''
        //             }
        //         }
        //     })
        //     .then(resp => {
        //         this.isLoading = false
        //         if(resp.status === 201){
        //             this.$router.push('/customerGroup')
        //         }
        //     })
        //     .catch(err => {
        //         this.errorMsg = err.response.data.ResponseStatus.Message
        //         this.isLoading = false
        //         this.openNotify()
        //     })
        // },
        // submitCusGroup() {
        //     if(this.checkForm()) {
        //         this.createCusGroup()
        //     }
        // },
        // fillKeywordCus(e) {
        //     this.keywordCus = e
        //     if(this.keywordCus.length > 0 && this.keywordCus !== null ){
        //         this.isExistKeywordCus =  true
        //     } else {
        //         this.isExistKeywordCus = false
        //     }
        // },
        // clearKeywordCus() {
        //     this.nameCus = ''
        //     this.keywordCus = ''
        //     this.isExistKeywordCus = false
        // },
        // assignCus(option) {

        //     let object = null

        //     if(option) {
        //         object = this.itemPointOfSales.filter(obj => obj.Id === option.Id)

        //         if(object.length === 0) {
        //             this.itemPointOfSales.push(option)
        //             this.input.cusIds.push(option.Id)
        //         }

        //     }

        // },
        // unassignCus(removeId) {
        //     this.itemPointOfSales = this.itemPointOfSales.filter(obj => obj.Id !== removeId)
        //     this.input.cusIds = this.input.cusIds.filter(item => item !== removeId)
        // },
        // startTimer() {
        //     this.interval = setInterval(this.countDown, 1000)
        // },
        // countDown() {
        //     if (this.counter > 0) {
        //         this.counter = this.counter - 1
        //     } else {
        //         this.closeNotify()
        //     }
        // },
        // resetCounter() {
        //     clearInterval(this.interval)
        // },
        // openNotify() {
        //     this.isNotifyActive = true
        //     this.counter = 5
        //     this.startTimer()
        // },
        // closeNotify() {
        //     this.isNotifyActive = false
        //     this.resetCounter()
        // }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
