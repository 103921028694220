import { defineComponent } from '@vue/composition-api'

export default defineComponent({
    inheritAttrs: false,
    props: {
        validator: {
            type: Object,
            required: true,
        },
        message: {
            type: [String, Object],
            required: true,
        },
    },
    data() {
        return {}
    },
        
    render() {
        // console.log(this.$attrs)
        const { label, type, message, ...restProps } = this.$attrs

        // console.log(this.validator)
        const {
            $params: { required },
            $error,
        } = this.validator
        const vLabel = `${label}${required ? '*' : ''}`
        const ErrorMsg = this.message

        return (
            <BField
                horizontal
                label={vLabel}
                type={{ 'is-danger': $error }}
                message={$error ? ErrorMsg : ''}
                {...restProps}
                scopedSlots={this.$scopedSlots}
            />
        )
    },
})
