<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

                <!-- Create -->
                <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link :to="{ name: 'customerGroupList' }" exact
                                ><span class="item-link">Customer Group</span></router-link
                            >
                        </li>
                        <li>
                            <router-link
                                :to="{
                                    name: 'customerGroupDetail',
                                    params: { customerGroupId: parentGroupId, tabs: 0 },
                                }"
                                exact
                                ><span class="item-link">{{ itemDetail.Name }}</span></router-link
                            >
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page">Create Sub-Group of {{ itemDetail.Name }}</a>
                        </li>
                    </ul>
                </nav>
                <div class="card mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Create Sub-Group of {{ itemDetail.Name }}</div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Name*"
                            :type="{ 'is-danger': $v.input.Name.$error }"
                            :message="{ 'Customer Name': $v.input.Name.$error }"
                        >
                            <b-input
                                name="name"
                                v-model="input.Name"
                                ref="subGroupName"
                                placeholder="Customer group name"
                                expanded
                            ></b-input>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button class="button has-border-transparent ml-12" @click="$router.go(-1)">
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Create</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ManageCusGroupCreateSubGroup',
    props: {
        msg: String,
        parentGroupId: String,
    },
    data() {
        return {
            //cusGroupEditId: this.$route.params.parentGroupId,
            itemDetail: { Name: '' },
            input: {
                Name: '',
                ParentId: '',
                CustomerIds: '',
            },
            customerGroupQuery: new Query(this.$http, 'CustomerGroupsGet', {
                Ids: this.$route.params.parentGroupId,
            }),
            createCustomerGroupCommand: new Command(this.$http, 'POST', 'CustomerGroupsAdd'),
        }
    },
    validations: {
        input: {
            Name: {
                required,
            },
        },
    },
    created() {
        //console.log(this.$route)
        this.getItem()
    },
    computed: {
        isLoading() {
            return this.customerGroupQuery.isLoading || this.createCustomerGroupCommand.isLoading
        },
    },
    methods: {
        getItem() {
            this.customerGroupQuery.execute().then(() => {
                this.itemDetail = this.customerGroupQuery.firstRow
            })
        },
        createItem() {
            this.createCustomerGroupCommand
                .execute({
                    Groups: [
                        {
                            Name: this.input.Name,
                            ParentId: this.parentGroupId,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 201) {
                        this.$router.push({
                            name: 'customerGroupDetail',
                            params: { tabId: 1, customerGroupId: this.parentGroupId },
                        })
                    }
                })
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.createItem()
        },
    },
}
</script>
