<template>
    <div class="menu-container">
        <div class="menu-logo">
            <!-- <img src="logo-horizontal.png" alt="LOGO" /> -->
        </div>

        <nav>
            <MenuList :items="top_items" />
            <MenuList :items="bottom_items" />
        </nav>
    </div>
</template>

<script>
import MenuList from './MenuList'

export default {
    name: 'MainMenu',
    props: {},
    components: {
        MenuList,
    },
    data() {
        return {
            top_items: [
                {
                    name: 'Dashboard',
                    title: 'Dashboard',
                    routeName: 'dashboard',
                    resources: ['Metric'],
                },
                {
                    name: 'Manage',
                    title: 'Manage',
                    routeName: 'customerList',
                    resources: ['Customer', 'Customer Group', 'Product', 'Machine', 'License'],
                    childs: [
                        {
                            name: 'Customer',
                            title: 'Customer',
                            routeName: 'customerList',
                            resources: ['Customer'],
                        },
                        {
                            name: 'Customer Group',
                            title: 'Customer Group',
                            routeName: 'customerGroupList',
                            resources: ['CustomerGroup'],
                        },
                        {
                            name: 'Product',
                            title: 'Product',
                            routeName: 'productList',
                            resources: ['Product'],
                        },
                        {
                            name: 'Machine',
                            title: 'Machine',
                            routeName: 'machineList',
                            resources: ['Machine'],
                        },
                        {
                            name: 'MachineLicense',
                            title: 'License - Machine',
                            routeName: 'licenseListMachine',
                            resources: ['License'],
                        },
                        {
                            name: 'ServerLicense',
                            title: 'License - Server',
                            routeName: 'licenseListServer',
                            resources: ['License'],
                        },
                    ],
                },
            ],
            bottom_items: [
                {
                    name: 'Account',
                    title: 'Account',
                    routeName: 'userList',
                    resources: ['User', 'Role'],
                    childs: [
                        {
                            name: 'User',
                            title: 'User',
                            routeName: 'userList',
                            resources: ['User'],
                        },
                        {
                            name: 'Role',
                            title: 'Role',
                            routeName: 'roleList',
                            resources: ['Role'],
                        },
                    ],
                },
                {
                    name: 'Audit Log',
                    title: 'Audit Log',
                    routeName: 'auditlog',
                    resources: ['AuditLog'],
                },
                {
                    name: 'Settings',
                    title: 'Settings',
                    routeName: 'settings',
                    resources: ['System'],
                },
            ],
        }
    },
    created() {
        // console.log('[DEBUG] route: ', this.$route)
    },
    methods: {
        hasParent(name) {
            return name === this.$route.meta.parent
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-container {
    width: 100%;
    min-height: 100%;
    height: 100%;
    padding: 50px 0 20px;
    overflow-y: auto;
    background: #fff;

    display: grid;
    grid-template-rows: auto 1fr;
}

.menu-logo {
    /* padding: 0 0 30px 24px; */

    /* img {
        width: 150px;
    } */
}

nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>
