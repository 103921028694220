<template>
    <MainListLayout>
        <BListview 
            pageTitle="Audit Log"
            :disableSearchByKeyword="true"
            QueryAPIName="AuditLogsQuery"
            :QueryParams="params"

            @onPageSizeChange="(value) => { params.pageSize = value; }"
            @onPageChange="(value) => { params.currentPage = value; }"
            @onDeleted="(value) => { if(value) { selectedRows = []; } }"
            ref="listview">

            <template v-slot:filterInLine>
                <BRapidSearchBox
                    ref="searchBox"
                    :initValue="params.UserKeyword"
                    :isFetching="false"
                    placeholder="Username"
                    @textChange="
                        (value) => {
                            params.UserKeyword = value
                        }
                    "
                />
                &nbsp;&nbsp;&nbsp;
                <BRapidSearchBox
                    ref="searchBox"
                    class="level-item"
                    :initValue="params.RefKeyKeyword"
                    :isFetching="false"
                    placeholder="Ref. Key"
                    @textChange="
                        (value) => {
                            params.RefKeyKeyword = value
                        }
                    "
                />
            </template>

            <template v-slot:filter>
                <LayoutListInline
                    padding-x="0"
                    padding-y="0"
                    gap-x="4"
                    horizontal-align="left"
                    vertical-align="center"
                    :wrap="true"
                >
                    <BTooltip label="Period" position="is-top" type="is-dark" :animated="true">
                        <b-dropdown ref="dropdownCustomOption">
                            <button class="button is-info" slot="trigger">
                                <span>{{ labelCustomOption }}</span>
                                <b-icon icon="chevron-down"></b-icon>
                            </button>
                            <template v-if="flagCustomOption">
                                <b-dropdown-item @click="viewLast7Days">Last 7 days</b-dropdown-item>
                                <b-dropdown-item @click="viewLast28Days">Last 28 days</b-dropdown-item>
                                <b-dropdown-item @click="viewLast90Days">Last 90 days</b-dropdown-item>
                                <b-dropdown-item @click="viewLast365Days">Last 365 days</b-dropdown-item>
                                <hr class="dropdown-divider" />
                                <b-dropdown-item @click="viewCurrentYear">{{ labelCurrentYear }}</b-dropdown-item
                                ><!-- current year -->
                                <b-dropdown-item @click="viewLastYear">{{ labelLastYear }}</b-dropdown-item
                                ><!-- last year -->
                                <hr class="dropdown-divider" />
                                <b-dropdown-item @click="viewCurrentMonth">{{ labelCurrentMonth }}</b-dropdown-item
                                ><!-- current month -->
                                <b-dropdown-item @click="viewLastMonth">{{ labelLastMonth }}</b-dropdown-item
                                ><!-- last month -->
                                <b-dropdown-item @click="viewLast2Month">{{ labelLast2Month }}</b-dropdown-item
                                ><!-- last 2 month -->
                                <hr class="dropdown-divider" />
                                <b-dropdown-item custom>
                                    <div class="dropdown-item-custom" @click="switchCustomOption">
                                        <b-icon icon="calendar"></b-icon>&nbsp;&nbsp;Custom Range
                                    </div>
                                </b-dropdown-item>
                            </template>
                            <template v-else>
                                <b-dropdown-item custom paddingless>
                                    <v-date-picker
                                        mode="range"
                                        v-model="selectedDate"
                                        class="custom-calendar"
                                        @input="setCustomDateRange"
                                        is-inline
                                        is-expanded
                                    >
                                    </v-date-picker>
                                </b-dropdown-item>
                                <hr class="dropdown-divider" />
                                <b-dropdown-item custom>
                                    <div class="dropdown-item-custom" @click="switchCustomOption">
                                        <b-icon icon="format-list-bulleted-type"></b-icon>&nbsp;&nbsp;Option Range
                                    </div>
                                </b-dropdown-item>
                            </template>
                        </b-dropdown>
                    </BTooltip>

                    <BFilter
                        tooltip="Action"
                        :loading="false"
                        v-model="filterAuditLogAction"
                        :options="auditLogActions"
                        @change="
                            (Item) => {
                                params.Actions = Item.Id
                            }
                        "
                    ></BFilter>

                    <BFilter
                        tooltip="Resource"
                        :loading="false"
                        v-model="filterAuditLogResource"
                        :options="auditLogResources"
                        @change="
                            (Item) => {
                                params.Resources = Item.Id
                            }
                        "
                    ></BFilter>

                    <BFilter
                        tooltip="Result"
                        :loading="false"
                        v-model="filterAuditLogResult"
                        :options="auditLogResults"
                        @change="
                            (Item) => {
                                params.Results = Item.Id
                            }
                        "
                    ></BFilter>
                </LayoutListInline>
            </template>

            <template v-slot:default="{ result }">
                <b-table
                    :data="result"
                    :opened-detailed="defaultOpenedDetails"
                    detailed
                    detail-key="Id"
                    backend-pagination
                    backend-sorting
                    :current-page="params.currentPage"
                    :per-page="params.pageSize"
                    :default-sort-direction="params.sortDirection"
                    :default-sort="params.sortColumn"
                    @sort="
                        (field, order) => {
                            params.sortColumn = field
                            params.sortDirection = order
                        }
                    "
                >
                    <b-table-column field="Timestamp" label="Timestamp" sortable v-slot="props">
                        <template v-if="showDetailIcon">
                            <router-link :to="'/auditLog/' + props.row.Id + '/tab/0'" exact>
                                <span class="item-link">{{ format_date(props.row.Timestamp) }}</span>
                            </router-link>
                        </template>
                        <template v-else>
                            <a @click="toggle(props.row)">
                                <router-link :to="'/auditLog/' + props.row.Id + '/tab/0'" exact>
                                    <span class="item-link">{{ format_date(props.row.Timestamp) }}</span>
                                </router-link>
                            </a>
                        </template>
                    </b-table-column>

                    <b-table-column field="User" label="User" sortable v-slot="props">
                        {{ props.row.User }}
                    </b-table-column>

                    <b-table-column field="Action" label="Action" sortable v-slot="props">
                        {{ props.row.Action }}
                    </b-table-column>

                    <b-table-column field="Resource" label="Resource" sortable v-slot="props">
                        {{ props.row.Resource }}
                    </b-table-column>

                    <b-table-column field="RefKey" label="Ref. Key" sortable v-slot="props">
                        {{ props.row.RefKey }}
                    </b-table-column>

                    <b-table-column field="Result" label="Result" sortable v-slot="props">
                        <span class="tag is-success" v-if="props.row.Result === 'OK'">
                            {{ props.row.Result }}
                        </span>
                        <span class="tag is-danger" v-else-if="props.row.Result === 'Error'">
                            {{ props.row.Result }}
                        </span>
                        <span class="tag is-grey" v-else>
                            {{ props.row.Result }}
                        </span>
                    </b-table-column>
                    <template #detail="props">
                        <div class="detail-item-title" style="font-size: 0.875rem;">Data</div>
                        <div v-if="isJson(props.row.Data)" class="detail-item-content i-relative data-code">
                            <div>
                                <pre>{{ dataStringify(props.row.Data) }}</pre>
                            </div>
                        </div>
                        <div v-else class="detail-item-content i-relative data-code">
                            <div>
                                <pre>{{ props.row.Data }}</pre>
                            </div>
                        </div>
                    </template>

                    <template slot="empty">
                        <BEmptyState title="No mached result" subtitle="Please try another criteria" />
                    </template>
                </b-table>
            </template>

            <template v-slot:loadingState="{ loadingRows }">
                <b-table :data="loadingRows">
                    <b-table-column label="Timestamp">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="User">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Action">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Resource">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column field="Ref. Key">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>      

                    <b-table-column label="Result">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>             
                </b-table>
            </template>

        </BListview>

    </MainListLayout>
</template>

<script>
import { MainListLayout } from '@/layouts'
import { format_date } from '@/libs/datetime'
import BListview from '@/components/common/BListview'

export default {
    name: 'AuditLog',
    props: {
        msg: String,
    },
    components: {
        MainListLayout,
        BListview
    },
    url: {
        __root: 'params',
            UserKeyword: { key: 'uw' },
            RefKeyKeyword: { key: 'kw' },
            DateType: { key: 'dt' },
            Begin: { key: 'srt' },
            End: { key: 'end' },
            Actions: { key: 'act' },
            Resources: { key: 'rec' },
            Results: { key: 'res' },
            currentPage: { key: 'page' },
            pageSize: { key: 'size' },
            sortColumn: { key: 'col' },
            sortDirection: { key: 'dir' },
    },


    data() {
        const params = {
            UserKeyword: '',
            RefKeyKeyword: '',
            DateType: '',
            Begin: '2020-01-01T00:00:00',
            End: '2020-12-31T23:59:59',
            Actions: '',
            Resources: '',
            Results: '',
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'Timestamp',
            sortDirection: 'desc'
        }

        return {
            params,

            /* Select Option */
            auditLogActions: [],
            filterAuditLogAction: null,
            auditLogResources: [],
            filterAuditLogResource: null,
            auditLogResults: [],
            filterAuditLogResult: null,

            
            /* Detail Table */
            defaultOpenedDetails: [1],
            showDetailIcon: true,

            /* Dropdown Calendar */
            labelCustomOption: '',
            flagCustomOption: true,
            /* Calendar */
            attrs: [
                {
                    key: 'today',
                    dot: true,
                    dates: new Date(),
                },
            ],
            nowDate: new Date(),
            selectedDate: {
                start: null,
                end: null,
            },
            monthName: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            beginDate: null,
            endDate: null,

            format_date,
        }
    },
    created() {
        //this.labelCustomOption = new Date().getFullYear()
        Promise.all([this.optionAuditLogAction(), this.optionAuditLogResource(), this.optionAuditLogResult()]).then(
            () => {
                // this.AuditLogQuery.execute(this.params).then(() => {
                //     this.monitor(() => {
                //         this.AuditLogQuery.execute(this.params)
                //     })
                // })
                this.viewLast7Days()
            }
        )
    },
    computed: {
        labelCurrentYear() {
            return this.nowDate.getFullYear()
        },
        labelLastYear() {
            return this.nowDate.getFullYear() - 1
        },
        labelCurrentMonth() {
            return this.monthName[this.nowDate.getMonth()]
        },
        labelLastMonth() {
            let monthValue = this.nowDate.getMonth() - 1
            if (monthValue === -1) {
                monthValue = 11
            }
            return this.monthName[monthValue]
        },
        labelLast2Month() {
            let monthValue = this.nowDate.getMonth() - 2
            if (monthValue === -1) {
                monthValue = 11
            }
            if (monthValue === -2) {
                monthValue = 10
            }
            return this.monthName[monthValue]
        },
    },
    methods: {
        optionAuditLogAction() {
            this.$http({
                url: '/auditlogs/actions/metadata',
            }).then((resp) => {
                let defaultItem = { Id: '', Name: 'All Actions' }
                let dataArray = []
                resp.data.forEach((element) => {
                    dataArray.push({ Id: element, Name: element })
                })
                this.auditLogActions = dataArray
                this.auditLogActions.unshift(defaultItem)
                this.filterAuditLogAction = defaultItem
            })
        },
        optionAuditLogResource() {
            this.$http({
                url: '/auditlogs/resources/metadata',
            }).then((resp) => {
                let defaultItem = { Id: '', Name: 'All Resources' }
                let dataArray = []
                resp.data.forEach((element) => {
                    dataArray.push({ Id: element, Name: element })
                })
                this.auditLogResources = dataArray
                this.auditLogResources.unshift(defaultItem)
                this.filterAuditLogResource = defaultItem
            })
        },
        optionAuditLogResult() {
            this.$http({
                url: '/auditlogs/results/metadata',
            }).then((resp) => {
                let defaultItem = { Id: '', Name: 'All Results' }
                let dataArray = []
                resp.data.forEach((element) => {
                    dataArray.push({ Id: element, Name: element })
                })
                this.auditLogResults = dataArray
                this.auditLogResults.unshift(defaultItem)
                this.filterAuditLogResult = defaultItem
            })
        },
        get2Digit(input) {
            if (input < 10) {
                return '0' + input
            }
            return '' + input
        },
        switchCustomOption() {
            this.flagCustomOption = !this.flagCustomOption
        },
        viewLast7Days() {
            let dtInput = new Date()
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = this.get2Digit(dtInput.getDate())
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            dtInput.setDate(dtInput.getDate() - 7)
            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = this.get2Digit(dtInput.getDate())
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            this.params.Begin = startDate + 'T00:00:00'
            this.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = 'Last 7 Days'
        },
        viewLast28Days() {
            let dtInput = new Date()
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = this.get2Digit(dtInput.getDate())
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            dtInput.setDate(dtInput.getDate() - 28)
            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = this.get2Digit(dtInput.getDate())
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            this.params.Begin = startDate + 'T00:00:00'
            this.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = 'Last 28 Days'
        },
        viewLast90Days() {
            let dtInput = new Date()
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = this.get2Digit(dtInput.getDate())
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            dtInput.setDate(dtInput.getDate() - 90)
            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = this.get2Digit(dtInput.getDate())
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            this.params.Begin = startDate + 'T00:00:00'
            this.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = 'Last 90 Days'
        },
        viewLast365Days() {
            let dtInput = new Date()
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = this.get2Digit(dtInput.getDate())
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            dtInput.setDate(dtInput.getDate() - 365)
            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = this.get2Digit(dtInput.getDate())
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            this.params.Begin = startDate + 'T00:00:00'
            this.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = 'Last 365 Days'
        },
        viewCurrentYear() {
            let dtInput = new Date()

            let startYear = dtInput.getFullYear()
            let startMonth = '01'
            let startDay = '01'
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let endYear = dtInput.getFullYear()
            let endMonth = '12'
            let endDay = '31'
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            this.params.Begin = startDate + 'T00:00:00'
            this.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = this.labelCurrentYear
        },
        viewLastYear() {
            let dtInput = new Date()
            dtInput.setYear(dtInput.getFullYear() - 1)

            let startYear = dtInput.getFullYear()
            let startMonth = '01'
            let startDay = '01'
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let endYear = dtInput.getFullYear()
            let endMonth = '12'
            let endDay = '31'
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            this.params.Begin = startDate + 'T00:00:00'
            this.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = this.labelLastYear
        },
        viewCurrentMonth() {
            let dtInput = new Date()

            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = '01'
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let dtInput2 = new Date(dtInput.getFullYear(), dtInput.getMonth() + 1, 0)
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = dtInput2.getDate()
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            this.params.Begin = startDate + 'T00:00:00'
            this.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = this.labelCurrentMonth
        },
        viewLastMonth() {
            let dtInput = new Date()
            dtInput.setMonth(dtInput.getMonth() - 1)

            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = '01'
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let dtInput2 = new Date(dtInput.getFullYear(), dtInput.getMonth() + 1, 0)
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = dtInput2.getDate()
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            this.params.Begin = startDate + 'T00:00:00'
            this.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = this.labelLastMonth
        },
        viewLast2Month() {
            let dtInput = new Date()
            dtInput.setMonth(dtInput.getMonth() - 2)

            let startYear = dtInput.getFullYear()
            let startMonth = this.get2Digit(dtInput.getMonth() + 1)
            let startDay = '01'
            let startDate = startYear + '-' + startMonth + '-' + startDay
            // let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let dtInput2 = new Date(dtInput.getFullYear(), dtInput.getMonth() + 1, 0)
            let endYear = dtInput.getFullYear()
            let endMonth = this.get2Digit(dtInput.getMonth() + 1)
            let endDay = dtInput2.getDate()
            let endDate = endYear + '-' + endMonth + '-' + endDay
            // let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            this.params.Begin = startDate + 'T00:00:00'
            this.params.End = endDate + 'T23:59:59'
            // this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
            this.labelCustomOption = this.labelLast2Month
        },
        setCustomDateRange(objValue) {
            let startYear = objValue.start.getFullYear()
            let startMonth = this.get2Digit(objValue.start.getMonth() + 1)
            let startDay = this.get2Digit(objValue.start.getDate())
            let startDate = startYear + '-' + startMonth + '-' + startDay
            let startDateLabel = startDay + '/' + startMonth + '/' + startYear

            let endYear = objValue.end.getFullYear()
            let endMonth = this.get2Digit(objValue.end.getMonth() + 1)
            let endDay = this.get2Digit(objValue.end.getDate())
            let endDate = endYear + '-' + endMonth + '-' + endDay
            let endDateLabel = endDay + '/' + endMonth + '/' + endYear

            let newStartDate = startDate + 'T00:00:00'
            let newEndDate = endDate + 'T23:59:59'

            if (this.params.Begin === newStartDate && this.params.End === newEndDate) {
                // this.$refs.dropdownCustomOption.toggle()
            } else {
                this.params.Begin = newStartDate
                this.params.End = newEndDate
                this.labelCustomOption = startDateLabel + ' - ' + endDateLabel
                this.$refs.dropdownCustomOption.toggle()
            }
        },
        toggle(row) {
            this.$refs.table.toggleDetails(row)
        },
        isJson(data) {
            try {
                JSON.parse(data)
            } catch (e) {
                return false
            }
            return true
        },
        dataStringify(data) {
            return JSON.stringify(JSON.parse(data), undefined, 2)
        },
    },
    mounted() {
        this.$refs.listview.reload()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
