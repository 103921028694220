import { render, staticRenderFns } from "./BRemainingPeriod.vue?vue&type=template&id=1ace9d1e&scoped=true&"
import script from "./BRemainingPeriod.vue?vue&type=script&lang=js&"
export * from "./BRemainingPeriod.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ace9d1e",
  null
  
)

export default component.exports