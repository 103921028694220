<template>
  <BTabContent :loading="assignedUserOfRoleQuery.isLoading">
    <div class="detail-item-title">Assigned Users</div>
    <div class="detail-item-content">
            <div class="i-relative">                
                <b-field>
                    <b-autocomplete
                        v-model="unassignUserName"
                        :data="unassignUserQuery.Rows"
                        :loading="unassignUserQuery.isLoading"
                        :keep-first="true"
                        :clear-on-select="true"
                        :open-on-focus="true"
                        placeholder="e.g. Username"
                        icon="magnify"
                        field="UserName"
                        @focus="getUnassignedUser(unassignUserName)"
                        @typing="value => getUnassignedUser(value)"
                        @input="null"
                        @select="value => assignUser(value)">
                        
                    </b-autocomplete>
                </b-field>
            </div>    
            <ul class="list mt-32 list-assigned">
                <li class="list-item" v-for=" (item, index) in assignedUserOfRoleQuery.Rows " :key="index">
                    <div class="item-assigned">
                        <span>{{ item.UserName }}</span>
                        <div class="item-assigned-remove" @click="confirmUnassign(item)">
                            <b-tooltip label="Unassign" position="is-right" type="is-light">
                                <b-icon icon="close"></b-icon>
                            </b-tooltip>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
  </BTabContent>
</template>

<script>
import Query from '@/libs/Query'
import Command from '@/libs/Command'

export default {
  props: {
    msg: String
  },
  data() {    
    return {
        unassignUserName: '',
        unassignUserQuery: new Query(
            this.$http,
            'UserQuery',
            { Keyword: '' },
            1,
            0,
            'UserName'
        ),
        assignedUserOfRoleQuery: new Query(
            this.$http, 
            'UsersOfRoleGet', 
            { RoleId: this.$route.params.roleId },
            1,
            10,
            'UserName'
        ),
        assignUserCommand: new Command(this.$http, 'POST', 'UsersOfRoleAssign'),
        unassignUserCommand: new Command(this.$http, 'DELETE', 'UsersOfRoleUnassign')
    }
  },
  created() {        
      this.assignedUserOfRoleQuery.execute()
  },
  methods: {
        getUnassignedUser(value) {
            this.unassignUserQuery.execute({
                Keyword: value
            })
        },
        assignUser(option) {
            let object = null

            if(option) {
                object = this.assignedUserOfRoleQuery.Rows.filter(obj => obj.Id === option.Id)
                
                if(object.length === 0) {
                    this.assignUserCommand.execute({ RoleId: this.$route.params.roleId, UserIds: option.Id }).then(resp => {
                        if (resp.status === 201) {
                            this.assignedUserOfRoleQuery.execute({ RoleId: this.$route.params.roleId })
                        }
                    })
                }
            }
        },
        confirmUnassign(item) {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message:
                    "Are you sure you want to unassign from role: <b>'" + item.UserName + "'</b>?",
                confirmText: 'Unassign',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.unassignUserCommand.execute({ RoleId: this.$route.params.roleId, UserIds: item.Id }).then(resp => {
                        if (resp.status === 204) {
                            this.assignedUserOfRoleQuery.execute()
                        }
                    })
                }
            })
        }
    }
};
</script>