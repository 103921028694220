

export default class Get {

    // States
    //
    get isLoading() {
        return this.__isLoading
    }
    

    get isLoaded() {
        return this.__isLoaded
    }
    

    constructor(http, getOpName,
        params = {}, paramExtractFn = null) {

        this.__isLoading = false
        this.__isLoaded = false

        this.__http = http
        this.__getOpName = getOpName

        this.params = params
        this.__paramExtractFn = paramExtractFn

        // Response Data
        //
        this.Data = null

    }

    

    execute(mergeParams = null) {

        //console.log('mergeParams: ', mergeParams)
        // Assign mergeParams
        //
        if (mergeParams) {
            for (let p in mergeParams) {
                if (this.params && this.params[p] != undefined)
                    this.params[p] = mergeParams[p];

                if (this[p])
                    this[p] = mergeParams[p];
            }
        }

        let reqParams = this.__paramExtractFn 
                    ? this.__paramExtractFn(this.params)
                    : this.params

        // console.log('reqParams: ', reqParams)
        this.__isLoading = true

        let pm = this.__http({
            url: '/json/reply/' + this.__getOpName,
            params: reqParams
        })
        .then(resp => {
            // console.log('resp: ', resp)
            this.Data = resp.data
        })
        .finally(() => {
            this.__isLoading = false
            this.__isLoaded = true
        })

        return pm
    }

    
}
