<template>
    <BTabContent :loading="licenseQuery.isLoading">
        <div v-if="licenseQuery.isLoaded && details">
            <div class="detail-item-title">Customer</div>
            <div class="detail-item-content">{{ details.Customer.Name }}</div>
            <div class="detail-item-title">Product</div>
            <div class="detail-item-content">{{ details.Product.Name }}</div>
            <div class="detail-item-title">Product Version</div>
            <div class="detail-item-content">{{ details.ProductVersion.Name }}</div>
            <div class="detail-item-title">
                {{ details.ActivationMethod == 'Server' ? 'Activation Key' : 'Serial Number' }}
            </div>
            <div class="detail-item-content">{{ details.ActivationKey }}</div>
            <div class="detail-item-title">Activation Method</div>
            <div class="detail-item-content">{{ details.ActivationMethod }}</div>
            <div class="detail-item-title">Type</div>
            <div class="detail-item-content">{{ details.Type }}</div>
            <div class="detail-item-title">Period</div>
            <div class="detail-item-content">{{ details.Periods }} {{ 'Month' | pluralize(details.Periods) }}</div>
            <div class="detail-item-title">Status</div>
            <div class="detail-item-content">{{ details.Status }}</div>
            <div class="detail-item-title">Activation Count / Max Activations</div>
            <div class="detail-item-content">
                <router-link :to="'/license/' + $route.params.licenseId + '/tab/1'" exact>
                    <span class="item-link"> {{ details.ActivatedCount }} / {{ details.MaxActivations }} </span>
                </router-link>
            </div>
            <div class="detail-item-title">Activated Date</div>
            <div class="detail-item-content">{{ format_date(details.ActivatedDate) }}</div>
            <div class="detail-item-title">Expire Date</div>
            <div class="detail-item-content">{{ format_date(details.ExpireDate) }}</div>
            <div class="detail-item-title">Remaining Preiod</div>
            <div class="detail-item-content">
                <div v-if="details.RemainingPeriod">
                    <span v-if="details.RemainingPeriod.Years > 0"
                        >{{ details.RemainingPeriod.Years }} Year<span v-if="details.RemainingPeriod.Year > 1"
                            >s</span
                        ></span
                    >&nbsp;
                    <span v-if="details.RemainingPeriod.Months > 0"
                        >{{ details.RemainingPeriod.Months }} Month<span v-if="details.RemainingPeriod.Months > 1"
                            >s</span
                        ></span
                    >&nbsp;
                    <span v-if="details.RemainingPeriod.Days > 0"
                        >{{ details.RemainingPeriod.Days }} Day<span v-if="details.RemainingPeriod.Days > 1"
                            >s</span
                        ></span
                    >
                </div>
            </div>
            <div class="detail-item-title">Note</div>
            <div class="detail-item-content">{{ details.Note }}</div>
            <div class="detail-item-title" v-if="details.ActivationMethod == 'Server'">Features</div>
            <div class="detail-item-content" v-if="details.ActivationMethod == 'Server'">
                <b-table :data="details.Features">
                    <b-table-column label="Name" v-slot="props">
                        {{ props.row.Name }}
                    </b-table-column>

                    <b-table-column label="Limits" width="130" v-slot="props">
                        <div v-if="props.row.Limits">
                            {{ props.row.Limits }}
                        </div>
                        <div v-else>
                            <div style="text-align:left;">N/A</div>
                        </div>
                    </b-table-column>

                    <template slot="empty">
                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>
                                    <b-icon icon="blur" size="is-mediam"> </b-icon>
                                </p>
                                <p>No Item.</p>
                            </div>
                        </section>
                    </template>
                </b-table>
            </div>
        </div>
        <div v-else>
            <!-- <p>Details not found</p> -->
        </div>
    </BTabContent>
</template>

<script>
import Query from '@/libs/Query'
import { format_date } from '@/libs/datetime'

export default {
    props: {
        msg: String,
    },
    data() {
        return {
            licenseQuery: new Query(this.$http, 'LicensesGet', { Ids: this.$route.params.licenseId }),

            format_date,
        }
    },
    async created() {
        await this.licenseQuery.execute()
    },
    computed: {
        details() {
            if (this.licenseQuery.hasRow) return this.licenseQuery.firstRow
            else {
                return { Product: '', Customer: '', ProductVersion: '' }
            }
        },
    },
}
</script>
