import Vue from "vue";
import store from "@/store";

export const VPermission = {
    bind(el, binding, vnode) {
        // console.log("bind ==> ");
        // console.log("resources: ", binding.arg);
        // console.log("modifiers: ", binding.modifiers)
        // console.log("actions: ", binding.value);

        let permitAny = binding.modifiers.any ? true : false
        let permission = { resources: binding.arg, actions: binding.value, permitAny: permitAny };
        
        if (!store.getters.isMyPermissionsLoaded) {
            el.style.display = 'none'
        } else {
            store.dispatch("hasPermission", permission).then(isPermit => {
                el.style.display = isPermit ? "block" : "none"
            })
        }
        
        // Apply permission on the change of state
        //
        const unwatch = store.watch(
            (state, getters) => getters.isMyPermissionsLoaded,
            loaded => {
                // console.log('isPermissionsLoaded: ', loaded)
                //if (store.dispatch('hasPermission', { resources: binding.arg, actions: binding.value } ))
                //    el.style.display = 'block'
                if(!loaded) return

                store.dispatch("hasPermission", permission).then(isPermit => {
                    el.style.display = isPermit ? "block" : "none"
                })
                
            }
        );

        el.__unwatch = unwatch;
    },

    unbind(el) {
        el.__unwatch && el.__unwatch();
    },

    

    // update(el, binding, vnode) {

    //     console.log("update ==> ");
    //     console.log("el: ", el);
    //     console.log("binding: ", binding);
    //     console.log("resources: ", binding.arg);
    //     console.log("action: ", binding.modifiers);
    //     console.log("value: ", binding.value);
    //     console.log("vnode: ", vnode);
    //     // el might not be present for server-side rendering.
    //     if (el) {
    //     }
    // }
};

/**
 * Create comment node
 *
 * @private
 * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
 */
// function commentNode(el, vnode) {
//     const comment = document.createComment(' ')

//     Object.defineProperty(comment, 'setAttribute', {
//       value: () => undefined
//     })

//     vnode.text = ' '
//     vnode.elm = comment
//     vnode.isComment = true
//     vnode.context = undefined
//     vnode.tag = undefined
//     vnode.data.directives = undefined

//     if (vnode.componentInstance) {
//       vnode.componentInstance.$el = comment
//     }

//     if (el.parentNode) {
//       el.parentNode.replaceChild(comment, el)
//     }
//   }

//Vue.directive("permission", VPermission);
