<template>
    <div class="h-100">
       
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>

                <!-- Create -->
                <div class="card mt-32 mb-48">
                    <header class="card-header card-header-create">
                        <div class="create-title">Edit License of {{ serialNo }}</div>
                    </header>
                    
                    <div class="card-content">
                        <b-field horizontal label='Customer'>
                            {{ customerName }}
                        </b-field>
                    </div>
                    
                    <div class="card-content" style="border-top: 1px solid #ededed;">
                        <b-field horizontal label='Product'>
                            {{ productName }}
                        </b-field>

                        <b-field horizontal label='Product Version'>
                            {{ productVersionName }}
                        </b-field>
                    </div>

                    <div class="card-content" style="border-top: 1px solid #ededed;">
                        <b-field horizontal label="License Type*" :type="{'is-danger': $v.input.Type.$error}" :message="{ 'Please select a License Type': $v.input.Type.$error}">
                            <b-select name="licenseType" v-model="input.Type" ref="licenseType" placeholder="Select a License Type">
                                <option
                                    v-for="(option, index) in licenseTypes"
                                    :value="option"
                                    :key="index">
                                    {{ option }}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field horizontal label="Period*" v-if="input.Type != 'Perpetual'" :type="{'is-danger': $v.input.period.$error}" :message="{ 'Please select a Period': $v.input.period.$error}">
                            <b-period v-model="input.period" @change="(value) => { this.input.period = value }" />
                        </b-field>

                        <b-field 
                            horizontal 
                            label="Max Activations*"
                            :type="{ 'is-danger': $v.input.maxActivation.$error }"
                            :message="{ 'Value is out of range': $v.input.maxActivation.$error }"
                            >
                            <BMaxActivation :value="input.maxActivation" :maxActivate="maxActivationFromProduct" @onChange="(value) => { input.maxActivation = value }" />
                        </b-field>

                        <b-field horizontal label="Note">
                             <b-input type="textarea" name="note" v-model="input.note" ref="note"></b-input>
                        </b-field>
                    </div>

                    <footer class="card-footer card-footer-create">
                        <div class="level w-100">
                            <div class="level-left">
                                <button class="button has-border-transparent ml-12" @click="$router.go(-1)"  data-cy="cancel">Cancel</button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Update</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required, numeric, minValue, maxValue } from 'vuelidate/lib/validators'
import BPeriod from '@/components/control/BPeriod'
import BMaxActivation from '@/components/control/BMaxActivation'

export default {
    name: 'MachineLicenseEdit',
    props: {
        'msg': String
    },
    components: { 
        BPeriod,
        BMaxActivation
    },
    data() {
        return {
           /* Select Option */
            licenseTypes: ['Trial','Subscription','Perpetual'],

            maxActivationFromProduct: 1,

            input: {
                Type: null,
                period: 1,
                maxActivation: 1,
                features: [],
                note: '',
            },
            
            getLicenseQuery: new Query(this.$http, 'LicensesGet', { Ids: this.$route.params.licenseId }),
            productGet: new Query(this.$http, 'ProductsGet', { Ids: [] }, 1, 10, 'Name' ),
            updateLicenseCommand: new Command(this.$http, 'PUT', 'LicensesUpdate'),
        }
    },
    validations() {
        return {
            input: {
                Type: {
                    required
                },
                period: {
                    required
                },
                maxActivation: {
                    numeric,
                    required,
                    minValue: minValue(1),
                    maxValue: maxValue(this.maxActivationFromProduct)
                }
            }   
        }
    },
    async created() {
        await this.getLicenseQuery.execute()
        let data = this.getLicenseQuery.firstRow
        this.input.Type = data.Type
        this.input.period = data.Periods
        this.input.maxActivation = data.MaxActivations
        this.input.note = data.Note

        await this.productGet.execute({ Ids: data.Product.Id })
        this.maxActivationFromProduct = this.productGet.firstRow.MaxActivations
    },
    computed: {
        isLoading() {
            return this.getLicenseQuery.isLoading || this.updateLicenseCommand.isLoading || this.productGet.isLoading
        },
        serialNo() {
            return this.getLicenseQuery.hasRow ? this.getLicenseQuery.firstRow.ActivationKey : '-'
        },
        customerName() {
            return this.getLicenseQuery.hasRow ? this.getLicenseQuery.firstRow.Customer.Name : '-'
        },
        productName() {
            return this.getLicenseQuery.hasRow ? this.getLicenseQuery.firstRow.Product.Name : '-'
        },
        productVersionName() {
            return this.getLicenseQuery.hasRow ? this.getLicenseQuery.firstRow.ProductVersion.Name : '-'
        }
    },
    methods: {
       
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) 
                return;  
            this.updateItem()
        },
        updateItem() {            
            this.updateLicenseCommand
            .execute({ 
                Licenses: [{
                    Id: this.$route.params.licenseId,
                    Type: this.input.Type,
                    Periods: this.input.period,
                    MaxActivations: this.input.maxActivation,
                    Features: [],
                    Note: this.input.note
                }] 
            })
            .then(resp => {
                if(resp.status === 200){
                    this.$router.go(-1)
                }
            })
        },
        
    },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.is-remark{
    color: red;
    font-size: 12px;
    font-style: italic;
}
</style>
