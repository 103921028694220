<template>
    <div class="h-100">
        <!-- <b-loading :is-full-page="true" :active="loading" :can-cancel="true"></b-loading> -->
        <div class="content-section-view">
            <!-- Back Button -->
            <!-- <BBackButton @click="$emit('backButtonClick')" /> -->

            <div class="content-container-view">
                <!-- Breadcrumb -->
                <BBreadcrumb
                    :prevPathName="prevPathName"
                    @prevPathClick="$emit('prevPathClick')"
                    :hasDotDotDot="hasDotDotDot"
                    :hasParent="hasParent"
                    :parentPathName="parentPathName"
                    @parentPathClick="$emit('parentPathClick')"
                    :currentPathName="currentPathName"
                />

                <div class="card mb-48">
                    <!-- Header -->
                    <header class="card-header card-header-detail">
                        <div class="columns">
                            <div class="column">
                                <div class="detail-group">{{ typeName }}</div>
                                <div class="detail-title">{{ viewName }}</div>
                                <div class="detail-subtitle">{{ subViewName }}</div>
                            </div>

                            <div class="column i-relative" v-if="actionItems && actionItems.length > 0">
                                <div class="i-absolute-br" v-permit="actionPermission">
                                    <b-dropdown position="is-bottom-left">
                                        <button
                                            class="button is-small has-text-weight-bold has-text-light has-background-transparent has-border-transparent"
                                            slot="trigger"
                                        >
                                            <span>Action</span>
                                            <b-icon icon="menu-down"></b-icon>
                                        </button>
                                        <b-dropdown-item
                                            v-for="(action, index) in actionItems"
                                            :key="index"
                                            v-permit="action.permission"
                                            @click="action.do"
                                            >{{ action.label }}</b-dropdown-item
                                        >
                                    </b-dropdown>
                                </div>
                            </div>
                        </div>
                    </header>

                    <BTabPanel
                        :items="tabItems"
                        :currentTabComponent="currentTabComponent"
                        @tabClick="(index) => tabClick(index)"
                    ></BTabPanel>
                    <!-- <div class="tab-box">
                        <div class="tab-item">
                            <button
                                v-for="(item, index) in tabItems"
                                :key="index"
                                class="tab-link"
                                :class="{ active: currentTabComponent == item.component }"
                                @click="tabClick(index)"
                            >{{item.label}}</button>
                        </div>

                        <keep-alive>
                            <component :is="currentTabComponent"></component>
                        </keep-alive>

                        <div class="clear-float"></div>
                    </div> -->
                </div>
                <br />
                <br />
                <br />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BDetailView',
    props: {
        loading: {
            type: [Boolean],
            default: false,
        },
        prevPathName: {
            type: [String],
        },
        hasDotDotDot: {
            type: [Boolean],
            default: false,
        },
        hasParent: {
            type: [Boolean],
            default: false,
        },
        parentPathName: {
            type: [String],
        },
        currentPathName: {
            type: [String],
        },
        typeName: {
            type: [String],
            default: 'TYPE',
        },
        viewName: {
            type: [String],
            default: 'NAME',
        },
        subViewName: {
            type: [String],
            default: '',
        },
        tabItems: {
            type: [Array],
            default: () => [],
        },
        actionPermission: {
            type: [Object],
            default: () => {
                return {}
            },
        },
        actionItems: {
            type: [Array],
            default: () => [],
        },
    },
    components: {},
    data() {
        return {
            currentTabId: 0,
            currentTabComponent: null,
        }
    },
    watch: {
        $route(to, from) {
            // console.log(this.$options.name, ': watch $route() ------------------------------------------')
            this.validateTabId()
        },
    },
    created() {
        // console.log(this.$options.name, ': created() ------------------------------------------')
        // console.log('tabItems: ', this.tabItems)

        this.validateTabId()
    },
    methods: {
        validateTabId() {
            let tabId = parseInt(this.$route.params.tabId)
            this.currentTabId = isNaN(tabId) ? 0 : tabId
            if (this.currentTabId < 0 || this.currentTabId > this.tabItems.length - 1) {
                this.$route.params.tabId = 0
                this.$router.push({ name: this.$route.name, params: this.$route.params })
                return
            }

            this.currentTabComponent = this.tabItems[this.currentTabId].component
        },
        tabClick(index) {
            this.$route.params.tabId = index
            this.$router.push({ name: this.$route.name, params: this.$route.params })
        },
    },
}
</script>

<style scoped></style>
