<template>
    <BDetailView
        :loading="isLoading"
        @backButtonClick="$router.push({ name: 'userList' })"
        prevPathName="User"
        @prevPathClick="$router.push({ name: 'userList' })"
        :currentPathName="userQuery.firstRow.UserName"
        typeName="User"
        :viewName="userQuery.firstRow.UserName"
        :tabItems="tabItems"
        :actionPermission="actionPermission"
        :actionItems="actionItems"
    />
</template>

<script>

import Command from '@/libs/Command'
import Query from '@/libs/Query'
import UserDetail from '@/views/Account/User/DetailTabs/DetailTab.vue'
import UserRole from '@/views/Account/User/DetailTabs/RoleTab.vue'
import UserResetPassword from '@/views/Account/User/DetailTabs/ResetPasswordTab.vue'

export default {
    name: 'UserDetail',
    props: {
        userId: {
            type: [String]
        },
        tabId: {
            type: [String, Number],
            default: 0
        }
    },
    components: {
        UserDetail
    },
    data() {
        return {
            tabItems: [
                { component: UserDetail, label: 'Details' },
                { component: UserRole, label: 'Role' },
                { component: UserResetPassword, label: 'Reset Password' }        
            ],
            actionPermission: {
                resources: 'User',
                any: true,
                actions: ['Edit', 'Delete']
            },
            actionItems: [
                {
                    label: 'Edit',
                    do: () =>
                        this.$router.push({
                            name: 'userEdit',
                            params: { userId: this.$route.params.userId }
                        }),
                    permission: { resources: 'User', actions: ['Edit'] }
                },
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(),
                    permission: { resources: 'User', actions: ['Delete'] }
                }
            ],
            userQuery: new Query(this.$http, 'UserGet', {
                Ids: this.$route.params.userId
            }),
            deleteUserCommand: new Command(this.$http, 'DELETE', 'UserRemove')
        }
    },
    created() {
        this.userQuery.execute()
    },
    computed: {
        isLoading() {
            return this.userQuery.isLoading || this.deleteUserCommand.isLoading
        }
    },
    methods: {
        confirmDelete() {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: "Are you sure you want to delete this User: <b>'" + this.userQuery.firstRow.Name + "'</b>?",
                confirmText: 'Delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteUserCommand.execute({ Ids: this.$route.params.userId }).then(resp => {
                        if (resp.status === 204) {
                            this.$router.push({ name: 'userList' })
                        }
                    })
                }
            })
        }
    }
}
</script>