var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-container"},[(!_vm.hideHeaderSection)?_c('div',{staticClass:"level space-16"},[_c('div',{staticClass:"level-left"},[_vm._t("title",function(){return [_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.pageTitle))])]})],2),_c('div',{staticClass:"level-right"},[_vm._t("actionButton")],2)]):_vm._e(),(!_vm.hideHeaderSection)?_c('div',{staticStyle:{"margin-bottom":"16px","border-bottom":"1px solid #ccc"}}):_vm._e(),_c('div',{staticClass:"level space-8"},[_c('div',{staticClass:"level-left"},[_c('BSearchBox',{directives:[{name:"show",rawName:"v-show",value:(!_vm.disableSearchByKeyword),expression:"!disableSearchByKeyword"}],ref:"searchBox",staticClass:"level-item",attrs:{"initValue":_vm.myKeyword,"placeholder":_vm.searchPlaceholder},on:{"submit":function (value) {
                        _vm.$emit('onKeywordSearch', value)
                    }}}),_vm._t("filterInLine")],2),_c('div',{staticClass:"level-right"},[_c('BPageSize',{staticClass:"level-item",attrs:{"options":[10, 20, 50, 100, 500]},on:{"change":function (value) {
                        _vm.$emit('onPageSizeChange', value)
                    }},model:{value:(_vm.myPageSize),callback:function ($$v) {_vm.myPageSize=$$v},expression:"myPageSize"}})],1)]),_vm._t("filter"),_c('div',{staticClass:"level space-0"},[_c('div',{staticClass:"level-left"}),_c('div',{staticClass:"level-right"},[_c('BPagingCounter',{staticClass:"level-item",attrs:{"loading":_vm.listQuery.isLoading,"beginRow":_vm.listQuery.beginRow,"endRow":_vm.listQuery.endRow,"totalRows":_vm.listQuery.TotalRows}})],1)]),(!_vm.listQuery.isLoading)?_c('div',{staticClass:"card card-container"},[_vm._t("default",null,{"result":_vm.listQuery.Rows,"alert":_vm.alert})],2):_vm._e(),(_vm.listQuery.isLoading)?_c('div',{staticClass:"card card-container"},[_vm._t("loadingState",null,{"loadingRows":_vm.loadingRows})],2):_vm._e(),_c('div',{staticClass:"pagination-container"},[_c('BPagination',{attrs:{"total":_vm.listQuery.TotalRows,"per-page":_vm.myPageSize,"current":_vm.myCurrentPage},on:{"change":function (page) {
                    _vm.$emit('onPageChange', page)
                    _vm.myCurrentPage = page
                }}})],1),_c('div',{staticStyle:{"padding-bottom":"50px"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }