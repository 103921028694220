import { VFragment } from '@superyusuke/vue-fragment'

// const Fragment = ({ scopedSlots }) => (scopedSlots.default ? scopedSlots.default() : <div></div>)

export default {
    install(Vue, options) {
        // Vue.directive('frag', frag)

        Vue.component('Fragment', VFragment)
    },
}
