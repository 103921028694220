<template>

    <BExtAutoComplete
        QueryAPIName="CustomersQuery"
        placeholderText="Search Customer name..."
        @onItemSelect="(value) => selectCustomer(value)"
        ref="extAutoComplete"
    />
</template>

<script>
import BExtAutoComplete from '@/components/common/BExtAutoComplete'

export default {
    components: {
        BExtAutoComplete
    },
    data() {
        return { }
    },
    methods: {
        selectCustomer(value) {
            this.$emit('onItemSelect', value);
        },
        clear() {
            this.$refs.extAutoComplete.clear()
        }
    }
}
</script>

<style scoped>
</style>
