<template>
    <div id="the-footer" class="the-footer level is-mobile has-text-light">
        <div class="level-left pl-8">
            <p class="level-item">&nbsp;</p>
        </div>
        <div class="level-right pr-8">
            <p class="level-item is-size-7">{{ appVersion.name }} {{ appVersion.version }}</p>
            <p class="level-item is-size-7">
                <a href="http://www.coloriance.com" target="_blank" rel="noreferrer">www.coloriance.com</a>
            </p>
            <p class="level-item is-size-7">&copy; 2021 All Rights Reserved</p>
        </div>
    </div>
</template>

<script>
import appVersion from '@/version.json'

export default {
    name: 'ViewFooter',
    props: {
        msg: String,
    },
    data() {
        return {
            appVersion: appVersion,
        }
    },

    created() {
        //console.log(appVersion);
        // this.appVersion = appVersion;
    },
}
</script>

<style scoped>
.the-footer {
    background-color: var(--app-primary-color);
    height: 30px;
    width: 100%;
}
.pl-8 {
    padding-left: 8px;
}
.pr-8 {
    padding-right: 8px;
}
</style>
