<template>
    <BTabContent :loading="licenseActivationQuery.isLoading" :title="'Activations ' + activationNumber">
        
        <template #header-right>
            <button
                v-if="canActivate"
                class="button is-primary"
                @click="
                    $router.push({
                        name: 'licenseActivationAdd',
                        params: { licenseId: $route.params.licenseId },
                    })
                "
            >
                Activate
            </button>
        </template>

        <BListview 
            :hideHeaderSection="true"
            :disableSearchByKeyword=true
            QueryAPIName="LicenseActivationsGet"
            :QueryParams="params"
            DeleteAPIName="LicenseActivationsRemove"

            @onPageSizeChange="(value) => { params.pageSize = value; }"
            @onPageChange="(value) => { params.currentPage = value; }"
            ref="listview">

            <template v-slot:default="{ result }" >
                <b-table
                    :data="result"
                    backend-pagination
                    backend-sorting
                    :current-page="params.currentPage"
                    :per-page="params.pageSize"
                    :default-sort-direction="params.sortDirection"
                    :default-sort="params.sortColumn"
                    @sort="
                        (field, order) => {
                            params.sortColumn = field
                            params.sortDirection = order
                        }
                    "
                >
                    <template>
                        <b-table-column field="Name" label="Hardware Id" sortable v-slot="props">
                            {{ props.row.HardwareId }}
                        </b-table-column>

                        <b-table-column field="ChildCount" label="Activated Date" sortable v-slot="props">
                            {{ format_date(props.row.ActivatedDate) }}
                        </b-table-column>

                        <b-table-column label="Download" centered v-slot="props" width="50px">
                            <span style="cursor:pointer;" @click="Download(props.row.Id, props.row.HardwareId)">
                                <b-icon icon="arrow-down-bold-circle-outline"></b-icon>
                            </span>
                        </b-table-column>

                        <b-table-column label="" centered v-slot="props" width="30px">
                            <span style="cursor:pointer;" @click="ConfirmDelete(props.row)">
                                <b-icon style="cursor:pointer;" icon="close"></b-icon>
                            </span>
                        </b-table-column>
                    </template>

                    <template slot="empty">
                        <BEmptyState title="No activations" subtitle="Click 'Activate' to create new one" />
                    </template>
                </b-table>
            </template>

            <template v-slot:loadingState="{ loadingRows }">
                <b-table :data="loadingRows">
                    <b-table-column label="Hardware Id">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Activated Date">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Download">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column field="control">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>                   
                </b-table>
            </template>

        </BListview>
        
    </BTabContent>
</template>

<script>
import Query from '@/libs/Query'
import BListview from '@/components/common/BListview'
import { format_date } from '@/libs/datetime'

export default {
    name: 'ActivationTab',
    components: {
        BListview,
    },
    url: {
        __root: 'params',
            currentPage: { key: 'page' },
            pageSize: { key: 'size' },
            sortColumn: { key: 'col' },
            sortDirection: { key: 'dir' },
            LicenseId: { key:'lid' }
    },



    data() {
        const params = {
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'ActivatedDate',
            sortDirection: 'desc',
            LicenseId: this.$route.params.licenseId,
        }

        return {
            params,

            licenseQuery: new Query(this.$http, 'LicensesGet', { Ids: this.$route.params.licenseId }),
            licenseActivationQuery: new Query(this.$http, 'LicenseActivationsGet', {
                LicenseId: this.$route.params.licenseId,
            }),

            format_date,

            canActivate: false
        }
    },
    async created() {
        await this.licenseQuery.execute()
        await this.licenseActivationQuery.execute(this.params)

        this.canActivate =  this.licenseQuery.isLoaded &&
                            this.licenseActivationQuery.isLoaded &&
                            this.licenseQuery.firstRow.MaxActivations > this.licenseActivationQuery.Rows.length

        this.$refs.listview.reload()
        this.$refs.listview.startWatch()
    },
    methods: {
        ConfirmDelete(selectedRow) {
            let formattedItemsName = '<b>' + selectedRow.HardwareId + '</b>'
            let confirmMsg = `Are you sure you want to deactivate ` + formattedItemsName + `?<br/><br/>`
            let ItemsId = selectedRow.Id

            this.$refs.listview.confirmDelete(ItemsId, confirmMsg + formattedItemsName)
        },
        Download(id, hwId) {
            let pm = this.$http({
                url: '/json/reply/LicenseDownload?ActivationId=' + id,
                //params: { PosId: this.$route.params.posId },
                responseType: 'blob',
            })
                .then((resp) => {
                    const url = window.URL.createObjectURL(new Blob([resp.data]))
                    const link = document.createElement('a')
                    link.href = url

                    let filename = resp.headers['content-disposition'].substring(
                        resp.headers['content-disposition'].indexOf('"') + 1,
                        resp.headers['content-disposition'].lastIndexOf('"')
                    )

                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()
                })
                .finally(() => {
                    //this.isLoad = false
                })
        },
    },
    computed: {
        // canActivate() {
        //     return (
        //         this.licenseQuery.isLoaded &&
        //         this.licenseActivationQuery.isLoaded &&
        //         this.licenseQuery.firstRow.MaxActivations > this.licenseActivationQuery.Rows.length
        //     )
        // },
        activationNumber() {
            return this.licenseQuery.isLoaded && this.licenseActivationQuery.isLoaded
                ? `(${this.licenseQuery.firstRow.ActivatedCount}/${this.licenseQuery.firstRow.MaxActivations})`
                : ''
        },
    },
}
</script>

<style scoped></style>
