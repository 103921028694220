<template>
    <div class="h-100">
        <div class="content-section-view">
            <div class="content-container-view" style="position: relative">
                <b-loading :is-full-page="false" :active="isLoading"></b-loading>

                <!-- Edit -->
                <nav class="breadcrumb mt-32" aria-label="breadcrumbs">
                    <ul>
                        <li>
                            <router-link to="/customerGroup" exact
                                ><span class="item-link">Customer Group</span></router-link
                            >
                        </li>
                        <li>
                            <router-link :to="'/customerGroup/' + customerGroupId" exact
                                ><span class="item-link">{{ itemDetail.Name }}</span></router-link
                            >
                        </li>
                        <li class="is-active">
                            <a href="#" aria-current="page">Edit {{ itemDetail.Name }}</a>
                        </li>
                    </ul>
                </nav>
                <div class="card mb-48">
                    <header class="card-header card-header-edit">
                        <div class="edit-title">Edit {{ itemDetail.Name }}</div>
                    </header>
                    <div class="card-content">
                        <b-field
                            horizontal
                            label="Name*"
                            :type="{ 'is-danger': $v.input.Name.$error }"
                            :message="{ 'Customer Name': $v.input.Name.$error }"
                        >
                            <b-input
                                name="name"
                                v-model="input.Name"
                                ref="groupName"
                                placeholder="Customer group name"
                                expanded
                            ></b-input>
                        </b-field>
                    </div>
                    <footer class="card-footer card-footer-edit">
                        <div class="level w-100">
                            <div class="level-left">
                                <button
                                    class="button has-border-transparent ml-12"
                                    @click="$router.go(-1)"
                                    data-cy="cancel"
                                >
                                    Cancel
                                </button>
                            </div>
                            <div class="level-right">
                                <button class="button is-info" @click="submitForm" data-cy="submit">Update</button>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'ManageCustomerGroupEdit',
    props: {
        msg: String,
    },
    data() {
        return {
            customerGroupId: this.$route.params.customerGroupId,
            sitePath: this.$route.params.sitePath,
            input: {
                Name: '',
            },
            itemDetail: {},
            customerGroupQuery: new Query(this.$http, 'CustomerGroupsGet', {
                Ids: this.$route.params.customerGroupId,
            }),
            editCustomerGroupCommand: new Command(this.$http, 'PUT', 'CustomerGroupsUpdate'),
        }
    },
    validations: {
        input: {
            Name: {
                required,
            },
        },
    },
    created() {
        this.getItem()
    },
    computed: {
        isLoading() {
            return this.customerGroupQuery.isLoading || this.editCustomerGroupCommand.isLoading
        },
    },
    methods: {
        getItem() {
            this.customerGroupQuery.execute().then(() => {
                this.itemDetail = this.customerGroupQuery.firstRow
                this.input = Object.assign(this.input, this.itemDetail)
            })
        },
        updateItem() {
            this.editCustomerGroupCommand
                .execute({
                    Groups: [
                        {
                            Id: this.customerGroupId,
                            Name: this.input.Name,
                        },
                    ],
                })
                .then((resp) => {
                    if (resp.status === 200) {
                        this.$router.go(-1)
                    }
                })
        },
        submitForm() {
            this.$v.$reset()
            this.$v.$touch()

            if (this.$v.$error) return
            this.updateItem()
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
