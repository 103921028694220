<template>
    <MainListLayout>
        <BListview
            pageTitle="License"
            searchPlaceholder="Search Customer Name, Product Name..."
            QueryAPIName="LicensesQuery"
            :QueryParams="params"
            DeleteAPIName="LicensesRemove"
            @onKeywordSearch="
                (value) => {
                    params.keyword = value
                }
            "
            @onPageSizeChange="
                (value) => {
                    params.pageSize = value
                }
            "
            @onPageChange="
                (value) => {
                    params.currentPage = value
                }
            "
            @onDeleted="
                (value) => {
                    if (value) {
                        selectedRows = []
                    }
                }
            "
            ref="listview"
        >
            <template #title>
                <LicenseListTitle />
                <!-- <div style="display:flex">
                    <span class="title">License</span>
                    <b-button class="ml-3" type="is-light" rounded inverted>Machine</b-button>
                    <b-button type="is-text" @click="$router.push({ name: 'licenseListServer' })">Server</b-button>
                </div> -->
            </template>

            <template v-slot:actionButton>
                <BActionButton
                    v-if="selectedRows.length > 0"
                    :actionPermission="actionPermission"
                    :actionItems="actionItems"
                    :selectedRows="selectedRows"
                >
                    <template v-slot:CTASelectedState>
                        <button class="button is-primary mr-3" @click="Download(selectedRows)">
                            Download
                        </button>
                    </template>
                </BActionButton>
                <button v-else class="button is-primary ml-16" @click="$router.push({ name: 'licenseCreate', params: { type: 'm' } })">
                    Create
                </button>
            </template>

            <template v-slot:filter>
                <LayoutListInline
                    padding-x="0"
                    padding-y="0"
                    gap-x="4"
                    horizontal-align="left"
                    vertical-align="center"
                    :wrap="true"
                >
                    <BFilter
                        tooltip="License Type"
                        :loading="false"
                        v-model="filterLicenseTypes"
                        :options="licenseTypesOptions"
                        @change="
                            (Item) => {
                                params.Types = Item.Id
                            }
                        "
                    ></BFilter>
                    <BFilter
                        tooltip="Status"
                        :loading="false"
                        v-model="filterStatus"
                        :options="statusOptions"
                        @change="
                            (Item) => {
                                params.Statuses = Item.Id
                            }
                        "
                    ></BFilter>
                    <BTooltip label="Customer" position="is-top" type="is-dark" :animated="true">
                        <!-- <b-autocomplete
                            v-model="customerKeyword"
                            :data="customersQuery.Rows"
                            :loading="customersQuery.isLoading"
                            :keep-first="true"
                            :open-on-focus="true"
                            placeholder="Type customer name..."
                            icon="magnify"
                            field="Name"
                            @focus="getmCustomer(customerKeyword)"
                            @typing="(value) => getCustomer(value)"
                            @select="(value) => selectCustomer(value)"
                        >
                        </b-autocomplete> -->

                        <BCustomerPicker @onItemSelect="(value) => selectCustomer(value)" />
                    </BTooltip>
                </LayoutListInline>            
            </template>

            <template v-slot:default="{ result }">
                <b-table
                    :data="result"
                    backend-pagination
                    backend-sorting
                    checkable
                    :current-page="params.currentPage"
                    :per-page="params.pageSize"
                    :checked-rows.sync="selectedRows"
                    :default-sort-direction="params.sortDirection"
                    :default-sort="params.sortColumn"
                    @sort="
                        (field, order) => {
                            params.sortColumn = field
                            params.sortDirection = order
                        }
                    "
                >
                    <b-table-column field="ActivationKey" label="Serial Number" v-slot="props">
                        <router-link :to="{ name: 'licenseDetail', params: { licenseId: props.row.Id, tabId: 0 } }">
                            {{ props.row.ActivationKey }}
                        </router-link>
                    </b-table-column>

                    <b-table-column field="Product" label="Product" sortable v-slot="props">
                        <span class="item-link">{{ props.row.Product.Name }} v{{ props.row.ProductVersion.Name }}</span>
                    </b-table-column>

                    <b-table-column field="Customer" label="Customer" sortable v-slot="props">
                        <span class="item-link">{{ props.row.Customer.Name }}</span>
                    </b-table-column>

                    <b-table-column field="Type" label="Type" v-slot="props">
                        {{ props.row.Type }}
                    </b-table-column>

                    <b-table-column field="Periods" label="Periods" sortable v-slot="props">
                        <div v-if="props.row.Periods">
                            <BMonthsToYearMonth :months="props.row.Periods" />
                        </div>
                    </b-table-column>

                    <b-table-column field="RemainingPeriod" label="Remaining" sortable v-slot="props">
                        <BRemainingPeriod :remaining="props.row.RemainingPeriod" />
                    </b-table-column>

                    <b-table-column field="Status" label="Status" sortable v-slot="props">
                        <span class="tag" v-if="props.row.Status === 'Ready'">
                            Ready
                        </span>
                        <span class="tag is-success" v-if="props.row.Status === 'Activated'">
                            Activated
                        </span>
                        <span class="tag is-danger" v-else-if="props.row.Status === 'Expired'">
                            {{ props.row.Status }}
                        </span>
                        <span class="tag" v-else-if="props.row.Status === 'Cancelled'">
                            {{ props.row.Status }}
                        </span>
                    </b-table-column>

                    <b-table-column field="control" centered v-slot="props">
                        <b-dropdown
                            position="is-bottom-left"
                            v-permit="{ resources: 'License', any: true, actions: ['Edit', 'Delete'] }"
                        >
                            <button class="button border-0 btn-control" slot="trigger">
                                <b-icon icon="dots-horizontal" size="is-small"></b-icon>
                            </button>
                            <b-dropdown-item has-link v-permit="{ resources: 'License', actions: ['Edit'] }">
                                <a style="cursor:pointer;" @click="Download([props.row])">Download</a>
                            </b-dropdown-item>
                            <b-dropdown-item has-link v-permit="{ resources: 'License', actions: ['Edit'] }">
                                <router-link :to="{ name: 'licenseEdit', params: { licenseId: props.row.Id } }"
                                    >Edit</router-link
                                >
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="confirmDelete([props.row])"
                                v-permit="{ resources: 'License', actions: ['Delete'] }"
                                >Delete</b-dropdown-item
                            >
                        </b-dropdown>
                    </b-table-column>
                    <template slot="empty">
                        <BEmptyState title="No mached result" subtitle="Please try another criteria" />
                    </template>
                </b-table>
            </template>

            <template v-slot:loadingState="{ loadingRows }">
                <b-table :data="loadingRows">
                    <b-table-column label="Serial Number">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Product">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Customer">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Type">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Periods">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Remaining">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column label="Status">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>

                    <b-table-column field="control">
                        <b-skeleton height="35px" :animated="true"></b-skeleton>
                    </b-table-column>
                </b-table>
            </template>
        </BListview>
    </MainListLayout>
</template>

<script>
import Query from '@/libs/Query'
import BListview from '@/components/common/BListview'
import { MainListLayout } from '@/layouts'
import BMonthsToYearMonth from '@/components/display/BMonthsToYearMonth'
import BRemainingPeriod from '@/components/display/BRemainingPeriod'
import LicenseListTitle from './components/LicenseListTitle'
import BCustomerPicker from '@/components/control/BCustomerPicker'

export default {
    props: {
        msg: String,
    },
    components: {
        MainListLayout,
        BListview,
        BMonthsToYearMonth,
        BRemainingPeriod,
        LicenseListTitle,
        BCustomerPicker
    },

    url: {
        __root: 'params',
        keyword: { key: 'kw' },
        Types: { key: 'type' },
        Statuses: { key: 'status' },
        CustomerIds: { key: 'cusid' },
        currentPage: { key: 'page' },
        pageSize: { key: 'size' },
        sortColumn: { key: 'col' },
        sortDirection: { key: 'dir' },
        ActivationMethods: { key: 'act' },
    },

    data() {
        const params = {
            keyword: '',
            Types: '',
            Statuses: '',
            CustomerIds: '',
            currentPage: 1,
            pageSize: 10,
            sortColumn: 'RemainingPeriod',
            sortDirection: 'desc',
            ActivationMethods: 'Machine',
        }

        return {
            params,

            selectedRows: [],

            // customerKeyword: '',
            // customersQuery: new Query(this.$http, 'CustomersQuery', { keyword: '' }),

            licenseTypesOptions: [],
            filterLicenseTypes: { Name: 'All Type', Value: '' },
            statusOptions: [],
            filterStatus: { Name: 'All Status', Value: '' },
            isLoad: false,

            actionPermission: {
                resources: 'License',
                any: true,
                actions: ['Delete'],
            },
            actionItems: [
                {
                    label: 'Upload Activation',
                    do: () => this.$router.push({ name: 'licenseActivationUpload' }),
                    permission: { resources: 'License', actions: ['Edit'] },
                },
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(this.selectedRows),
                    permission: { resources: 'License', actions: ['Delete'] },
                },
            ],
        }
    },
    async created() {
        this.isLoad = true

        await this.$http({
            url: '/json/reply/SupportedLicenseTypesGet  ',
        }).then((resp) => {
            let defaultItem = { Id: '', Name: 'All Type' }
            let dataArray = []
            resp.data.forEach((element) => {
                dataArray.push({ Id: element, Name: element })
            })

            this.licenseTypesOptions = dataArray
            this.licenseTypesOptions.unshift(defaultItem)
            this.filterLicenseTypes = defaultItem
        })

        await this.$http({
            url: '/json/reply/SupportedLicenseStatusesGet ',
        })
            .then((resp) => {
                let defaultItem = { Id: '', Name: 'All Status' }
                let dataArray = []
                resp.data.forEach((element) => {
                    dataArray.push({ Id: element, Name: element })
                })

                this.statusOptions = dataArray
                this.statusOptions.unshift(defaultItem)
                this.filterStatus = defaultItem
            })
            .finally(() => {
                this.isLoad = false
            })

        if (this.params.Types !== '')
            this.filterLicenseTypes = this.licenseTypesOptions.filter((item) => item === this.params.Types)
        else this.filterLicenseTypes = this.licenseTypesOptions[0]

        this.$refs.listview.startWatch()
        this.$refs.listview.reload()
    },
    computed: {
        isLoading() {
            return (
                this.serverLicenseQuery.isLoading ||
                this.machineLicenseQuery.isLoading ||
                this.deleteLicenseCommand.isLoading ||
                this.isLoad
            )
        },
    },
    methods: {
        confirmDelete(selectedRow) {
            let formattedItemsName = selectedRow
                .map((p) => `<b>${p.Customer.Name} - ${p.Product.Name} v${p.ProductVersion.Name}</b>`)
                .join('<br/>')
            let nPos = selectedRow.length > 1 ? selectedRow.length : ''
            let confirmMsg = `Are you sure you want to delete the following ${nPos} License?<br/><br/>`
            let ItemsId = selectedRow.map((p) => p.Id)

            this.$refs.listview.confirmDelete(ItemsId, confirmMsg + formattedItemsName)
        },
        Download(selectedRow) {
            let ids = selectedRow.map((a) => a.Id)
            let param = '[' + ids.join(',') + ']'
            let pm = this.$http({
                url: '/json/reply/LicensesUnrestrictHardwareIdDownload?LicenseIds=' + param,
                //params: { LicenseIds: ItemIds },
                responseType: 'blob',
            })
                .then((resp) => {
                    const url = window.URL.createObjectURL(new Blob([resp.data]))
                    const link = document.createElement('a')
                    link.href = url

                    let filename = resp.headers['content-disposition'].substring(
                        resp.headers['content-disposition'].indexOf('"') + 1,
                        resp.headers['content-disposition'].lastIndexOf('"')
                    )

                    link.setAttribute('download', filename)
                    document.body.appendChild(link)
                    link.click()
                })
                .finally(() => {
                    //this.isLoad = false
                })
        },
        getCustomer(value) {
            this.customersQuery.execute({ keyword: value })
        },
        selectCustomer(value) {
            if (value) {
                this.params.CustomerIds = value.Id
            } else {
                this.params.CustomerIds = ''
            }
        },
    },
}
</script>

<style scoped>
</style>
