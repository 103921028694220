<template>
    <BDetailView
        :loading="isLoading"
        @backButtonClick="$router.push({ name: 'customerGroupList' })"
        prevPathName="Customer Group"
        @prevPathClick="$router.push({ name: 'customerGroupList' })"

        :hasDotDotDot="customerGroupQuery.firstRow.Path.length > 2"

        :hasParent="customerGroupQuery.firstRow.Path.length > 1"
        :parentPathName="parentName"
        @parentPathClick="$router.push({ name: 'customerGroupDetail', params:{ customerGroupId: customerGroupQuery.firstRow.Path[customerGroupQuery.firstRow.Path.length < 2 ? 0 : customerGroupQuery.firstRow.Path.length-2].Id, tab: 0} })"
        
        :currentPathName="customerGroupQuery.firstRow.Name"

        typeName="Customer Group"
        :viewName="customerGroupQuery.firstRow.Name"
        :tabItems="tabItems"
        :actionPermission="actionPermission"
        :actionItems="actionItems"
    />
</template>

<script>
import Command from '@/libs/Command'
import Query from '@/libs/Query'
import TabDetail from '@/views/Manage/CustomerGroup/DetailTabs/GroupDetailTab.vue'
//import TabSubGroup from '@/views/Manage/CustomerGroup/DetailTabs/SubGroupTab.vue'
import TabCustomer from '@/views/Manage/CustomerGroup/DetailTabs/CustomerTab.vue'

export default {
    name: 'ManageCustomerGroupDetail',
    props: {
        customerGroupId: {
            type: [String]
        },
        tabId: {
            type: [String, Number],
            default: 0
        }
    },
    components: {
        TabDetail
    },
    data() {
        return {
            tabItems: [
                { component: TabDetail, label: 'Details' },
                //{ component: TabSubGroup, label: 'Sub Group' },
                { component: TabCustomer, label: 'Customer' }
            ],
            actionPermission: {
                resources: 'CustomerGroup',
                any: true,
                actions: ['Edit', 'Delete']
            },
            actionItems: [
                {
                    label: 'Edit',
                    do: () =>
                        // this.$router.push({
                        //     name: 'customerGroupEdit',
                        //     params: { customerGroupId: this.$route.params.customerGroupId }
                        // }),
                        this.discusEdit(),
                    permission: { resources: 'CustomerGroup', actions: ['Edit'] }
                },
                {
                    label: 'Delete',
                    do: () => this.confirmDelete(),
                    permission: { resources: 'CustomerGroup', actions: ['Delete'] }
                }
            ],
            customerGroupQuery: new Query(this.$http, 'CustomerGroupsGet', {
                Ids: this.customerGroupId
            }),
            deleteCustomerGroupCommand: new Command(this.$http, 'DELETE', 'CustomerGroupsRemove')
        }
    },
    created() {
        this.customerGroupQuery.Rows.push({Path: [], Name: ''})  //define structure to prevent binding error
        this.customerGroupQuery.TotalRows = 1

        this.$watch('customerGroupId', () => {
            //console.log(this.$options.name, ': customerGroupId changed = ', this.customerGroupId)
            this.customerGroupQuery.execute({ Ids: this.customerGroupId })                
        })

        this.customerGroupQuery.execute()
    },
    computed: {
        isLoading() {
            return this.customerGroupQuery.isLoading || this.deleteCustomerGroupCommand.isLoading
        },
        parentName() {
            let pathIndex = this.customerGroupQuery.firstRow.Path.length < 2 ? 0 : this.customerGroupQuery.firstRow.Path.length-2
            return this.customerGroupQuery.firstRow.Path[pathIndex] != undefined ? this.customerGroupQuery.firstRow.Path[pathIndex].Name : ''
        }
    },
    methods: {
        confirmDelete() {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message:
                    "Are you sure you want to delete this Group: <b>'" +
                    this.customerGroupQuery.firstRow.Name +
                    "'</b>?",
                confirmText: 'Delete',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.deleteCustomerGroupCommand.execute({ Ids: this.$route.params.customerGroupId }).then(resp => {
                        if (resp.status === 204) {
                            this.$router.push({ name: 'customerGroupList' })
                        }
                    })
                }
            })
        }
        ,discusEdit() {
            if(this.customerGroupQuery.firstRow.Path.length > 1)
            {
                //console.log('customerSubGroupEdit');
                this.$router.push({
                    name: 'customerSubGroupEdit',
                    params: { customerGroupId: this.$route.params.customerGroupId, subGroupId: '' }
                })
            }    
            else
            {
                //console.log('customerGroupEdit');
                this.$router.push({
                    name: 'customerGroupEdit',
                    params: { customerGroupId: this.$route.params.customerGroupId }
                })
            }   
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
