<template>
  <BTabContent :loading="assignedCustomerQuery.isLoading" title="Customer">        
        <div class="detail-item-content">
            <div class="level">
                <div class="level-left"></div>
                <div class="level-right">
                    <div class="i-relative level-item" style="width: 320px">
                        <b-autocomplete
                            v-model="unassignedCustomerKeyword"
                            :data="unassignedCustomerQuery.Rows"
                            :loading="unassignedCustomerQuery.isLoading"
                            :keep-first="true"
                            :clear-on-select="true"
                            :open-on-focus="true"
                            placeholder="Search to assign Customer"
                            icon="magnify"
                            field="Name"
                            @focus="getUnassignedCustomer(unassignedCustomerKeyword)"
                            @typing="value => getUnassignedCustomer(value)"
                            @input="null"
                            @select="value => assignCustomer(value)"
                        >
                            <!-- <template slot="empty">No results found</template> -->
                        </b-autocomplete>
                    </div>
                </div>
            </div>

            <ul class="list mt-32 list-assigned">
                <li class="list-item" v-for="(Customer, index) in assignedCustomers" :key="index">
                    <div class="item-assigned">
                        <span>{{ Customer.Name }}</span>
                        <div class="item-assigned-remove" @click="confirmUnassign(Customer)">
                            <b-tooltip label="Unassign" position="is-right" type="is-light">
                                <b-icon icon="close"></b-icon>
                            </b-tooltip>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </BTabContent>
</template>

<script>
import Query from '@/libs/Query'
import Command from '@/libs/Command'
import { pathParamsMixin } from '@/mixins/pathParams'

export default {
    props: {
        msg: String
    },
    mixins: [pathParamsMixin],
    data() {
        return {
            unassignedCustomerKeyword: '',

            assignedCustomerQuery: new Query(
                this.$http, 
                'CustomersOfCustomerGroupGet', 
                { Id: this.$route.params.customerGroupId },
                1,
                10,
                'Name'),
            unassignedCustomerQuery: new Query(
                this.$http,
                'CustomersQuery',
                { Keyword: '', ExcludeByCustomerGroupId: '*' },
                1,
                0,
                'Name'
            ),
            assignCustomerCommand: new Command(this.$http, 'POST', 'CustomersOfCustomerGroupAssign'),
            unassignCustomerCommand: new Command(this.$http, 'DELETE', 'CustomersOfCustomerGroupUnassign')
        }
    },
    computed: {
        assignedCustomers() {
            return this.assignedCustomerQuery.Rows
        }
    },
    created() {
        this.assignedCustomerQuery.execute().then(() => {
            this.$path.monitor(() => {
                this.assignedCustomerQuery.execute()
            })
        })
    },
    methods: {
        getUnassignedCustomer(value) {
            this.unassignedCustomerQuery.execute({
                Keyword: value
            })
        },
        assignCustomer(value) {
            this.assignCustomerCommand.execute({
                GroupId: this.$route.params.customerGroupId,
                CustomerIds: value.Id
            }).then(() => {
                this.unassignedCustomerQuery.clearResponse()
                this.assignedCustomerQuery.execute()
            })

        },
        confirmUnassign(Customer) {
            this.$buefy.dialog.confirm({
                title: 'Confirm',
                message: "Are you sure you want to unassign Customer: <b>'" + Customer.Name + "'</b>?",
                confirmText: 'Unassign',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => {
                    this.unassignCustomerCommand
                        .execute({ GroupId: this.$route.params.customerGroupId, CustomerIds: Customer.Id })
                        .then(resp => {
                            if (resp.status === 204) {
                                this.assignedCustomerQuery.execute({ Id: this.$route.params.customerGroupId })
                            }
                        })
                }
            })
        }
    }
}
</script>