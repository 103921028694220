<template>
    <div>
        <SkeletonLoading v-if="loading">
            <SquareSkeleton
                :count="1"
                :boxProperties="{
                                    width: '100px',
                                    height: '32px'
                                }"
            />
        </SkeletonLoading>
        <BTooltip :label="tooltip" position="is-top" type="is-dark" :animated="true" v-else>
            <b-dropdown v-model="ddValue">
                <button v-if="ddValue" :class="{'button has-text-grey': isFirst, 'button is-primary': !isFirst}" slot="trigger">
                    <span>{{ ddValue.Name }}</span>
                    <b-icon icon="chevron-down"></b-icon>
                </button>
                <button v-else class="button has-text-grey" slot="trigger">
                    <span>No Filter</span>
                    <b-icon icon="chevron-down"></b-icon>
                </button>
                <b-dropdown-item v-for="(option, index) in options" :value="option" :key="index">{{ option.Name }}</b-dropdown-item>
            </b-dropdown>
        </BTooltip>
    </div>
</template>

<script>
export default {
    name: 'BFilter',
    props: {
        loading: {
            type: [Boolean],
            default: false
        },
        value: {
            type: [String, Number, Boolean, Object, Array, Symbol, Function],
            default: null
        },
        options: Array,
        tooltip: {
            type: [String],
            default: 'Filter'
        }
    },
    watch: {
        value() {
            // console.log('value: ', this.value)
            this.ddValue = this.value
            this.isFirst = this.options[0] == this.value
        },
        ddValue() {
            // console.log('ddValue: ', this.ddValue)
            this.$emit('input', this.ddValue)
            this.$emit('change', this.ddValue)
        }
    },
    data() {
        return {
            isFirst: true,
            ddValue: null
        }
    },
    mounted() {
        // console.log('value: ', this.value)
        this.ddValue = this.value
    }
}
</script>
