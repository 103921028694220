<template>
    <div v-permit="actionPermission">
        <div
            class="content-control"
            v-if="actionItems && actionItems.length > 0 && selectedRows && selectedRows.length > 0"
        >
            <div class="mr-3 has-text-grey">
                {{ selectedRows.length }} {{ 'item' | pluralize(selectedRows.length) }} selected
            </div>

            <slot name="CTASelectedState" v-if="selectedRows.length > 0"></slot>
            
            <b-dropdown position="is-bottom-left">
                <button class="button" slot="trigger">
                    <span>Action</span>
                    <b-icon icon="menu-down"></b-icon>
                </button>
                <b-dropdown-item
                    v-for="(action, index) in actionItems"
                    :key="index"
                    v-permit="action.permission"
                    @click="action.do"
                    >{{ action.label }}</b-dropdown-item
                >
            </b-dropdown>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BActionButton',
    props: {
        actionPermission: {
            type: [Object],
            default: () => {
                return {}
            },
            required: true,
        },
        actionItems: {
            type: [Array],
            default: () => [],
        },
        selectedRows: {
            type: [Array],
            default: () => [],
        },
    },
    data() {
        return {}
    },
    watch: {},
    created() {
        // console.log(this.$options.name, ': created() ------------------------------------------')
        // console.log('tabItems: ', this.tabItems)
    },
    methods: {},
}
</script>

<style scoped></style>
