<template>
    <BTabContent :loading="customerQuery.isLoading">
        <div v-if="details">
            <div class="detail-item-title">Name</div>
            <div class="detail-item-content">{{ details.Name }}&nbsp;</div>
            <div class="detail-item-title">Address</div>
            <div class="detail-item-content">{{ details.Address }}&nbsp;</div>
            <div class="detail-item-title">Location</div>
            <div class="detail-item-content" v-if="details.Location">
                Latitude: {{ details.Location.Latitude }}&nbsp;, Longitude: {{ details.Location.Longitude }}
            </div>
            <div class="detail-item-title">Country</div>
            <div class="detail-item-content" v-if="details.Country">{{ details.Country.Name }}</div>
            <div class="detail-item-title">Phone Number</div>
            <div class="detail-item-content">{{ details.PhoneNumber }}&nbsp;</div>
            <div class="detail-item-title">Email</div>
            <div class="detail-item-content">{{ details.Email }}&nbsp;</div>
            <div class="detail-item-title">Server URL</div>
            <div class="detail-item-content">{{ details.ServerUrl }}&nbsp;</div>
        </div>
        <div v-else>
            <!-- <p>Details not found</p> -->
        </div>
    </BTabContent>
</template>

<script>
import Query from '@/libs/Query'

export default {
    props: {
        msg: String,
    },
    data() {
        return {
            customerQuery: new Query(this.$http, 'CustomersGet', { Ids: this.$route.params.customerId }),
        }
    },
    created() {
        this.customerQuery.firstRow.Location = {}
        this.customerQuery.firstRow.Country = {}
        this.customerQuery.execute()
    },
    computed: {
        details() {
            return this.customerQuery.firstRow
        },
    },
}
</script>
