<template>
    <div>
        <SkeletonLoading v-if="loading">
            <SquareSkeleton
                :count="1"
                :boxProperties="{
                                    width: '120px',
                                    height: '32px'
                                }"
            />
        </SkeletonLoading>
        <template v-else>
            <BTooltip label="Page Size" position="is-left" type="is-dark" :animated="true">
                <span class="has-text-weight-semibold" style="margin-right: 10px;">{{label}}</span>
                <b-dropdown position="is-bottom-left" v-model="ddValue">
                    <button class="button is-normal" slot="trigger">
                        <span>{{ ddValue }}</span>
                        <b-icon icon="chevron-down"></b-icon>
                    </button>
                    <b-dropdown-item v-for="(item,index) in options" :key="index" :value="item">{{item}}</b-dropdown-item>
                </b-dropdown>
            </BTooltip>
        </template>
    </div>
</template>

<script>
export default {
    name: 'BPageSize',
    props: {
        loading: {
            type: [Boolean],
            default: false
        },
        label: {
            type: [String],
            default: ''
        },
        value: {
            type: [String, Number, Boolean, Object, Array, Symbol, Function],
            default: null
        },
        options: {
            type: [Array],
            default: () => [10, 20, 50, 100]
        }
    },
    watch: {
        value() {
            // console.log('value: ', this.value)
            this.ddValue = this.value
        },
        ddValue() {
            // console.log('ddValue: ', this.ddValue)
            this.$emit('input', this.ddValue)
            this.$emit('change', this.ddValue)
        }
    },
    data() {
        return {
            ddValue: null
        }
    },
    mounted() {
        // console.log('value: ', this.value)
        this.ddValue = this.value
    }
}
</script>
